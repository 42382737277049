import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Colors } from "../../../../../../styles/themeNew";
import { ArrowRight } from "./icons/Arrow";
import { MeatballsMenuDefault } from "./icons/MeatballsMenu";
import { AddBlockIcon } from "./icons/AddBlock";
import { RemoveBlockIcon } from "./icons/RemoveBlock";
import { ClearBlockIcon } from "./icons/ClearBlock";
import { CopyBlockIcon } from "./icons/CopyBlock";

// type Option = {
//     label: string;
//     value: string;
//     selected: boolean;
// };

type Props = {
    // title?: string;
    // placeholder?: string;
    // required?: boolean;
    // options: Option[];
    // onSelect: (option: Option) => void;
    // disabled?: boolean;
};

export const HistologicalMaterial: React.FC<Props> = (props) => {
    // const [dropdownVisible, setDropdownVisible] = useState(false);
    // const [selectedOption, setSelectedOption] = useState<Option | null>(null);
    const [blocks, setBlocks] = useState<{ block: string; glass: string }[]>([{ block: "", glass: "" }]);
    const [popupVisible, setPopupVisible] = useState<number | null>(null);
    const [selectedInput, setSelectedInput] = useState<"block" | "glass">("block");
    const popupRef = useRef<HTMLDivElement>(null);

    const handleSelect = (option: "block" | "glass") => {
        // props.onSelect(option);
        // setSelectedOption(option);
        // setDropdownVisible(false);
        setSelectedInput(option as "block" | "glass");
    };

    const handleAddBlock = () => {
        setBlocks([...blocks, { block: "", glass: "" }]);
        setPopupVisible(null);
    };

    const handleRemoveBlock = (index: number) => {
        setBlocks(blocks.filter((_, i) => i !== index));
        setPopupVisible(null);
    };

    const handleClearBlock = (index: number) => {
        const newBlocks = [...blocks];
        newBlocks[index] = { block: "", glass: "" };
        setBlocks(newBlocks);
        setPopupVisible(null);
    };

    const handleCopyBlock = (index: number) => {
        const newBlocks = [...blocks];
        newBlocks.push(newBlocks[index]);
        setBlocks(newBlocks);
        setPopupVisible(null);
    };

    const handleBlockChange = (index: number, field: "block" | "glass", value: string) => {
        const newBlocks = [...blocks];
        newBlocks[index][field] = value;
        setBlocks(newBlocks);
    };

    const renderPopup = (index: number) => {
        if (selectedInput === "block") {
            return (
                <StyledPopup ref={popupRef} isGlass={false}>
                    <StyledPopupItem onClick={handleAddBlock}>Добавить блок <AddBlockIcon /></StyledPopupItem>
                    <StyledPopupItem onClick={() => handleRemoveBlock(index)}>Удалить блок <RemoveBlockIcon /></StyledPopupItem>
                    <StyledPopupItem onClick={() => handleClearBlock(index)}>Очистить блок <ClearBlockIcon /></StyledPopupItem>
                    <StyledPopupItem onClick={() => handleCopyBlock(index)}>Скопировать <CopyBlockIcon /></StyledPopupItem>
                </StyledPopup>
            );
        } else {
            return (
                <StyledPopup ref={popupRef} isGlass={true}>
                    <StyledPopupItem onClick={handleAddBlock}>Добавить Гист. стекло к данному блоку <AddBlockIcon /></StyledPopupItem>
                    <StyledPopupItem onClick={() => handleRemoveBlock(index)}>Удалить Гист. стекла <RemoveBlockIcon /></StyledPopupItem>
                    <StyledPopupItem onClick={() => handleClearBlock(index)}>Очистить <ClearBlockIcon /></StyledPopupItem>
                    <StyledPopupItem onClick={() => handleCopyBlock(index)}>Скопировать <CopyBlockIcon /></StyledPopupItem>
                </StyledPopup>
            );
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                setPopupVisible(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [popupRef]);
    console.log('BLOCKS', blocks)
    return (
        <StyledContainer>
            <TTabPanel>
                <StyledHistologicalBtn
                    onClick={() => handleSelect('block')}
                    selected={selectedInput === 'block'}
                >1. Блоки *</StyledHistologicalBtn>
                <StyledHistologicalBtn
                    onClick={() => handleSelect('glass')}
                    selected={selectedInput === 'glass'}
                >2. Гист. стекла *
                </StyledHistologicalBtn>
            </TTabPanel>
            {/*<StyledMainContainer>*/}
            {/*    <StyledLabel>*/}
            {/*        {props.title} {props.required && <StyledAsteriskContainer>*</StyledAsteriskContainer>}*/}
            {/*    </StyledLabel>*/}
            {/*    <StyledOptionButton*/}
            {/*        selected={dropdownVisible}*/}
            {/*        onClick={() => setDropdownVisible(!dropdownVisible)}*/}
            {/*        disabled={props.disabled}*/}
            {/*    >*/}
            {/*        {selectedOption ? selectedOption.label : props.placeholder}*/}
            {/*    </StyledOptionButton>*/}
            {/*    {dropdownVisible && (*/}
            {/*        <StyledDropdown>*/}
            {/*            {props.options.map((option) => (*/}
            {/*                <StyledDropdownItem*/}
            {/*                    key={option.value}*/}
            {/*                    onClick={() => handleSelect(option)}*/}
            {/*                    selected={option.selected}*/}
            {/*                >*/}
            {/*                    {option.label}*/}
            {/*                </StyledDropdownItem>*/}
            {/*            ))}*/}
            {/*        </StyledDropdown>*/}
            {/*    )}*/}
            {/*</StyledMainContainer>*/}
            {blocks.map((block, index) => (
                <StyledBlockContainer key={index}>
                    {selectedInput === "block" && (
                        <>
                            <StyledInput
                                type="text"
                                placeholder="--"
                                value={block.block}
                                onChange={(e) => handleBlockChange(index, "block", e.target.value)}
                            />
                            <ArrowRight />
                            <StyledInput
                                type="text"
                                placeholder="Номер"
                                value={block.glass}
                                onChange={(e) => handleBlockChange(index, "glass", e.target.value)}
                            />
                        </>
                    )}
                    {selectedInput === "glass" && (
                        <>
                            <StyledInput
                                type="text"
                                placeholder="--"
                                value={block.block}
                                onChange={(e) => handleBlockChange(index, "block", e.target.value)}
                            />
                            <ArrowRight />
                            <StyledInput
                                type="text"
                                placeholder="Номер"
                                value={block.glass}
                                onChange={(e) => handleBlockChange(index, "glass", e.target.value)}
                            />
                        </>
                    )}
                    <StyledOptionsButtonContainer>
                        <StyledOptionsButton onClick={() => setPopupVisible(index)}>
                            <MeatballsMenuDefault />
                        </StyledOptionsButton>
                        {popupVisible === index && renderPopup(index)}
                    </StyledOptionsButtonContainer>
                </StyledBlockContainer>
            ))}
        </StyledContainer>
    );
};

const StyledHistologicalBtn = styled.div<{
    selected?: boolean;
}>`
  display: flex;
  width: 260px;
  height: 45px;
  border: 1px solid rgb(216, 219, 223);
  box-sizing: border-box;
  border-radius: 10px;
  background-color:  ${(props) => props.selected ? '#CBECFE' : 'white'};
  justify-content: center;
  align-items: center`;

const TTabPanel = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  width:100%;
  border: 1px solid ${Colors.MONOCHROM[200]};
  box-sizing: border-box;
  border-radius: 10px;
  background-color: white`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;
`;

// const StyledMainContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   position: relative;
// `;
//
// const StyledOptionButton = styled.button<{ selected: boolean; disabled?: boolean }>`
//   flex: 1;
//   padding: 10px;
//   border: 1px solid ${Colors.MONOCHROM[300]};
//   cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
//   background: ${(props) => (props.selected ? Colors.BRAND[500] : "white")};
//   color: ${(props) => (props.selected ? "white" : Colors.MONOCHROM[950])};
// `;

// const StyledLabel = styled.label`
//   display: flex;
//   align-items: center;
//   color: ${Colors.MONOCHROM[950]};
//   font-size: 14px;
//   font-weight: 400;
//   margin-right: 10px;
// `;
//
// const StyledAsteriskContainer = styled.div`
//   color: ${Colors.RED[500]};
//   font-size: 12px;
//   font-weight: 400;
//   margin-left: 5px;
// `;
//
// const StyledDropdown = styled.div`
//   position: absolute;
//   top: 40px;
//   left: 0;
//   right: 0;
//   background: white;
//   border: 1px solid ${Colors.MONOCHROM[200]};
//   border-radius: 5px;
//   z-index: 10;
// `;

// const StyledDropdownItem = styled.div<{ selected: boolean }>`
//   padding: 10px;
//   cursor: pointer;
//   background: ${(props) => (props.selected ? Colors.BRAND[500] : "white")};
//   color: ${(props) => (props.selected ? "white" : Colors.MONOCHROM[950])};
//
//   &:hover {
//     background: ${Colors.BRAND[200]};
//   }
// `;

const StyledBlockContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
`;

const StyledInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  font-size: 14px;
`;

const StyledOptionsButtonContainer = styled.div`
  position: relative;
`;

const StyledOptionsButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: ${Colors.MONOCHROM[950]};
`;

const StyledPopup = styled.div<{
    isGlass: boolean;
}>`
  position: absolute;
  top: 0px;
  left: 0;
  background: white;
  width:  ${(props) => props.isGlass ? '370px' : '260px'};
  border: 1px solid ${Colors.MONOCHROM[200]};
  border-radius: 5px;
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const StyledPopupItem = styled.div`
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  &:hover {
    background: ${Colors.BRAND[50]};
  }
`;
