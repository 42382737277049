import React from 'react';

export function CopyBlockIcon(): JSX.Element {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3307 6.6665V4.33317C13.3307 3.39975 13.3307 2.93304 13.1491 2.57652C12.9893 2.26292 12.7343 2.00795 12.4207 1.84816C12.0642 1.6665 11.5975 1.6665 10.6641 1.6665H4.33073C3.39731 1.6665 2.9306 1.6665 2.57408 1.84816C2.26047 2.00795 2.00551 2.26292 1.84572 2.57652C1.66406 2.93304 1.66406 3.39975 1.66406 4.33317V10.6665C1.66406 11.5999 1.66406 12.0666 1.84572 12.4232C2.00551 12.7368 2.26047 12.9917 2.57408 13.1515C2.9306 13.3332 3.39731 13.3332 4.33073 13.3332H6.66406M9.9974 12.4998L11.6641 14.1665L15.4141 10.4165M9.33073 18.3332H15.6641C16.5975 18.3332 17.0642 18.3332 17.4207 18.1515C17.7343 17.9917 17.9893 17.7368 18.1491 17.4232C18.3307 17.0666 18.3307 16.5999 18.3307 15.6665V9.33317C18.3307 8.39975 18.3307 7.93304 18.1491 7.57652C17.9893 7.26292 17.7343 7.00795 17.4207 6.84816C17.0642 6.6665 16.5975 6.6665 15.6641 6.6665H9.33073C8.39731 6.6665 7.9306 6.6665 7.57408 6.84816C7.26047 7.00795 7.00551 7.26292 6.84572 7.57652C6.66406 7.93304 6.66406 8.39975 6.66406 9.33317V15.6665C6.66406 16.5999 6.66406 17.0666 6.84572 17.4232C7.00551 17.7368 7.26047 17.9917 7.57408 18.1515C7.9306 18.3332 8.39731 18.3332 9.33073 18.3332Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}


