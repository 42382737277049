import { FC, useCallback, useMemo } from "react";
import { format } from 'date-fns';
import { Experiment, ExperimentSteps, ExperimentSs, ExperimentSsConclusion, MarkerReference, AnalysisFishAdvisor } from "../../../store/analysis/model";
import arrowSelectIcon from '../../../icons/arrow-select.svg';
import defaultStatusIcon from '../../../icons/done-gray.svg';
import { TEditButton, TRowWr } from "../MarkersValidation/styled";
import { Table, TBody } from "../../../components/shared/BiomaterialTable";
import { TWrapper, TDateOfCompletion, TExecutor, TButtonWrapper, TAdvisor, TAdvisorItem, TBioAcquired } from "./styled";
import { TCheckbox, TDetails, TDetailsContent, TSummary, TSummaryContent, TSummaryIcon } from "../../../components/shared/Details/styled";
import { TTable } from "../../../components/shared/BiomaterialTable/styled";
import { IExamExecutors } from "./CreateExperiment";

interface IComponentProps {
  Experiment: any;
  defaultExecutorOptions?: any;
  isSelectedOptions?: number[];
  handleChecked?: (id: number) => void;
  ExperimentIndex?: number;
  disbledLastExperements?: boolean;
  examMethodName: string;
  examExecutors: IExamExecutors;
  markerName: string;
  [index: string]: any;
}

interface IMethodExamTableSetting {
  headers: string[];
  conclusionColSpan: number;
  sensitivityTitleColSpan: number;
  sensitivitySelectColSpan: number;
}

interface IExamTableSetting {
  [index: string]: IMethodExamTableSetting;
}


const ExamTableSetting: IExamTableSetting = {
  pcr: {
    headers: ['Маркер', 'Референсная последовательность', 'Патогенный вариант/детектируемое нарушение', 'Результат исследования'],
    sensitivityTitleColSpan: 4,
    sensitivitySelectColSpan: 4,
    conclusionColSpan: 4,
  },
  ss: {
    headers: ['Маркер', 'Референсная последовательность', 'Экзон / Кодон / Патогенный вариант', 'Результат исследования'],
    sensitivityTitleColSpan: 4,
    sensitivitySelectColSpan: 4,
    conclusionColSpan: 4,
  },
  fa: {
    headers: ['Маркер', 'Микросателлитный маркер', 'Результат исследования'],
    sensitivityTitleColSpan: 3,
    sensitivitySelectColSpan: 3,
    conclusionColSpan: 3,
  },
  ihc: {
    headers: ['Маркер', 'Референсные значения', 'Интенсивность окрашивания', 'Окрашенных клеток, %', 'Результат исследования'],
    sensitivityTitleColSpan: 1,
    sensitivitySelectColSpan: 2,
    conclusionColSpan: 2,
  },
  ihcAlk: {
    headers: ['Маркер', 'Клон', 'Результат исследования',	'Характеристика окрашивания'],
    sensitivityTitleColSpan: 1,
    sensitivitySelectColSpan: 3,
    conclusionColSpan: 2,
  },
  ihcClones: {
    headers: ['Маркер', 'Клон', 'Показатель экспрессии ОК, %',	'Показатель экспрессии ИК, %','Результат исследования'],
    sensitivityTitleColSpan: 1,
    sensitivitySelectColSpan: 3,
    conclusionColSpan: 2,
  },
  fishRear: {
    headers: ['Маркер', 'Кол-во проанализ. ядер опухолевых клеток',
      'Кол-во ядер опухолевых клеток без реаранжировки', 'Кол-во ядер с BA - сигналом', 'Кол-во ядер с изолированным сигналом',
      'Ядер опухолевых клеток c реаранжировкой, %'],
    sensitivityTitleColSpan: 2,
    sensitivitySelectColSpan: 4,
    conclusionColSpan: 2,
  },
  fishAmplify: {
    headers: [],
    sensitivityTitleColSpan: 2,
    sensitivitySelectColSpan: 4,
    conclusionColSpan: 2,
  }
}

const stepDate = (date: Date) => format(new Date(date), 'dd.MM.yyyy - HH:mm:ss');

const LastExperiment: FC<IComponentProps> = ({ Experiment, examExecutors, markerName, defaultExecutorOptions, ExperimentIndex, isSelectedOptions, handleChecked, disbledLastExperements, examMethodName }) => {

  const handleSummaryChecked = useCallback((id: number) => {
    if (!handleChecked) return;
    handleChecked(id);
  }, [handleChecked]);

  const dateOfCompletion = useMemo(() => {
    if (!Experiment?.dateCompleted) return '';

    return format(new Date(Experiment.dateCompleted), 'dd.MM.yyyy - HH:mm:ss');
  }, [Experiment]);

  const experementBioSteps = useMemo(() => {
    const BioSteps: ExperimentSteps[] = Experiment?.experimentIhcSteps || Experiment?.experimentIhcAlkSteps || Experiment?.experimentFishSteps;
    if (!BioSteps) return <></>;
    return BioSteps
    ?.filter(((value: ExperimentSteps) => value.step === 'bio_acquired'))
    ?.map((value: ExperimentSteps) => {
      return (
        <div key={value.id}>
          <span>Материал получен: <b> {stepDate(value.dateCreated)}</b></span>
        </div>
      )
    });
  }, [Experiment]);

  const experementAdvisors = useMemo(() => {
    const Advisors:AnalysisFishAdvisor[] = Experiment?.advisors;
    if (!Advisors) return <></>;
    return <ul>
      {Advisors?.map((value: AnalysisFishAdvisor) => (
        <TAdvisorItem key={value.fullName}>
          <span><b>{value.fullName}</b>, {value.specialization}</span>
        </TAdvisorItem>
      ))}

    </ul>
  },[Experiment]);

  return (
    <TDetails open={disbledLastExperements ? !!Experiment?.acceptance : (!ExperimentIndex ? true : false)}>
      <TSummary>
        <TSummaryContent isSelected={true}>
          <TCheckbox
            id={`${Experiment.id}`}
            checked={isSelectedOptions?.includes(Experiment.id) || !!Experiment?.acceptance}
            disabled={disbledLastExperements}
            onChange={({ target }) => handleSummaryChecked(+target.id)} />
          Попытка №{Experiment?.number}
          <TSummaryIcon className="arrow" src={arrowSelectIcon} />
        </TSummaryContent> <TSummaryIcon src={defaultStatusIcon} />
      </TSummary>
      <TDetailsContent>
        <TRowWr direction={'space-between'}>
          <TDateOfCompletion>{!!dateOfCompletion && `Дата завершения: ${dateOfCompletion}`}</TDateOfCompletion>
          <TEditButton disabled={true}> Редактировать </TEditButton>
        </TRowWr>
        <TWrapper>
          {!!['pcr', 'ss', 'fa'].includes(examMethodName) && (
            <Table>
              <TBody>
                <tr>
                  <td colSpan={ExamTableSetting?.[examMethodName]?.sensitivityTitleColSpan ?? 2} className="head violet leftTopRadius rigthTopRadius">Аналитическая чувствительность метода, %</td>
                </tr>
                <tr>
                  <td colSpan={ExamTableSetting?.[examMethodName]?.sensitivitySelectColSpan ?? 2}>{Experiment.analyticalSensitivity}</td>
                </tr>
              </TBody>
              <TBody>
                <tr className="borderTop violet">
                  {ExamTableSetting?.[examMethodName]?.headers?.map((header: string) => (
                  <td key={header} className='head noRadius violet'>{header}</td>))}
                </tr>
              </TBody>
              <TBody>
                {!!Experiment?.experimentSsRuns && Experiment?.experimentSsRuns?.map((data: ExperimentSs, index: number, allExperements: ExperimentSs[]) => {
                  //Sangler Sequence
                  if (data?.identifier){
                    let isDetectOtherGene = allExperements?.[index - 1]?.gene !== data.gene;
                    let rowSpanValue = isDetectOtherGene ? allExperements?.filter((exp: Experiment) => exp?.gene === data.gene)?.length : allExperements?.length;
                    return (
                    <tr key={index + 'mutationSS'}>
                      {(!index || isDetectOtherGene) && <td rowSpan={rowSpanValue}>{data.gene}</td>}
                      {(!index || isDetectOtherGene) && <td rowSpan={rowSpanValue} className="borderRight">{data.reference}</td>}
                      <td>{data.identifier}</td>
                      <td>{data.result}</td>
                    </tr>
                  )}
                  return <tr></tr>
                })}
                {!!Experiment?.experimentRuns && Experiment?.experimentRuns?.map((data: Experiment, index: number, allExperements: Experiment[]) => {

                  //PCR analysis
                  if (data?.genePcr) {
                    let isDetectOtherGene = allExperements?.[index - 1]?.genePcr !== data.genePcr;
                    let rowSpanValue = isDetectOtherGene ? allExperements?.filter((exp: Experiment) => exp?.genePcr === data.genePcr)?.length : allExperements?.length;
                    return <tr key={index + 'mutationPcr'}>
                      {(!index || isDetectOtherGene) && <td rowSpan={rowSpanValue}>{data.genePcr}</td>}
                      {(!index || isDetectOtherGene) && <td rowSpan={rowSpanValue} className="borderRight">{data.reference}</td>}
                      <td>{data.mutationPcr}</td>
                      <td>{data.resultPcr}</td>
                    </tr>
                  }

                  //Fragment analysis
                  if (data?.microsatelliteFa) return (
                    <tr key={index + 'microsatellite'}>
                      {!index && <td className="borderRight" rowSpan={allExperements.length}>{markerName}</td>}
                      <td>{data.microsatelliteFa}</td>
                      <td>{data.resultFa}</td>
                    </tr>
                  )

                  return <tr></tr>
                })}

              </TBody>
              {/* conclusion */}
              <TBody>
                <tr>
                  <td className="head violet" colSpan={ExamTableSetting?.[examMethodName]?.conclusionColSpan ?? 3}>
                    Клинико-лабораторное заключение
                  </td>
                </tr>
                {!!(Experiment?.conclusion?.length || Experiment?.experimentSsConclusions?.length) && <tr>
                  <td colSpan={ExamTableSetting?.[examMethodName]?.conclusionColSpan ?? 3} className="lastExpConclusion">
                    {(!!Experiment?.conclusion && Experiment?.conclusionMap?.[Experiment?.conclusion]?.split('\u2004 ')?.map(
                      (text: string, index: number) => <div className="capitalize" key={'conclusion' + index}>{text}</div>)) ||
                      Experiment?.experimentSsConclusions?.map(
                        (text: ExperimentSsConclusion, index: number) => <div className="capitalize" key={'conclusion' + index}>{text.conclusion.replace(';  ','')}.</div>) ||
                      Experiment?.conclusion?.split('\n\r')?.map(
                        (text: string, index: number) => <div className="capitalize" key={'conclusion' + index}>{text}.</div>)}
                  </td>
                </tr>}
                  {Experiment?.notes ? <tr><td colSpan={ExamTableSetting?.[examMethodName]?.conclusionColSpan ?? 3} className="lastExpNotes">{Experiment?.notes}</td></tr> : null}
              </TBody>
            </Table>)}
          {/* IHC ... */}
          {(!!Experiment?.clone || !!Experiment?.stainIntensity) && (
            <TTable style={{tableLayout: 'auto'}}>
              <TBody>
                <tr className="borderTop violet">
                  {Experiment?.name === 'ALK' && ExamTableSetting?.['ihcAlk']
                    ?.headers?.map((header: string, index:number, arr:string[]) => <td key={header} className={`head ${!index && 'leftTopRadius'} ${index === arr?.length - 1 && 'rigthTopRadius'}`}>{header}</td>)}
                  {Experiment?.name !== 'ALK' && !!Experiment?.clone && ExamTableSetting?.['ihcClones']
                    ?.headers?.map((header: string, index:number, arr:string[]) => <td key={header} className={`head ${!index && 'leftTopRadius'} ${index === arr?.length - 1 && 'rigthTopRadius'}`}>{header}</td>)}
                  {!!Experiment?.stainIntensity && ExamTableSetting
                    ?.['ihc']?.headers?.map((header: string, index:number, arr:string[]) => <td key={header} className={`head ${!index && 'leftTopRadius'} ${index === arr?.length - 1 && 'rigthTopRadius'}`}>{header}</td>)}
                </tr>
              </TBody>
              {/* IHC ALK */}
              {Experiment?.name === 'ALK' && (
                <TBody>
                  <tr style={{tableLayout: 'auto'}}>
                    <td style={{width:'10%'}}>{Experiment?.name ?? '--'}</td>
                    <td style={{width:'10%'}}>{Experiment?.clonesMap?.[Experiment?.clone ?? 14] ?? '--'}</td>
                    <td style={{width:'25%'}}>{Experiment?.resultMap?.[Experiment?.result] ?? '--'}</td>
                    <td>{Experiment?.characteristicMap?.[Experiment?.result] ?? '--'}</td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="head violet">Клинико-лабораторное заключение</td>
                  </tr>
                  {!!Experiment?.conclusion?.length && <tr>
                    <td colSpan={5}>{Experiment?.conclusion ?? '--'}.</td>
                  </tr>}
                  {!!Experiment?.notes && <tr>
                    <td colSpan={5}>{Experiment?.notes ?? '--'}</td>
                  </tr>}
                </TBody>
              )}
              {/* IHC Clones */}
              {Experiment?.name !== 'ALK' && !!Experiment?.clone && (
                <TBody>
                  <tr style={{tableLayout: 'auto'}}>
                    <td style={{width:'10%'}}>{Experiment?.name ?? '--'}</td>
                    <td style={{width:'15%'}}>{Experiment?.clonesMap?.[Experiment?.clone ?? 15] ?? '--'}</td>
                    <td style={{width:'25%'}}>{!!Experiment?.cancerCellLevel ? Experiment?.cancerCellLevel : '--'}</td>
                    <td style={{width:'25%'}}>{!!Experiment?.cancerCellLevel ? Experiment?.immuneCellLevel : '--'}</td>
                    <td style={{width:'25%'}}>{Experiment?.resultMap?.[Experiment?.result] ?? '--'}</td>
                  </tr>
                  <tr>
                    <td colSpan={2} className={`head violet ${!Experiment?.conclusion?.length ? 'leftBottomRadius' : ''}`}>Референсные значения</td>
                    <td colSpan={3}>
                      {Experiment?.referencesMap?.[Experiment?.clone]?.map((reference: MarkerReference,index:number) => <div style={{margin:'0px'}} key={`${index}_${reference}`}>{reference}</div>) ?? '--'}
                      </td>
                  </tr>
                  {!!Experiment?.conclusion?.length &&
                  <tr>
                    <td colSpan={5} className="head violet">Клинико-лабораторное заключение</td>
                  </tr>}
                  {!!Experiment?.conclusion?.length && <tr>
                    <td colSpan={5}>{Experiment?.conclusion ?? '--'}.</td>
                  </tr>}
                  {!!Experiment?.notes && <tr>
                    <td colSpan={5}>{Experiment?.notes ?? '--'}</td>
                  </tr>}
                </TBody>
              )}

              {/* IHC */}
              {!!Experiment?.stainIntensity && (
                <TBody>
                  <tr>
                    <td>{Experiment?.name ?? '--'}</td>
                    <td>{Experiment?.markerIhcReferences?.map((reference: MarkerReference) => <div key={reference?.id}>{reference.content}</div>) ?? '--'}</td>
                    <td>{Experiment?.stainIntensity ?? '--'}</td>
                    <td>{Experiment?.cellStainPercentage ?? '--'}</td>
                    <td>{Experiment?.resultsMap?.[Experiment?.result]}</td>
                  </tr>
                    {!!Experiment?.conclusion?.length && <tr>
                      <td colSpan={5} className="head violet">Клинико-лабораторное заключение</td>
                    </tr>}
                    {!!Experiment?.conclusion?.length && <tr>
                      <td colSpan={5}>
                        {typeof Experiment?.conclusion === 'string' ? Experiment?.conclusion : Experiment?.conclusionMap?.[Experiment?.conclusion] ?? '--'}.
                      </td>
                    </tr>}
                </TBody>
              )}
            </TTable>
          )}

          {/* FISH Rearrangement */}
          {!!Experiment?.rearrangement && (
            <Table>
              <TBody>
                <tr>
                  <td colSpan={ExamTableSetting?.['fishRear']?.sensitivityTitleColSpan ?? 2} className="head violet leftTopRadius">Реагенты (наборы, зонды)</td>
                  <td colSpan={ExamTableSetting?.['fishRear']?.sensitivitySelectColSpan ?? 4} >{Experiment?.kitMap?.[Experiment?.kit]}</td>
                </tr>
              </TBody>
              <TBody>
                <tr className="borderTop violet">
                  {ExamTableSetting?.['fishRear']?.headers?.map((header: string) => <td key={header} className='head'>{header}</td>)}
                </tr>
                <tr>
                  <td>{Experiment?.name ?? '--'}</td>
                  <td>{Experiment?.rearrangement?.cancerCellCount ?? '--'}</td>
                  <td>{Experiment?.rearrangement?.cancerCellNucleusRearrangedCount ?? '--'}</td>
                  <td>{Experiment?.rearrangement?.splitSignalNucleusCount ?? '--'}</td>
                  <td>{Experiment?.rearrangement?.isolatedSignalNucleusCount ?? '--'}</td>
                  <td>{Math.trunc(+Experiment?.rearrangement?.rearrangedCellPercentage) ?? '--'}</td>
                </tr>
              </TBody>
              <TBody>
                <tr>
                  <td colSpan={ExamTableSetting?.['fishRear']?.sensitivityTitleColSpan ?? 2} className="head violet leftRadius">Референсные значения</td>
                  <td colSpan={ExamTableSetting?.['fishRear']?.sensitivitySelectColSpan ?? 4}>
                    {Experiment?.references?.map((ref: MarkerReference) => <div key={ref.id}>{ref.content}</div>)}
                  </td>
                </tr>
                <tr>
                  <td colSpan={ExamTableSetting?.['fishRear']?.sensitivityTitleColSpan ?? 2} className="head violet leftRadius">Результат исследования</td>
                  <td colSpan={ExamTableSetting?.['fishRear']?.sensitivitySelectColSpan ?? 4}>
                    {Experiment?.resultMap?.[Experiment?.rearrangement?.result]}
                  </td>
                </tr>
                <tr>
                  <td colSpan={6} className="head violet leftRadius">Клинико-лабораторное заключение</td>
                </tr>
                {!!Experiment?.rearrangement?.conclusion?.length && <tr>
                  <td colSpan={6}>
                    {Experiment?.rearrangement?.conclusion}.
                  </td>
                </tr>}
                {!!Experiment?.rearrangement?.notes && (
                <tr>
                  <td colSpan={6}>{Experiment?.rearrangement?.notes}</td>
                </tr>)}
              </TBody>
            </Table>
          )}

          {/* FISH Amplification */}
          {!!Experiment?.amplification && (
            <Table>
              <TBody>
                <tr>
                  <td colSpan={ExamTableSetting?.['fishAmplify']?.sensitivityTitleColSpan ?? 2} className="head violet leftRadius">Реагенты (наборы, зонды)</td>
                  <td colSpan={ExamTableSetting?.['fishAmplify']?.sensitivitySelectColSpan ?? 4} >{Experiment?.kitMap?.[Experiment?.kit]}</td>
                </tr>
              </TBody>
              <TBody>
                <tr className="borderTop violet">
                  <td className="head">Маркер</td>
                  <td className="head">Кол-во проанализ. ядер опухолевых клеток</td>
                  <td className="head">Количество сигналов {Experiment?.name}</td>
                  <td className="head">Количество сигналов {Experiment?.signalsMap?.[Experiment?.kit]?.name}</td>
                  <td className="head">Соотношение {Experiment?.name}/{Experiment?.signalsMap?.[Experiment?.kit]?.name}</td>
                  <td className="head">Количество сигналов {`${Experiment?.name}/клетка`}</td>
                </tr>
                <tr>
                  <td>{Experiment?.name}</td>
                  <td>{Experiment?.amplification?.cancerCellNucleusCount ?? '--'}</td>
                  <td>{Experiment?.amplification?.signalMarkerCount ?? '--'}</td>
                  <td>{Experiment?.amplification?.signalCount ?? '--'}</td>
                  <td>{Experiment?.amplification?.proportion ?? '--'}</td>
                  <td>{Experiment?.amplification?.signalToCellCount ?? '--'}</td>
                </tr>
              </TBody>
              <TBody>
                <tr>
                  <td colSpan={ExamTableSetting?.['fishAmplify']?.sensitivityTitleColSpan ?? 2} className="head violet leftRadius">Референсные значения</td>
                  <td colSpan={ExamTableSetting?.['fishAmplify']?.sensitivitySelectColSpan ?? 4}>
                    {Experiment?.references?.map((ref: MarkerReference) => <div key={ref.id}>{ref.content}</div>)}
                  </td>
                </tr>
                <tr>
                  <td colSpan={ExamTableSetting?.['fishAmplify']?.sensitivityTitleColSpan ?? 2} className="head violet leftRadius">Результат исследования</td>
                  <td colSpan={ExamTableSetting?.['fishAmplify']?.sensitivitySelectColSpan ?? 4}>
                    {Experiment?.resultMap?.[Experiment?.amplification?.result]}
                  </td>
                </tr>
                <tr>
                  <td colSpan={6} className="head violet leftRadius">Клинико-лабораторное заключение</td>
                </tr>
                {!!Experiment?.amplification?.conclusion?.length && <tr>
                  <td colSpan={6}>
                    {Experiment?.amplification?.conclusion ?? '--'}.
                  </td>
                </tr>}
                {!!Experiment?.amplification?.notes && (
                <tr>
                  <td colSpan={ExamTableSetting?.['fishAmplify']?.sensitivitySelectColSpan ?? 4}>{Experiment?.amplification?.notes}</td>
                </tr>)}
              </TBody>
            </Table>
          )}

        </TWrapper>
        {/* <TExecutor height={'fit-content'}>
          <span>
            <b>{defaultExecutorOptions?.[Experiment.executor]?.fullName ?? 'Исполнитель не определен'}</b>, {defaultExecutorOptions?.[Experiment.executor]?.title}
          </span>
        </TExecutor> */}
        {/* {!!Experiment?.advisors?.length && !!Experiment?.acceptance && (
          <TAdvisor height={Experiment?.advisors ? 'max-content' : '84px'}>
            {experementAdvisors}
          </TAdvisor>
          )} */}
        {/* {Experiment?.labAssistant && (<TBioAcquired height={'84px'}>
          {experementBioSteps}
          <span>Материал подготовил:  
            <b> {defaultExecutorOptions?.[Experiment.labAssistant]?.fullName ?? 'Исполнитель не определен'}</b>
            {`, ${defaultExecutorOptions?.[Experiment.labAssistant]?.title}`}
          </span>
        </TBioAcquired>)} */}
        <TButtonWrapper>
          {/* <CustomButton disabled={true}>
            Завершить попытку
          </CustomButton> */}
        </TButtonWrapper>
      </TDetailsContent>
    </TDetails>
  )
}

export default LastExperiment;