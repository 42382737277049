import { createSelector } from '@reduxjs/toolkit';
import { IAppState } from '../';

const getState = (state: IAppState) => state.organizations;

export const getOrganization = (id: number) => createSelector(getState, state =>
  state.data.find(item => item.id === id));

export const getOrganizationLocalName = (id: number) => createSelector(getState, state => {
  return state.data.find(item => item.id === id)?.nameLocalRus
  });

export const getDepartmentLocalName = (organizationId: number, departmentId: number) =>
  createSelector(getOrganization(organizationId), (organization) =>
   organization?.organizationDepartment.find(item => item.id === departmentId)?.nameLocalRus)

export const getOrganizations = createSelector(getState, state => state.data);

export const getOrganizationsSelectOptions = createSelector(getState, state =>
  state.data.map(({ id, nameLocalRus }) => ({ value: id, label: nameLocalRus })));

export const getDepartmentsSelectOptions = (id?: number) => createSelector(getOrganization(id ?? -1), organization =>
  organization?.organizationDepartment.map(({ id, nameLocalRus }) => ({ value: id, label: nameLocalRus }))) ?? [];

export const getHistologyOwner  = (state: IAppState) => state.createReferralPageNew?.biomaterialRegistration?.histologyOwner;
