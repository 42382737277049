import LoginPage from '../containers/LoginPage';
import SignUpPage from '../containers/SignUpPage';
import EmailVerificationPage from '../containers/EmailVerificationPage';
import PersonalInfoPage from '../containers/PersonalInfoPage';
import ReferralPage from '../containers/ReferralPage';
import CreateReferralPage from '../containers/CreateReferralPage';
import RestorePasswordRequestPage from '../containers/RestorePasswordRequestPage';
import RestorePasswordPage from '../containers/RestorePasswordPage';
import StartPersonalInfoPage from '../containers/StartPersonalInfoPage';
import MolecularProfilingDetails from '../containers/MolecularProfilingDetailsPage';
import ResearchPage from "../containers/ResearchPage";

export enum ROUTES {
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  RESTORE_PASSWORD_REQUEST = '/restore-password',
  RESTORE_PASSWORD = '/password-confirmation/:id/:token',
  EMAIL_VERIFICATION = '/email-verification',
  PERSONAL_INFORMATION = '/personal-information',
  UPDATE_PERSONAL_INFORMATION = '/update-personal-information',
  RESEARCH = '/research',
  REFERRALS = '/referral',
  CREATE_REFERRAL = '/create-referral',
  CREATE_REFERRAL_IHC = '/create-referral/ihc',
  CREATE_REFERRAL_MOLECULAR_PROFILING = '/create-referral/molecular-profiling',
  PATIENTS = '/patients',
  NOTIFICATIONS = '/notifications',
  ANALYTICS = '/analytics',
  CALENDAR = '/calendar',
  INFO = '/info',
  LOG = '/log',
  MOLECULAR_PROFILING_DETAILS = '/molecular-profiling/detail/:id/:method',
  IHC_DETAILS = '/ihc/detail/:id/:method',
  ADMIN = '/admin'
}

export interface IRoute {
  path: ROUTES;
  Component: any;
  needsAuth: boolean;
  exact?: boolean;
}

export function AnlyticDashboard() {
  window.open('https://gen-x.analytics.devnull.express/login/', "_blank") || window.location.replace('https://gen-x.analytics.devnull.express/login/');
  return window.location.reload();
}

export const routes: IRoute[] = [
  {
    path: ROUTES.SIGN_IN,
    Component: LoginPage,
    needsAuth: false,
  },
  {
    path: ROUTES.SIGN_UP,
    Component: SignUpPage,
    needsAuth: false,
  },
  {
    path: ROUTES.RESTORE_PASSWORD_REQUEST,
    Component: RestorePasswordRequestPage,
    needsAuth: false,
    exact: true,
  },
  {
    path: ROUTES.RESTORE_PASSWORD,
    Component: RestorePasswordPage,
    needsAuth: false,
  },
  {
    path: ROUTES.EMAIL_VERIFICATION,
    Component: EmailVerificationPage,
    needsAuth: false,
  },
  {
    path: ROUTES.PERSONAL_INFORMATION,
    Component: PersonalInfoPage,
    needsAuth: true,
  },
  {
    path: ROUTES.UPDATE_PERSONAL_INFORMATION,
    Component: StartPersonalInfoPage,
    needsAuth: true,
  },
  {
    path: ROUTES.REFERRALS,
    Component: ReferralPage,
    needsAuth: true,
  },
  {
    path: ROUTES.CREATE_REFERRAL,
    Component: CreateReferralPage,
    needsAuth: true,
  },
  {
    path: ROUTES.MOLECULAR_PROFILING_DETAILS,
    Component: MolecularProfilingDetails,
    needsAuth: false,
  },
  {
    path: ROUTES.RESEARCH,
    Component: ResearchPage,
    needsAuth: true,
  },
  {
    path: ROUTES.ANALYTICS,
    Component: AnlyticDashboard,
    needsAuth: false,
  },
];

export default routes;
