import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import FlowStep from "../../../components/shared/FlowStep";
import { FLOW_STEPS_EXAMINATION } from "../../../fixtures/StepsMolecularProfilingPage";
import { TCancelRequestBlock, TCheckBoxListWr, TPageTitle } from "../styled";
import { getResearchListLoading, getResearchesList } from "../../../store/research/selectors";
import { fetchResearch } from "../../../store/research/thunkActions";
import { IResearch } from "../../../store/research/model";
import { fetchAnalysisByBluprint, postAnalysisAdvisorCreate, putExperimentUpdate, resetAnalysisByBluprint } from "../../../store/analysis/thunkActions";
import { getAnalisys, getAnalisysError, getAnalisysLoading, getNewExperimentData } from "../../../store/analysis/selectors";
import NoDataToShow from "../NoDataToShow";
import { AnalysisExperiment, IAnalisysByMethod, IMarkerIHCClone, MarkerFishExperiment, MarkerIhcAlkExperiment, MarkerIhcExperiment, Signal } from "../../../store/analysis/model";
import { TDetailsContent, TDetailsFormWr, TDetailsItemWr } from "../../../components/shared/Details/styled";
import {
  TAddAttempt, TAnalysisConstantWr, TBackLink, TExaminationNumber, TExperimentBlock, TButtonWrapper,
  CustomButton, TRadioInputsWr, TCancelReasonBlock, TTooltipContainer, TButtonVerticalWrapper, TNotEditedInput
} from "./styled";
import { ReactComponent as SuccessModalIcon } from '../../../icons/success-modal.svg';


import {
  checkPermitCreateFAExperement,
  checkPermitCreateFISHExperement,
  checkPermitCreateIHCExperement,
  checkPermitCreatePCRExperement,
  checkPermitCreateSSExperement,
  checkPermitUpdateExperement,
  getTokens
} from "../../../store/auth/selectors";
import { getServiceUsers, getServiceUsersLoading } from "../../../store/serviceUsers/selectors";
import { fetchServiceUsers } from "../../../store/serviceUsers/thunkActions";
import LastExperiment from "./LastExperiment";
import { getDrugByMarkerMap } from "../../../store/markers/selectors";
import { fetchMarkers } from "../../../store/markers/thunkActions";
import InfoModal from "../../../components/shared/InfoModal";
import Textarea from "../../../components/shared/Textarea";
import Radio from "../../../components/shared/Radio";
import { fetchMolecularProfiling, putExaminationBlueprintsUpdate } from "../../../store/molecularProfiling/thunkActions";
import { getUpdatedBlueprints } from "../../../store/molecularProfiling/selectors";
import Button, { SIZE, VARIANT } from "../../../components/shared/Button";
import { transformBloodId } from "../../../utils/stringHelpers";
import ExperimentPCR from "./Experiments/ExperimentPCR";
import ExperimentFA from "./Experiments/ExperimentFA";
import ExperimentSS from "./Experiments/ExperimentSS";
import ExperimentIHC from "./Experiments/ExperimentIHC/ExperimentIHC";
import ExperimentFISH from "./Experiments/ExperimentFISH/ExperimentFISH";
import Tooltip from "../../../components/shared/Tooltip";
import Concilium from "../../../components/shared/Concilium";
import { IMolProRequest } from "../../../store/molecularProfiling/model";
import { format } from "date-fns";
import Loader from "../../../components/shared/Loader";



interface IComponentProps {
  data?: any;
  [index: string]: any;
}

export interface IExecutorOptions {
  value: string;
  label: string;
  disabled: boolean;
  title: string;
  fullName: string;
}

export interface IAdvisorUpdate {
  advisor_ulid: string;
  full_name: string;
  specialization: string;
  blueprint: number
}

export interface IBlueprintAdvisors {
  advisorUlid: string;
  fullName: string;
  specialization: string;
  blueprint: number
}

export interface IBlueprintExecutors {
  blueprint: number
  executor?: string
  assistant?: string
  firstName: string
  lastName: string
  middleName: string
  specialization: string
}

export interface IExamExecutors {
  blueprintAdvisory: IBlueprintAdvisors[];
  blueprintExecutors: IBlueprintExecutors[];
  blueprintAssistants: IBlueprintExecutors[];
  [index: string]: any;
}

export interface IExamExecutorsMap {
  [index: string]: IExamExecutors;
}

const methodsFromUrl: { [index: string]: string; } = {
  pcr: 'ПЦР',
  ss: 'Секвенирования по Сэнгеру',
  ihc: 'ИГХ',
  fish: 'FISH',
  fa: 'Фрагментного анализа',
}

function shortName(fName: string, lName: string, mName: string) {
  let lastName = lName ? lName[0].toUpperCase() + '.' : '';
  let midName = mName ? mName[0].toUpperCase() + '.' : '';
  return `${fName} ${lastName} ${midName}`;
}

const reasonCancelidation: { [index: string]: string; } = {
  noNeeded: 'Нет необходимости в исследовании',
  noReagents: 'Нет реагентов',
  other: 'Иное'
}
// может еще пригодится
const getBlockOrBloodNumber = (data: any) => {
  let formatedNumber = '';
  let bloodNumber = (data ?? '0').toString();
  formatedNumber = (transformBloodId(bloodNumber)).toString().padStart(6, "0").replace(/(?=(?:.{3})*$)/g, ' ');

  return formatedNumber;
}

function checkTextData(data: string | undefined | null) {
  return !!data ? data : 'Нет данных'
}

const CreateExperiment: FC<IComponentProps> = ({ data }) => {
  const { register, unregister, control, watch, formState: { errors }, clearErrors, setError, setValue, getValues } = useForm();

  const { id: referralULID, method: methodUrlParam } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tokens = useSelector(getTokens);
  const researchList = useSelector(getResearchesList);
  const analysisData = useSelector(getAnalisys);
  const doctorsList = useSelector(getServiceUsers);
  const analisysError = useSelector(getAnalisysError);
  const drugByMarkerMap = useSelector(getDrugByMarkerMap);
  const newExperimentCompleted = useSelector(getNewExperimentData);
  const updatedBluepritData = useSelector(getUpdatedBlueprints);

  const ResearchListLoading = useSelector(getResearchListLoading);
  const AnalisysLoading = useSelector(getAnalisysLoading);
  const ServiceUsersLoading = useSelector(getServiceUsersLoading);

  //Permissions
  const ableToCreatePCRExperement = useSelector(checkPermitCreatePCRExperement);
  const ableToCreateSSExperement = useSelector(checkPermitCreateSSExperement);
  const ableToCreateFAExperement = useSelector(checkPermitCreateFAExperement);
  const ableToCreateIHCExperement = useSelector(checkPermitCreateIHCExperement);
  const ableToCreateFISHExperement = useSelector(checkPermitCreateFISHExperement);
  const ableToUpdateExperement = useSelector(checkPermitUpdateExperement);


  const [loadingError, setLoadingError] = useState(false);
  const [blueprintData, setBlueprintData] = useState<IResearch | { [index: string]: any }>({});
  const [analisysByMethod, setAnalisysByMethod] = useState<IAnalisysByMethod | { [index: string]: any }>({});
  const [isSelectedOptions, setSelectedOptions] = useState([0]);
  const [addAttempt, setAddAttempt] = useState<boolean>(false);
  const [advisorList, setAdvisorList] = useState<IAdvisorUpdate[]>();

  const ableToExperementCreate = useMemo(() => {
    if (analisysByMethod?.markerPcr) return ableToCreatePCRExperement;
    if (analisysByMethod?.markerFa) return ableToCreateFAExperement;
    if (analisysByMethod?.markerSs) return ableToCreateSSExperement;
    if (analisysByMethod?.markerIhc) return ableToCreateIHCExperement;
    if (analisysByMethod?.markerFish) return ableToCreateFISHExperement;
    return false;
  }, [analisysByMethod, ableToCreatePCRExperement, ableToCreateSSExperement,
    ableToCreateFAExperement, ableToCreateIHCExperement, ableToCreateFISHExperement])

  //Modals
  const [flagCancelWithReason, setFlagCancelWithReason] = useState(false);
  const watchСancelReason = watch('cancelReason', false);
  const watchСancelReasonText = watch('reasonText', false);

  const [show, setShow] = useState(false);
  const [isExperimentComplite, setExperimentComplite] = useState(false);
  const [isExperementCancelled, setExperementCancelled] = useState(false);
  const [isNoFinishedExperement, setNoFinishedExperement] = useState(false);

  const [showСompletedExperimentModal, setShowСompletedExperimentModal] = useState(false);
  const [showCancelExperimentModal, setShowCancelExperimentModal] = useState(false);

  const [showSeccessModal, setshowSeccessModal] = useState(false);

  const onOpenСompletedExperimentModal = useCallback(() => setShowСompletedExperimentModal(true), []);
  const onCloseСompletedExperimentModal = useCallback(() => setShowСompletedExperimentModal(false), []);

  const onOpenCancelExperimentModal = useCallback(() => setShowCancelExperimentModal(true), []);
  const onCloseCancelExperimentModal = useCallback(() => {
    setShowCancelExperimentModal(false);
    setFlagCancelWithReason(false);
    unregister(['cancelReason', 'reasonText'], { keepValue: false });

  }, [setFlagCancelWithReason, setShowCancelExperimentModal, unregister]);

  const onOpenCancelSeccessModal = useCallback(() => {
    onCloseCancelExperimentModal();
    onCloseСompletedExperimentModal();
    setshowSeccessModal(true);

  }, [onCloseCancelExperimentModal, onCloseСompletedExperimentModal, setshowSeccessModal]);

  const [examNumber, examMethod, bluprintNumber] = useMemo(() => {
    if (!methodUrlParam) return ['', { name: '', code: '' }]

    let [number, method] = methodUrlParam?.split('_') ?? ['0', '', ''];
    let examNumber = number.padStart(6, "0").replace(/(?=(?:.{3})*$)/g, ' ');
    let examMethod = { name: methodsFromUrl[method], code: method };

    return [examNumber, examMethod, +number ?? 0];
  }, [methodUrlParam]);

  //handle reason text, option field;
  useEffect(() => {
    if (!watchСancelReason) return;
    if (watchСancelReason !== reasonCancelidation.other) {
      setValue('reasonText', '');
    }
    clearErrors();

  }, [watchСancelReason, watchСancelReasonText, setValue, clearErrors]);

  /** modals end */

  const isLoading = useMemo(() => {
    if (ResearchListLoading || AnalisysLoading || ServiceUsersLoading) return true;
    return false;
  }, [ResearchListLoading, AnalisysLoading, ServiceUsersLoading]);

  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      if (!isLoading && !show) {
        setShow(true)
      } else {
        return null;
      }
    }, 1000);

    return () => window.clearTimeout(timeoutID);
  }, [setShow, isLoading, show]);

  useMemo(() => {
    // if(!blueprintData || !data || !analisysByMethod || isLoading) return [true,true,true];
    let blueprintData = data?.examinationReferral?.examinationBlueprints?.find((bluprint: any) => bluprint?.id === bluprintNumber);
    if (blueprintData?.status) {
      let complete = blueprintData?.status === 'Complete';
      let cancelled = !!data?.statusCancellation || (blueprintData?.status === 'Cancelled');

      let IHCMarker: MarkerIhcExperiment[] = analisysByMethod?.analysisIhcExperiments;
      let FISHMarker: MarkerFishExperiment[] = analisysByMethod?.analysisFishExperiments;

      let hasNoFinishedExperementIHC = IHCMarker?.some((experement: MarkerIhcExperiment) => !experement?.completed);
      let hasNoFinishedExperementFISH = FISHMarker?.some((experement: MarkerFishExperiment) => !experement?.completed);
      let isNoFinishedExperement = false;

      if (IHCMarker?.length) isNoFinishedExperement = hasNoFinishedExperementIHC;
      if (FISHMarker?.length) isNoFinishedExperement = hasNoFinishedExperementFISH;

      setExperimentComplite(complete);
      setExperementCancelled(cancelled);
      setNoFinishedExperement(isNoFinishedExperement);
      setBlueprintData(blueprintData);
    }

  }, [analisysByMethod, data, bluprintNumber, setExperimentComplite, setExperementCancelled, setNoFinishedExperement,setBlueprintData]);

  const isReferralComplite = useMemo(() => {
    let complite = !!data?.statusCancellation || data?.status === 'referral_completed';
    return !!complite
  }, [data]);

  useEffect(() => {
    if (analisysError) setLoadingError(!!analisysError);
    if (newExperimentCompleted) {
      setAddAttempt(false);
    }
    if (updatedBluepritData?.id) {
      //modal show
      onOpenCancelSeccessModal()
    }
    return () => {
      setLoadingError(false);
      setAddAttempt(false);
    };
  }, [analisysError, setLoadingError, newExperimentCompleted, tokens?.access, referralULID,
    setAddAttempt, dispatch, updatedBluepritData, onOpenCancelSeccessModal]);


  const goBack = useCallback(() => {
    navigate(`/molecular-profiling/detail/${referralULID}/referral`);
  }, [navigate, referralULID]);

  const stayHere = useCallback(() => {
    setshowSeccessModal(false);
    navigate(`/molecular-profiling/detail/${referralULID}/${methodUrlParam}`);
  }, [navigate, referralULID, methodUrlParam]);


  useLayoutEffect(() => {
    let examNumber = parseInt(methodUrlParam ?? '0');
    let method = methodUrlParam?.split('_')[1];


    if (!data?.id) return;
    let blueprintULID = data.examinationReferral.examinationBlueprints.find((bluprint: any) => bluprint?.id === examNumber)?.ulid;

    if (tokens?.access && referralULID && data?.diagnosis) {
      dispatch(resetAnalysisByBluprint());
      dispatch(fetchResearch(tokens?.access, { ulid: referralULID }))
      dispatch(fetchMarkers(tokens?.access, data?.diagnosis?.localization));
    };
    if (tokens?.access && method && blueprintULID) {
      dispatch(resetAnalysisByBluprint());
      if (method) dispatch(fetchAnalysisByBluprint(tokens?.access, method, blueprintULID))
      else dispatch(fetchAnalysisByBluprint(tokens?.access, method, blueprintULID));
    }
    return () => {
      //clear state and analysis store
      setAnalisysByMethod({});
      setBlueprintData({});
      if (tokens?.access) {
        dispatch(fetchServiceUsers(tokens?.access, { organizationId: 0 }))
        dispatch(resetAnalysisByBluprint());
        // dispatch(resetMolecularProfiling());
        // referralULID && dispatch(fetchMolecularProfiling(tokens?.access, referralULID))
        dispatch(fetchResearch(tokens?.access, { ulid: referralULID }))
        dispatch(fetchMarkers(tokens?.access, data?.diagnosis?.localization));
      };
    }
  }, [tokens?.access, dispatch, referralULID, setAnalisysByMethod, setBlueprintData, data, methodUrlParam]);

  useEffect(() => {
    //set bluprint data
    if (researchList?.length && !blueprintData?.id && !!methodUrlParam) {
      if (!data?.examinationReferral) return;
      let examNumber = parseInt(methodUrlParam);
      let blueprintFomData = data?.examinationReferral?.examinationBlueprints?.find((bluprint: any) => bluprint?.id === examNumber);
      let blueprintULID = blueprintFomData?.ulid;
      let fullBlueprint = researchList?.find((bluprint: any) => bluprint?.ulid === blueprintULID);

      if (!!blueprintULID && fullBlueprint?.ulid) {
        setBlueprintData(fullBlueprint);
      }
      if (!fullBlueprint?.ulid && blueprintULID) {
        setBlueprintData(blueprintFomData);
      }
    }

  }, [researchList, methodUrlParam, blueprintData, data?.examinationReferral]);


  const handleSummaryChecked = useCallback((index) => {
    if (!index) return;
    const hasSelected = isSelectedOptions.includes(index);
    if (hasSelected) return setSelectedOptions(isSelectedOptions.filter(options => options !== index));

    return setSelectedOptions(isSelectedOptions.concat(index));
  }, [isSelectedOptions, setSelectedOptions]);



  const [executorOptions, defaultExecutorOptions, advisorOptionsList] = useMemo(() => {
    if (!doctorsList) return [];
    let examNumber = +(parseInt(methodUrlParam ?? '0'));
    //find advisor
    const advisorOptionsList = doctorsList
      .filter((user: any) => user.userRegistryPermissions.advisory)
      .map((advisor: any) => {
        let { firstName, lastName, middleName, title: specialization } = advisor.userProfile;
        let full_name = `${lastName} ${firstName} ${middleName}`.trim();
        let label = `${full_name}, ${specialization}`;
        return { value: advisor.ulid, label, specialization, full_name, advisor_ulid: advisor?.ulid ?? '', blueprint: +examNumber }
      });

    //find geneticist coordinator or user whocreated attempt
    const options = doctorsList
      // .filter((user: any) => {
      //   return user.userRegistryPermissions.geneticistCoordinator
      // })
      .map((genetic: any) => {
        let { firstName, lastName, middleName, title } = genetic.userProfile;
        let label = shortName(firstName, lastName, middleName);
        let fullName = `${lastName} ${firstName} ${middleName}`.trim();
        return { value: genetic.ulid, label, title, fullName, disabled: false }
      });

    let defaultExecutorMap: { [index: string]: any; } = {};

    options.forEach((option: any) => {
      defaultExecutorMap[option.value] = { name: option.label, title: option.title, fullName: option.fullName };
    });

    let optionsList: IExecutorOptions[] = [{ value: '', label: '--', disabled: true, title: '', fullName: '' }, ...options];

    return [optionsList, defaultExecutorMap, advisorOptionsList];
  }, [doctorsList, methodUrlParam]);

  const examExecutors = useMemo(() => {
    const examExecutorsMap: IExamExecutorsMap = {};
    if (!data?.examinationReferral?.examinationBlueprints) return examExecutorsMap;
    let examinationBlueprints = data?.examinationReferral?.examinationBlueprints ?? [];
    for (let blp of examinationBlueprints) {
      let { blueprintAdvisory, blueprintAssistants, blueprintExecutors = [], ulid } = blp;
      if (!blueprintExecutors?.length && defaultExecutorOptions) {
        let executorData = { blueprint: blp.id, firstName: defaultExecutorOptions[blp?.executor ?? '']?.fullName ?? '', specialization: defaultExecutorOptions[blp?.executor ?? '']?.title };
        blueprintExecutors = [executorData]
      }
      examExecutorsMap[ulid] = { blueprintAdvisory, blueprintAssistants, blueprintExecutors }
    }

    return examExecutorsMap;
  }, [data?.examinationReferral, defaultExecutorOptions]);


  const lastExperiments = useMemo(() => {
    let experiments = (analisysByMethod?.analysisExperiments || analisysByMethod?.analysisSsExperiments) ?? [];
    //IHS
    if (analisysByMethod?.markerIhc?.name !== 'ALK') {
      const complited = analisysByMethod?.analysisIhcExperiments
        ?.filter((exp: MarkerIhcExperiment) => !!exp.completed)
        ?.sort((a: MarkerIhcExperiment, b: MarkerIhcExperiment) => a.number - b.number) ?? [];

      if (!!complited && complited?.length) {
        let { markerIhcConclusions, markerIhcReferences, markerIhcResults, name } = analisysByMethod.markerIhc;
        let conclusionMap: { [index: number]: string } = {};
        let resultsMap: { [index: number]: string } = {};
        //Conclusion value Map
        for (let concl of markerIhcConclusions ?? []) {
          conclusionMap[concl.id] = concl.content;
        }
        //results value Map
        for (let { id, content } of markerIhcResults ?? []) {
          resultsMap[+id] = content;
        }


        experiments = complited.map((exp: MarkerIhcExperiment) => ({ ...exp, name, conclusionMap, resultsMap, markerIhcReferences, analisysByMethod }))
      }
    };
    //IHC ALK
    if (analisysByMethod?.markerIhc?.name === 'ALK') {
      const complited = analisysByMethod?.analysisIhcExperiments
        ?.filter((exp: MarkerIhcAlkExperiment) => !!exp.completed)
        ?.sort((a: MarkerIhcAlkExperiment, b: MarkerIhcAlkExperiment) => a.number - b.number) ?? [];

      if (!!complited && complited?.length) {
        let { markerIhcClones, markerIhcResults, name } = analisysByMethod.markerIhc;
        let clonesMap: { [index: number]: string } = {};
        let resultMap: { [index: number]: string } = {};
        let conclusionMap: { [index: number]: string } = {};
        let characteristicMap: { [index: number]: string } = {};

        //Clone Name
        markerIhcClones?.forEach((clone: IMarkerIHCClone) => {
          clonesMap[clone.id] = clone.name;
        });
        //Options value Map
        for (let { id, content, conclusions, characteristics } of markerIhcResults) {
          resultMap[+id] = content;
          for (let { content, id } of conclusions) {
            conclusionMap[+id] = content;
          }
          for (let { content, id } of characteristics) {
            characteristicMap[+id] = content;
          }
        }
        experiments = complited.map((exp: MarkerIhcAlkExperiment) => ({ ...exp, name, clonesMap, resultMap, conclusionMap, characteristicMap, analisysByMethod }))
      }
    };
    //IHC Clones
    if (analisysByMethod?.markerIhc?.markerIhcClones?.length && analisysByMethod?.markerIhc?.name !== 'ALK') {
      const complited = analisysByMethod?.analysisIhcExperiments
        ?.filter((exp: MarkerIhcAlkExperiment) => !!exp.completed)
        ?.sort((a: MarkerIhcAlkExperiment, b: MarkerIhcAlkExperiment) => a.number - b.number) ?? [];

      if (!!complited && complited?.length) {
        let { markerIhcClones, markerIhcResults, name, markerIhcReferences } = analisysByMethod.markerIhc;
        let referencesMap: { [index: number]: string[] } = {};
        let clonesMap: { [index: number]: string } = {};
        let resultMap: { [index: number]: string } = {};
        let conclusionMap: { [index: number]: string } = {};
        let characteristicMap: { [index: number]: string } = {};

        //Clone Name
        markerIhcClones?.forEach((clone: IMarkerIHCClone) => {
          clonesMap[clone.id] = clone.name;
        });
        for (let reference of markerIhcReferences) {
          if (!referencesMap[reference?.cloneIhc]) referencesMap[reference?.cloneIhc] = []
          referencesMap[reference?.cloneIhc].push(reference?.content ?? '');
        }
        //Options value Map
        for (let { id, content, conclusions, characteristics } of markerIhcResults) {
          resultMap[+id] = content;
          for (let { content, id } of conclusions) {
            conclusionMap[+id] = content;
          }
          for (let { content, id } of characteristics) {
            characteristicMap[+id] = content;
          }
        }

        experiments = complited.map((exp: MarkerIhcAlkExperiment) => ({ ...exp, name, clonesMap, resultMap, referencesMap, analisysByMethod }))
      }
    };

    //FISH
    if (analisysByMethod?.analysisFishExperiments) {
      const complited = analisysByMethod?.analysisFishExperiments
        ?.filter((exp: MarkerFishExperiment) => !!exp.completed)
        ?.sort((a: MarkerFishExperiment, b: MarkerFishExperiment) => a.number - b.number) ?? [];
      if (!!complited && complited?.length) {
        let { kit, results, references, name } = analisysByMethod.markerFish; //signals,localizations in Amplification
        // let conclusionMap: { [index: number]: string } = {};
        let resultMap: { [index: number]: string } = {};
        let kitMap: { [index: number]: string } = {};
        let signalsMap: { [index: string | number]: Signal } = {};

        if (!!kit && kit?.length) {
          for (let kitData of kit) {
            kitMap[kitData.id] = kitData.name;
            signalsMap[kitData.name] = kitData.signals;
            signalsMap[kitData.id] = kitData.signals;
          }
        }

        for (let resultData of results) {
          resultMap[resultData.id] = resultData.content;
          // for (let conclusion of resultData.results) {
          //   conclusionMap[conclusion.id] = conclusion.content
          // }
        }

        experiments = complited.map((exp: MarkerFishExperiment) => ({ ...exp, name, resultMap, kitMap, signalsMap, references, analisysByMethod }));
      }
    }


    if (!experiments?.length) return [];
    const Experiments = [...experiments].reverse();

    return Experiments;
  }, [analisysByMethod]);


  const flowStepsActiveIndex = useMemo(() => {
    const steps = [...FLOW_STEPS_EXAMINATION];

    const canceled = blueprintData.status === 'Cancelled';
    if (canceled) steps[3] = 'Исследование отменено';

    let index = lastExperiments.length;
    if (addAttempt) index = index + 1;

    const done = blueprintData?.status === 'Complete';
    const donePoint = lastExperiments.length;

    if (blueprintData?.status === 'Cancelled' && !lastExperiments.length) return { index, canceled, done, donePoint, steps };
    if (blueprintData.status === 'In Progress' && !lastExperiments.length) return { index, canceled, done, donePoint, steps };

    return { index, canceled, done, donePoint, steps };
  }, [blueprintData, addAttempt, lastExperiments]);



  const headerData = useMemo(() => {
    if (!data?.examinationReferral || !methodUrlParam) return;
    let marker = '', drugList = '', bioMaterial = { block: '', blockNumber: '', blood: '', bloodNumber: '' }
    let examNumber = parseInt(methodUrlParam);
    let blueprint = data.examinationReferral.examinationBlueprints.find((bluprint: any) => bluprint?.id === examNumber);

    if (blueprint?.ulid) {
      //marker name
      marker = blueprint?.marker ?? '';

      //biomaterial
      const priority = blueprint?.bio[0]?.material?.priority || blueprint?.bio[1]?.material?.priority;
      if (priority && blueprint?.bio?.length === 1) {
        // bioMaterial.block = `Парафиновый блок, К${priority}`;
        bioMaterial.block = `мс20210311/001`;
        bioMaterial.blockNumber = blueprint?.bio[0]?.material?.cypher;

      }
      if (priority && blueprint?.bio?.length > 1) {
        bioMaterial.block = `Парафиновый блок, К${priority}`;
        bioMaterial.blockNumber = blueprint?.bio[0]?.material?.cypher;
        bioMaterial.blood = `Кровь`;
        bioMaterial.bloodNumber = blueprint?.bio[1]?.material?.bloodId;

      }
      if (!priority && blueprint?.bio?.length && blueprint?.bio?.[0]?.type === "Blood") {
        bioMaterial.blood = `Кровь`;
        bioMaterial.bloodNumber = blueprint?.bio[0]?.material?.bloodId;
      }
      if (!blueprint?.bio?.length) bioMaterial.block = '';

      //drug names
      let markerUlid;

      if (analisysByMethod?.markerPcr) {
        markerUlid = analisysByMethod.markerPcr.markerUlid;
      }
      if (analisysByMethod?.markerFa) {
        markerUlid = analisysByMethod.markerFa.markerUlid;
      }
      if (analisysByMethod?.markerSs) {
        markerUlid = analisysByMethod.markerSs.markerUlid;
      }
      if (analisysByMethod?.markerIhc) {
        markerUlid = analisysByMethod.markerIhc.markerUlid;
      }
      if (analisysByMethod?.markerFish) {
        markerUlid = analisysByMethod.markerFish.markerUlid;
      }

      const drugListSet = Array.from(drugByMarkerMap.get(markerUlid) ?? []);
      if (drugListSet && drugListSet.length) {
        drugList = [...drugListSet].toString().replaceAll(',', ', ');
      }
    }

    return { marker, drugList, bioMaterial }
  }, [analisysByMethod, drugByMarkerMap, data, methodUrlParam]);


  const handleAddAtempt = useCallback(() => {
    if (isExperimentComplite || isExperementCancelled || isNoFinishedExperement) return setAddAttempt(false);
    if (lastExperiments.length < 3) setAddAttempt(true);
  }, [setAddAttempt, lastExperiments, isExperimentComplite, isExperementCancelled, isNoFinishedExperement]);

  const handleDelAtempt = useCallback(() => {
    if (isExperimentComplite || isExperementCancelled || isNoFinishedExperement) return setAddAttempt(false);
    if (lastExperiments.length < 3) setAddAttempt(false);
  }, [setAddAttempt, lastExperiments, isExperimentComplite, isExperementCancelled, isNoFinishedExperement]);

  // Modal handlers

  //cancel experement
  const onCancelExperimentConfirm = useCallback(async (createNewEperement?: boolean) => {

    let validation = true;
    let [cancelReason, reasonText] = getValues(['cancelReason', 'reasonText']);

    let id: number = blueprintData.id;
    let status: string = 'Cancelled';
    let cancellation_reason: string = !!reasonText ? reasonText : cancelReason;
    let trigger_new_blueprint: boolean = createNewEperement ?? false;

    if (flagCancelWithReason) {
      //errors
      if (!cancelReason) {
        setError('cancelReason', { types: { required: "Укажите причину отмены" } });
        validation = false;
      }
      if (cancelReason && cancelReason === reasonCancelidation.other && !(reasonText.trim())) {
        setError('reasonText', { types: { required: "Укажите причину отмены" } });
        validation = false;
      }
    }

    if (flagCancelWithReason && validation && tokens?.access && !trigger_new_blueprint) {
      setFlagCancelWithReason(createNewEperement ?? false);
      await dispatch(putExaminationBlueprintsUpdate(tokens?.access, id, { status, cancellation_reason, trigger_new_blueprint }));
      onCloseCancelExperimentModal();
    }

    //cancel and create new one  
    if (tokens?.access && trigger_new_blueprint) {
      await dispatch(putExaminationBlueprintsUpdate(tokens?.access, id, { status, trigger_new_blueprint }));
      onCloseCancelExperimentModal();
      !!referralULID && dispatch(fetchMolecularProfiling(tokens.access, referralULID))
    }

  }, [tokens?.access, dispatch, setFlagCancelWithReason,
    getValues, setError, blueprintData, flagCancelWithReason,
    onCloseCancelExperimentModal,referralULID]);


  //seccesful complite experement
  const onСompleteExperimentConfirm = useCallback(async (createNewEperement?: boolean) => {

    const analysisExperiments: AnalysisExperiment[] = (analisysByMethod?.analysisExperiments || analisysByMethod.analysisSsExperiments) ?? [];
    const analysisIHC_Experements = analisysByMethod?.markerIhc?.name !== 'ALK' ? analisysByMethod?.analysisIhcExperiments : [];
    const analysisIHC_ALK_Experements = analisysByMethod?.markerIhc?.name === 'ALK' ? analisysByMethod?.analysisIhcExperiments : [];
    const analysisFISH_Experements = analisysByMethod?.analysisFishExperiments ?? [];

    const experementId: number = blueprintData?.id;
    const status: string = 'Complete';
    const trigger_new_blueprint: boolean = createNewEperement ?? false;
    const data = { acceptance: true };

    if (tokens?.access && analysisExperiments?.length) {
      dispatch(putExaminationBlueprintsUpdate(tokens?.access, experementId, { status, trigger_new_blueprint }));

      for (let selectedExperement of analysisExperiments) {
        if (selectedExperement?.id && isSelectedOptions.includes(selectedExperement?.id)) {
          dispatch(putExperimentUpdate(tokens?.access, examMethod.code, selectedExperement.id, data));
        }
      }
    }

    if (tokens?.access && analysisIHC_Experements?.length) {
      dispatch(putExaminationBlueprintsUpdate(tokens?.access, experementId, { status, trigger_new_blueprint }));
      for (let selectedExperement of analysisIHC_Experements) {

        if (selectedExperement?.id && isSelectedOptions.includes(selectedExperement?.id)) {
          let ihcData = {
            ...data,
            labAssistant: selectedExperement.labAssistant,
            analysis: selectedExperement.analysis
          }
          // dispatch(putExperimentUpdate(tokens?.access, 'ihc/ihc', selectedExperement.id, ihcData));
          dispatch(putExperimentUpdate(tokens?.access, 'ihc', selectedExperement.id, ihcData));
        }
      }
    }

    if (tokens?.access && analysisIHC_ALK_Experements?.length) {
      dispatch(putExaminationBlueprintsUpdate(tokens?.access, experementId, { status, trigger_new_blueprint }));
      for (let selectedExperement of analysisIHC_ALK_Experements) {

        if (selectedExperement?.id && isSelectedOptions.includes(selectedExperement?.id)) {
          let ihcData = {
            ...data,
            labAssistant: selectedExperement.labAssistant,
            analysis: selectedExperement.analysis
          }
          dispatch(putExperimentUpdate(tokens?.access, 'ihc', selectedExperement.id, ihcData));
        }
      }
    }

    if (tokens?.access && analysisFISH_Experements?.length) {
      dispatch(putExaminationBlueprintsUpdate(tokens?.access, experementId, { status, trigger_new_blueprint }));
      for (let selectedExperement of analysisFISH_Experements) {

        if (selectedExperement?.id && isSelectedOptions.includes(selectedExperement?.id)) {
          let fishData = {
            ...data,
            labAssistant: selectedExperement.labAssistant,
            analysis: selectedExperement.analysis
          }
          dispatch(putExperimentUpdate(tokens?.access, 'fish', selectedExperement.id, fishData));
        }
      }
    }

    //send Advisors
    if (tokens?.access && advisorList?.length && analisysByMethod?.blueprint) {
      dispatch(postAnalysisAdvisorCreate(tokens?.access, advisorList))
    };

    if(tokens?.access && referralULID){
      dispatch(fetchMolecularProfiling(tokens.access, referralULID));
    }

    onCloseСompletedExperimentModal();
  }, [tokens?.access, dispatch, isSelectedOptions, analisysByMethod, blueprintData, examMethod, onCloseСompletedExperimentModal, advisorList, referralULID]);

  const actionModalText = useMemo(() => {
    const newBlueprintNumber = updatedBluepritData?.blueprintNewId;
    const newExamNumber = newBlueprintNumber?.toString()?.padStart(6, "0")?.replace(/(?=(?:.{3})*$)/g, ' ');
    let currentNumber = updatedBluepritData?.id ?? examNumber;
    if (typeof currentNumber === 'number') currentNumber = currentNumber?.toString()?.padStart(6, "0")?.replace(/(?=(?:.{3})*$)/g, ' ');
    let result = '';
    if (updatedBluepritData?.status === 'Cancelled') result = `Исследование № ${currentNumber} успешно отменено`;
    if (updatedBluepritData?.status === 'Complete') result = `Текущее исследование завершено`;

    if (newBlueprintNumber) result = result + `\n\n Исследование №${newExamNumber} успешно создано`;

    return result;

  }, [updatedBluepritData, examNumber]);

  useEffect(() => {
    setAnalisysByMethod(analysisData ?? {});
  }, [analysisData, setAnalisysByMethod]);

  useLayoutEffect(() => {
    tokens?.access && referralULID && dispatch(fetchMolecularProfiling(tokens?.access, referralULID))
  }, [tokens?.access, referralULID, dispatch])

  useLayoutEffect(() => {
    //---------all ----------------
    let experiments = [];
    experiments = analisysByMethod?.analysisExperiments ?? [];

    //---------ihc ----------------
    let ihcExpComplited = 0;
    let ihcExpNotComplited = 0;

    if (!experiments.length) experiments = analisysByMethod?.analysisSsExperiments ?? [];

    if (!experiments.length && analisysByMethod?.analysisIhcExperiments && analisysByMethod?.markerIhc?.name !== 'ALK') {
      (analisysByMethod?.analysisIhcExperiments ?? [])?.forEach((exp: MarkerIhcExperiment) => {
        exp?.completed ? ihcExpComplited = ++ihcExpComplited : ihcExpNotComplited = ++ihcExpNotComplited;
      });
    };
    // if (!experiments.length && analisysByMethod?.analysisIhcAlkExperiments) {
    if (!experiments.length && analisysByMethod?.analysisIhcExperiments && analisysByMethod?.markerIhc?.name === 'ALK') {
      (analisysByMethod?.analysisIhcExperiments ?? [])?.forEach((exp: MarkerIhcAlkExperiment) => {
        exp?.completed ? ihcExpComplited = ++ihcExpComplited : ihcExpNotComplited = ++ihcExpNotComplited;
      });
    };

    //---------fish----------------
    let fishExpComplited = 0;
    let fishExpNotComplited = 0;

    if (!experiments.length && analisysByMethod?.analysisFishExperiments) {
      (analisysByMethod?.analysisFishExperiments ?? [])?.forEach((exp: MarkerFishExperiment) => {
        exp?.completed ? fishExpComplited = ++fishExpComplited : fishExpNotComplited = ++fishExpNotComplited;
      });
    };

    if (!experiments.length) setAddAttempt(true);
    else setAddAttempt(false);

    //Show IHC Markers if bioData partical filled
    if (ihcExpNotComplited) setAddAttempt(true);
    if (ihcExpComplited && !ihcExpNotComplited) setAddAttempt(false);

    //Show FISH Markers if bioData partical filled
    if (fishExpNotComplited) setAddAttempt(true);
    if (fishExpComplited && !fishExpNotComplited) setAddAttempt(false);

  }, [lastExperiments, handleAddAtempt, setAddAttempt, analisysByMethod, handleAddAtempt]);

  const cancelRequestMessage = useMemo(() => {
    let requestReferra = data?.requestReferral?.filter((data: IMolProRequest) => data?.status === 'Cancellation' && data?.pending && !data?.confirmation);

    const getNameAndTitle = (userUlid: string) => {
      let nameAndTitle = '';
      let doctorName = doctorsList?.find((item) => item.ulid === userUlid)?.userProfile;
      if (doctorName) {
        let { firstName = '', lastName = '', middleName = '', title = '' } = doctorName;
        nameAndTitle = `${lastName} ${firstName} ${middleName},`.trim() + '\n' + title;
      };
      return nameAndTitle;
    }

    if (requestReferra && requestReferra?.length) {
      let { pending, comment, confirmation, dateConfirmation, dateCreated, status, userUlid, id } = requestReferra[0];
      let requesterName = getNameAndTitle(userUlid);
      let dateConfirm = dateConfirmation ? format(new Date(dateConfirmation), 'dd.MM.yyyy - HH:mm:ss') : '';
      let dateCreate = dateCreated ? format(new Date(dateCreated), 'dd.MM.yyyy - HH:mm:ss') : '';

      return { id, pending, comment, confirmation, dateConfirmation: dateConfirm, dateCreated: dateCreate, status, user: requesterName }
    }
    return {};
  }, [data?.requestReferral, doctorsList]);

  const markerName = useMemo(() => {
    return analisysByMethod?.markerFa?.name || analisysByMethod?.markerFish?.name || analisysByMethod?.markerIhc?.name ||
      analisysByMethod?.markerPcr?.name || analisysByMethod?.markerSs?.name
  }, [analisysByMethod]);




  const CreateExperementData = (
    <TExperimentBlock>

      <TExaminationNumber><span>Исследование 10000/2024МИ-1</span></TExaminationNumber>
      {/* <TExaminationNumber><span>Исследование {examNumber}</span></TExaminationNumber> */}
      <TBackLink onClick={() => goBack()}><span>&lsaquo;</span>К направлению</TBackLink>

      {/* Method not available */}
      {loadingError &&
        <TCheckBoxListWr>
          <NoDataToShow title='Данных для исследования нет, либо исследование отменено' description=' ' />
        </TCheckBoxListWr>
      }

      {!loadingError && <>
        <TPageTitle>Исследование биоматериала методом {examMethod.name ?? blueprintData?.method?.nameRus}</TPageTitle>
        <FlowStep
          activeIndex={flowStepsActiveIndex?.index}
          flowSteps={flowStepsActiveIndex?.steps}
          canceled={flowStepsActiveIndex?.canceled}
          donePoint={flowStepsActiveIndex?.donePoint}
          done={flowStepsActiveIndex?.done} />

        <TCheckBoxListWr>
          {!(isExperimentComplite || isExperementCancelled || isNoFinishedExperement) && <>
            {!isReferralComplite && !addAttempt && ableToExperementCreate && !data?.statusCancellation && <TAddAttempt
              onClick={() => handleAddAtempt()}
              active={!addAttempt && lastExperiments.length <= 2 && !(isExperimentComplite || isExperementCancelled || isNoFinishedExperement)}>
              Добавить попытку
            </TAddAttempt>}
            {!isReferralComplite && addAttempt && ableToExperementCreate && !data?.statusCancellation && <TAddAttempt
              onClick={() => isNoFinishedExperement ? null : handleDelAtempt()}
              active={addAttempt && lastExperiments.length <= 2 && !(isExperimentComplite || isExperementCancelled || isNoFinishedExperement)}>
              Убрать попытку
            </TAddAttempt>}
          </>}
          <TDetailsFormWr>
            {!!isExperementCancelled && !isLoading && <TCancelReasonBlock>Исследование отменено: <i>{data?.cancellationReason || blueprintData?.cancellationReason}</i></TCancelReasonBlock>}
            {!!Object.keys(cancelRequestMessage)?.length && (
              <TCancelRequestBlock marginLeft={1}>
                <b>Запрос на отмену направления.</b>
                <span><i>Дата формирования запроса: </i> {cancelRequestMessage?.dateCreated}</span>
                <span><i>Инициатор: </i> {cancelRequestMessage?.user}</span>
                <span><i>Причина: </i> {cancelRequestMessage?.comment}</span>
              </TCancelRequestBlock>)}
            <TDetailsContent>
              <TAnalysisConstantWr>
                <TNotEditedInput dataLabel={"Маркер"}>{checkTextData(headerData?.marker)}</TNotEditedInput>
                <TNotEditedInput dataLabel={"Лекарственное средство"}>{checkTextData(headerData?.drugList)}</TNotEditedInput>
                {!!headerData?.bioMaterial?.block && (<>
                  <TNotEditedInput dataLabel={"Номер парафинового блока"}>{checkTextData(headerData?.bioMaterial?.block)}</TNotEditedInput>
                  <TNotEditedInput dataLabel={"Номер направления"}>{'2/2024'}</TNotEditedInput></>)}
                  {/* <TNotEditedInput dataLabel={"Номер"}>{checkTextData(headerData?.bioMaterial?.blockNumber)}</TNotEditedInput></>)} */}
                {!!headerData?.bioMaterial?.blood && (<>
                  <TNotEditedInput>{checkTextData(headerData?.bioMaterial?.blood)}</TNotEditedInput>
                  <TNotEditedInput>{checkTextData(getBlockOrBloodNumber(headerData?.bioMaterial?.bloodNumber))}</TNotEditedInput></>)}
              </TAnalysisConstantWr>
            </TDetailsContent>
            <br />
            <TDetailsItemWr >

              {/* new examinations */}
              {addAttempt && analisysByMethod?.markerPcr && <ExperimentPCR
                flowStepsActiveIndex={flowStepsActiveIndex}
                isSelectedOptions={isSelectedOptions}
                analisysByMethod={analisysByMethod}
                executorOptions={executorOptions}
                isActive={!(isExperimentComplite || isExperementCancelled)}
                isReferralComplite={isReferralComplite}
                referralULID={referralULID ?? ''}
                examExecutors={examExecutors[analisysByMethod?.blueprint]}
              />}
              {addAttempt && analisysByMethod?.markerFa && <ExperimentFA
                flowStepsActiveIndex={flowStepsActiveIndex}
                isSelectedOptions={isSelectedOptions}
                analisysByMethod={analisysByMethod}
                executorOptions={executorOptions}
                isActive={!(isExperimentComplite || isExperementCancelled)}
                isReferralComplite={isReferralComplite}
                referralULID={referralULID ?? ''}
                examExecutors={examExecutors[analisysByMethod?.blueprint]}
              />}
              {addAttempt && analisysByMethod?.markerSs && <ExperimentSS
                flowStepsActiveIndex={flowStepsActiveIndex}
                isSelectedOptions={isSelectedOptions}
                analisysByMethod={analisysByMethod}
                executorOptions={executorOptions}
                isActive={!(isExperimentComplite || isExperementCancelled)}
                isReferralComplite={isReferralComplite}
                referralULID={referralULID ?? ''}
                examExecutors={examExecutors[analisysByMethod?.blueprint]}
              />}
              {addAttempt && analisysByMethod?.markerIhc && <ExperimentIHC
                flowStepsActiveIndex={flowStepsActiveIndex}
                isSelectedOptions={isSelectedOptions}
                analisysByMethod={analisysByMethod}
                executorOptions={executorOptions}
                isActive={!(isExperimentComplite || isExperementCancelled)}
                isReferralComplite={isReferralComplite}
                referralULID={referralULID ?? ''}
                examExecutors={examExecutors[analisysByMethod?.blueprint]}
              />}
              {addAttempt && analisysByMethod?.markerFish && <ExperimentFISH
                flowStepsActiveIndex={flowStepsActiveIndex}
                isSelectedOptions={isSelectedOptions}
                analisysByMethod={analisysByMethod}
                executorOptions={executorOptions}
                isActive={!(isExperimentComplite || isExperementCancelled)}
                isReferralComplite={isReferralComplite}
                referralULID={referralULID ?? ''}
                examExecutors={examExecutors[analisysByMethod?.blueprint]}
              />}


              {/* last examinations results */}
              {!!lastExperiments.length && lastExperiments.map((Experiment: any, ExperimentIndex: number) => (
                <div key={Experiment.number}>

                  {!!ableToUpdateExperement && !isExperementCancelled && !!Experiment?.acceptance ? (
                    <div>
                      {analisysByMethod?.markerPcr && <ExperimentPCR
                        flowStepsActiveIndex={{ ...flowStepsActiveIndex, index: ExperimentIndex }}
                        // isSelectedOptions={isSelectedOptions}
                        analisysByMethod={analisysByMethod}
                        executorOptions={executorOptions}
                        isActive={!(isExperimentComplite || isExperementCancelled)}
                        ableToUpdate={!isExperementCancelled && ableToUpdateExperement}
                        defaultValues={Experiment}
                        isReferralComplite={isReferralComplite}
                        referralULID={referralULID ?? ''}
                        examExecutors={examExecutors[analisysByMethod?.blueprint]}
                      />}
                      {analisysByMethod?.markerFa && <ExperimentFA
                        flowStepsActiveIndex={flowStepsActiveIndex}
                        // isSelectedOptions={isSelectedOptions}
                        analisysByMethod={analisysByMethod}
                        executorOptions={executorOptions}
                        isActive={!(isExperimentComplite || isExperementCancelled)}
                        ableToUpdate={!isExperementCancelled && ableToUpdateExperement}
                        defaultValues={Experiment}
                        isReferralComplite={isReferralComplite}
                        referralULID={referralULID ?? ''}
                        examExecutors={examExecutors[analisysByMethod?.blueprint]}
                      />}
                      {analisysByMethod?.markerSs && <ExperimentSS
                        flowStepsActiveIndex={flowStepsActiveIndex}
                        isSelectedOptions={isSelectedOptions}
                        analisysByMethod={analisysByMethod}
                        executorOptions={executorOptions}
                        isActive={!(isExperimentComplite || isExperementCancelled)}
                        ableToUpdate={!isExperementCancelled && ableToUpdateExperement}
                        defaultValues={Experiment}
                        isReferralComplite={isReferralComplite}
                        referralULID={referralULID ?? ''}
                        examExecutors={examExecutors[analisysByMethod?.blueprint]}
                      />}
                      {analisysByMethod?.markerIhc && <ExperimentIHC
                        flowStepsActiveIndex={flowStepsActiveIndex}
                        isSelectedOptions={isSelectedOptions}
                        analisysByMethod={analisysByMethod}
                        executorOptions={executorOptions}
                        defaultExecutorOptions={defaultExecutorOptions}
                        isActive={!(isExperimentComplite || isExperementCancelled)}
                        ableToUpdate={!isExperementCancelled && ableToUpdateExperement}
                        defaultValues={Experiment}
                        isReferralComplite={isReferralComplite}
                        referralULID={referralULID ?? ''}
                        examExecutors={examExecutors[analisysByMethod?.blueprint]}
                      />}
                      {analisysByMethod?.markerFish && <ExperimentFISH
                        flowStepsActiveIndex={flowStepsActiveIndex}
                        isSelectedOptions={isSelectedOptions}
                        analisysByMethod={analisysByMethod}
                        executorOptions={executorOptions}
                        defaultExecutorOptions={defaultExecutorOptions}
                        isActive={!(isExperimentComplite || isExperementCancelled)}
                        ableToUpdate={!isExperementCancelled && ableToUpdateExperement}
                        defaultValues={Experiment}
                        isReferralComplite={isReferralComplite}
                        referralULID={referralULID ?? ''}
                        examExecutors={examExecutors[analisysByMethod?.blueprint]}
                      />}
                    </div>

                  ) :
                    <LastExperiment
                      key={Experiment.number}
                      Experiment={Experiment}
                      ExperimentIndex={addAttempt ? ExperimentIndex + 1 : ExperimentIndex}
                      defaultExecutorOptions={defaultExecutorOptions}
                      isSelectedOptions={isSelectedOptions}
                      handleChecked={handleSummaryChecked}
                      disbledLastExperements={isExperimentComplite || isExperementCancelled || isNoFinishedExperement}
                      examMethodName={examMethod.code}
                      examExecutors={examExecutors[analisysByMethod?.blueprint]}
                      markerName={markerName}
                    />
                  }
                </div>
              ))}
            </TDetailsItemWr>
          </TDetailsFormWr>
        </TCheckBoxListWr>
        <Tooltip 
          key={`finish-button-${isSelectedOptions.length}-${lastExperiments.length}`} 
          id={`finish-button-tooltip-${!(isSelectedOptions.length > 1) && !isExperimentComplite && !isExperementCancelled && !isNoFinishedExperement}`} 
          place="top">
          <TTooltipContainer>
            Для завершения исследования выберите <br /> финальную попытку с помощью чекбокса
          </TTooltipContainer>
        </Tooltip>
        {ableToExperementCreate && <TButtonWrapper >
          <CustomButton
            onClick={onOpenCancelExperimentModal}
            disabled={isExperimentComplite || isExperementCancelled || isNoFinishedExperement}>Отменить исследование </CustomButton>
          <Button size={SIZE.SMALL} variant={VARIANT.DEFAULT}
            data-tip='' data-for={`finish-button-tooltip-true`}
            onClick={onOpenСompletedExperimentModal}
            disabled={!(isSelectedOptions.length > 1) || isExperimentComplite || isExperementCancelled || isNoFinishedExperement}>Завершить исследование</Button>
        </TButtonWrapper>}
        {/* Modals */}
        <InfoModal
          showModal={showСompletedExperimentModal}
          onModalToggle={onCloseСompletedExperimentModal}
          altTitle={'Завершение исследования'}
          altDescription='Укажите других исполнителей, если необходимо'
          width={851}
          hasCloseButton
          buttons={
            <TButtonVerticalWrapper>
              <Tooltip id="warning" place="top">
                <TTooltipContainer>
                  Позволяет создать дополнительное исследование иным методом
                </TTooltipContainer>
              </Tooltip>

              <CustomButton
                isActive
                width={'468px'}
                onClick={() => onСompleteExperimentConfirm(false)}
                disabled={false}>Завершить</CustomButton>
              <CustomButton
                width={'468px'}
                onClick={() => onСompleteExperimentConfirm(true)}
                disabled={false}><div data-tip='' data-for="warning">Завершить и исследовать иным методом</div></CustomButton>



            </TButtonVerticalWrapper>

          } >
          <Concilium itemIDByDefault={bluprintNumber ?? 0} advisorOptions={advisorOptionsList} setSelectedAdvisorList={(data) => setAdvisorList(data)} />
        </InfoModal>


        <InfoModal
          showModal={showCancelExperimentModal}
          onModalToggle={onCloseCancelExperimentModal}
          isWarning={!flagCancelWithReason}
          title={flagCancelWithReason ? 'Укажите причину отмены' : 'Отменить исследование'}
          hasCloseButton
          buttons={
            !flagCancelWithReason ?
              <TButtonVerticalWrapper>
                <CustomButton isActive width={'468px'} onClick={() => setFlagCancelWithReason(true)} disabled={false}>Отменить с указанием причины</CustomButton>
                <CustomButton width={'468px'} onClick={() => onCancelExperimentConfirm(true)} disabled={false}>Отменить и исследовать иного кандидата</CustomButton>
              </TButtonVerticalWrapper> :
              <CustomButton isActive width={'406px'} onClick={() => onCancelExperimentConfirm(false)} disabled={!watchСancelReason}>Отменить исследование</CustomButton>
          }>
          {flagCancelWithReason &&
            <div className={!!errors?.cancelReason ? 'error' : ''}>
              <TRadioInputsWr>
                <Controller
                  control={control}
                  name='cancelReason'
                  rules={{ required: true }}
                  render={({ field: { onChange, value }, fieldState: { invalid } }) => (
                    <>
                      <Radio selected={value} value={reasonCancelidation.noNeeded} label={reasonCancelidation.noNeeded} onChange={onChange} />
                      <Radio selected={value} value={reasonCancelidation.noReagents} label={reasonCancelidation.noReagents} onChange={onChange} />
                      <Radio selected={value} value={reasonCancelidation.other} label={reasonCancelidation.other} onChange={onChange} />
                    </>
                  )}
                />
                {watchСancelReason === reasonCancelidation.other && <Textarea
                  {...register("reasonText", { required: watchСancelReason === reasonCancelidation.other })}
                  rows={1}
                  cols={100}
                  maxLength={50}
                  placeholder={'Укажите причину'}
                  bordered
                  height={62}
                  disabled={watchСancelReason !== reasonCancelidation.other}
                  readOnly={watchСancelReason !== reasonCancelidation.other}
                  error={!!errors?.reasonText}
                />}
              </TRadioInputsWr>
            </div>
          }

        </InfoModal>

        {/* action status modal */}
        <InfoModal
          showModal={showSeccessModal}
          onModalToggle={stayHere}
          icon={<SuccessModalIcon />}
          width={568}
          title={actionModalText}
          hasCloseButton
          buttons={
            <Button size={SIZE.SMALL} onClick={goBack}>OK</Button>
          } />
      </>}
    </TExperimentBlock>
  );

  if (!show) return <NoDataToShow title='Загружаем данные' description='перезагрузите страницу, если долго видите это сообщение' />;
  if (show) return CreateExperementData;
  return <Loader enabled><NoDataToShow title='Загружаем данные' description='перезагрузите страницу, если долго видите это сообщение' /></Loader>;
}

export default CreateExperiment;