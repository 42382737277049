import styled from 'styled-components';
import Input from "../../../components/shared/Input";
import {CSSProperties} from "react";
import {defaultThemeType} from "../../../styles/theme";

export const TExperimentBlock = styled.div`
  position: relative;
  width: 63%;
  min-width: 550px;
  height: 100vh;
  max-height: 100vh;

  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: #EFEEFF;
  }

  ::-webkit-scrollbar-thumb {
    background: #B7B5F1;
    box-shadow: inset 0 0 1px #EFEEFF;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  scrollbar-color: #B7B5F1 #EFEEFF;


  table tbody {
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to bottom, transparent, #CECECE, transparent) 1 100%;
    border-left: none;
    border-right: none;

    ::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(90deg, transparent, #CECECE 50%, transparent);
    }
  }
`

export const TBackLink = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({theme}) => theme.colors.gray[600]};
  width: max-content;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  margin-top: 8px;
  padding-left: 10px;

  & > *:first-child {
    margin: -3px 3px 0;
    font-size: 24px;
    line-height: 14px;
  }`

export const TExaminationNumber = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 70px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: ${({theme}) => theme.colors.black[200]};
  padding-left: 12px;
`
export const TAddAttempt = styled.div<{ active?: boolean }>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({theme, active}) => active ? theme.colors.product.primaryActive : theme.colors.gray[200]};
  text-decoration: underline;
  padding-left: 20px;
  cursor: pointer;
  margin-bottom: 16px;
`

export const TAnalysisConstantWr = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`

export const TCustomInput = styled(Input)<{ width?: string; background?: string; disabled?:boolean }>`
  /* width: ${({width}) => !!width ? width : '100%'}; */
  position: relative;
  width: 100%;
  & > div > div {
    display: ${({disabled}) => disabled ? 'none' : 'flex'};
    right: 0;
    top: -2px;
  }

  & :read-only {
    cursor: default;
  }

  & input {
    padding: 18px;
    background-color: ${({background}) => background ? background : '#fff'};

  }
`;

export const TNotEditedInput = styled.div<{dataLabel?:string}>`
  position: relative;
  display: inline-block;
  width: 49%;
  border: 1px solid ${({theme}) => theme.colors.gray[400]};
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.gray[500]};
  margin-top: 50px;
  font-weight: 400;
  padding: 18px 16px;
  font-weight: 500;
  box-sizing: border-box;
  height: fit-content;

    b {
      font-weight: 600;
    }
    ::before {
      content: '${props => props.dataLabel}';
      position: absolute;
      top: -31px;
      left: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${({theme}) => theme.colors.gray[500]};
    }
`

export const TFullWidthInput = styled.div<{dataLabel?:string}>`
  position: relative;
  display: inline-block;
  width: 100%;
  border: 1px solid ${({theme}) => theme.colors.gray[400]};
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.gray[500]};
  margin-top: 50px;
  font-weight: 400;
  padding: 18px 16px;
  font-weight: 500;
  box-sizing: border-box;
  height: fit-content;

    b {
      font-weight: 600;
    }
    ::before {
      content: '${props => props.dataLabel}';
      position: absolute;
      top: -31px;
      left: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${({theme}) => theme.colors.gray[500]};
    }
`

export const TWrapper = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 32px;

  .tooltip {
    margin: 0;
    padding: 0;
    width: 222px;
  }

  .no-padding-cell > div {
    padding-bottom: 1px;
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  &.ihc-alk-table {
    & table {table-layout: auto;}
  }

  td {
    position: relative;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;

    color: ${({theme}) => theme.colors.black[200]};
  }

  td.left {
    text-align: left;
    padding-left: 16px;
  }

  div[class*="-menu"] {
    width: 100%;
    min-width: min-content;
  }

  tr.error, td.error {
    border: 1px solid;
    border-color: ${({theme}) => theme.colors.alert.warning};
  }

  .conclusionDescription {
    margin:0;
    padding: 0;
    /* border-top: 1px solid ${({theme}) => theme.colors.gray[200]}; */
    margin: 0 -14px;
    & textarea {
      border-radius: 0;
      border: none;
      padding-left: 24px;
      ::placeholder {
        text-align: left;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0;
        color: ${({theme}) => theme.colors.gray[200]};
        opacity: 1;
      }
    }
  }

  .leftTopRadius {
    border-top-left-radius: 10px;
  }

  .leftBotRadius {
    border-bottom-left-radius: 10px;
  }
  
  .violet {
    background-color: rgba(220, 219, 255, .6);
  }
  
  .head {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: ${({theme}) => theme.colors.gray[500]};
  }
  
  td.rigthTopRadius {
    border-radius: 0;
    border-top-right-radius: 10px;
  }
  
  td.noRadius {
    border-radius: 0px;
  }
  .flex-content {
    padding-bottom: 5px;
    padding-top: 5px;
    height: 60px;
    div:first-of-type {
      vertical-align: top;
    }
    div:last-of-type {
      vertical-align: bottom;
    }
  }

  .borderTop {
    border-top-style: solid;
    border-top-width: 1px;

  }

  .borderRight {
    border-right-style: solid;
    border-right-width: 1px;
  }

  .borderLeftNone {
    border-left: none;
  }

  td.noBorderRadius {
    border-radius: 0;
  }
  td.topRadius {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  td.lastExpConclusion{
    padding: 12px 17px;
  }
  td.lastExpNotes{
    padding: 12px 17px;
    word-wrap: break-word; 
  }

  /* Custom multiselect style */

  div[class$=-control] {
    border-color: ${({theme}) => theme.colors.white};
    background-color: ${({theme}) => theme.colors.white};
    box-shadow: none;
    height: 100%;

    div[class$=-multiValue] {
      border-color: ${({theme}) => theme.colors.white};
      padding: 8px 11px;
      background: ${({theme}) => theme.colors.white};
      border-radius: 10px;
      border: 1px solid ${({theme}) => theme.colors.product.primaryActive};
      text-overflow: ellipsis;
      & :first-child {
        white-space: unset;
      }

      & :last-child {
        :hover {
          background-color: transparent;
        }

        svg {
          border-radius: 50%;
          color: ${({theme}) => theme.colors.white};
          border: 1px solid ${({theme}) => theme.colors.gray[200]};
          background-color: ${({theme}) => theme.colors.gray[200]};

          :hover {
            border-color: ${({theme}) => theme.colors.alert.warning};
            color: ${({theme}) => theme.colors.alert.warning};
          }
        }
      }
    }
  }

  .capitalize {
    ::first-letter {
      text-transform: capitalize;
    }
  }
`;

export const customStylesOptions = (theme: defaultThemeType): Record<string, unknown> => ({
  control: (state: CSSProperties, {isDisabled}: { isDisabled: boolean }) => ({
    ...state,
    outline: 'none',
    transition: 'all 0.2s ease-in-out 0s',
    boxShadow: 'none',
    cursor: 'default',
    padding: '0',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '28px',
    height: '60px',
    backgroundColor: '#fff',
    border: 'none',
    'div[class$="singleValue"]': {
      color: isDisabled ? theme.colors.gray[500] : theme.colors.black[200],
    },
    '&:hover': {
      pointerEvents: isDisabled ? 'none' : 'auto',
      borderColor: isDisabled ? 'transparent' : theme.colors.alert.border,
      cursor: isDisabled ? 'default' : 'pointer',
    },
  }),
  option: (state: CSSProperties, {isSelected}: { isSelected: boolean }) => ({
    ...state,
    height: 'auto',
    textAlign: 'left',
    backgroundColor: theme.colors.white,
    border: 'none',
    outline: 'none',
    color: isSelected ? theme.colors.product.primaryActive : theme.colors.black[200],
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out 0s',
    padding: '8px 14px 8px 12px',
    fontSize: '12px',
    lineHeight: '20px',
    width: '100%',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.colors.product.primaryHover,
      color: theme.colors.white,
    },
  }),
  dropdownIndicator: (
    state: CSSProperties,
    {selectProps: {menuIsOpen}}: { selectProps: { menuIsOpen: boolean } },
  ) => ({
    ...state,
    cursor: 'pointer',
    transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    textAlign: 'center',
    transition: 'all 0.2s ease-in-out 0s',
  }),
  container: () => ({
    position: 'relative',
    // textAlign: 'left !important',
  }),
  indicatorsContainer: () => ({}),
  valueContainer: (base: CSSProperties) => ({
    ...base,
    padding: 0,
    // textAlign: 'center !important',
    // justifyContent: 'center !important',
  }),
  placeholder: (base: CSSProperties) => ({
    ...base,
    color: theme.colors.gray[600],
    textAlign: 'center !important',
  }),
});

export const TDateOfCompletion = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({theme}) => theme.colors.gray[500]};
`;

export const TLaborantWr = styled.div<{ error?: boolean }>`
  display: block;
  position: relative;
  width: 100%;
  margin-top: 30px;
  & > div > div {
    border: 1px solid ${({theme,error}) => error ? theme.colors.alert.warning : theme.colors.gray[500]};
    border-radius: 10px;
  }
`;

export const TExecutor = styled.div<{height?: string}>`
  position: relative;
  min-height: fit-content;
  width: calc(100% - 32px);
  border: 1px solid ${({theme}) => theme.colors.gray[500]};
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.gray[500]};
  margin-top: 52px;
  font-weight: 400;
  padding: 18px 16px;

  b {
    font-weight: 600;
  }

  ::before {
    content: 'Исполнитель';
    position: absolute;
    top: -26px;
    left: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({theme}) => theme.colors.gray[500]};
  }
`;

export const TOtherExecutors = styled.div<{height?: string}>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: flex-start;
  align-items: flex-start;
  position: relative;
  height: ${({height}) => height ? height : '64px'};
  width: calc(100% - 12px);
  border: 1px solid ${({theme}) => theme.colors.gray[500]};
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.gray[500]};
  padding: 18px 16px;
  margin-top: 52px;
  font-weight: 400;
  & div {
    margin-bottom: 12px;
  }

  b {
    font-weight: 600;
  }

  ::before {
    content: 'Другие исполнители';
    position: absolute;
    top: -26px;
    left: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({theme}) => theme.colors.gray[500]};
  }
`;

export const TBioAcquired = styled.div<{height?: string}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: ${({height}) => height ? height : '84px'};
  width: calc(100% - 12px);
  border: 1px solid ${({theme}) => theme.colors.gray[500]};
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.gray[500]};
  padding-left: 12px;
  margin-top: 52px;
  font-weight: 400;

  b {
    font-weight: 600;
  }

  ::before {
    content: 'Окрашивание биоматериала';
    position: absolute;
    top: -26px;
    left: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({theme}) => theme.colors.gray[500]};
  }
`;

export const TAdvisor = styled.div<{height?: string,marginTop?: number}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: ${({height}) => height ? height : '84px'};
  width: calc(100% - 12px);
  border: 1px solid ${({theme}) => theme.colors.gray[500]};
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.gray[500]};
  padding-left: 12px;
  margin-top: ${({marginTop}) => marginTop ? `${marginTop}px` : '52px'};
  font-weight: 400;

  b {
    font-weight: 600;
  }

  ::before {
    content: 'Другие исполнители';
    position: absolute;
    top: -26px;
    left: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({theme}) => theme.colors.gray[500]};
  }

  ul {
    position: relative;
    list-style: none;
    padding: 0 0 12px 0;
  }

  ul li:last-child::before {
    background-image: none;
  }
`;

export const TAdvisorItem = styled.li`
  display: block;
  position: relative;
  color: ${({theme}) => theme.colors.gray[500]};
  padding: 5px 0 5px;
  ::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 2px;
      background-image: linear-gradient(90deg,transparent,#CECECE 50%,transparent);
    }
`


export const TButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 50px;
  margin: 30px 0;
`;

export const TButtonVerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  align-items: center;
  height: 116px;
  width: 100%;
  gap: 24px;
  /* margin: 30px 0; */
`;

export const CustomButton = styled.button<{ disabled: boolean, width?: string, isActive?:boolean }>`
  width: ${({width}) => width ? width : '282px'};
  height: 50px;
  border-radius: 10px;
  border: ${({ theme, disabled }) => disabled ? `2px solid ${theme.colors.gray[300]}` : `2px solid ${theme.colors.violet[500]}`};
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  background-color: ${({theme, isActive, disabled }) => disabled ? (isActive ? theme.colors.gray[300] : 'transparent' ) : (isActive ? theme.colors.violet[500] : 'transparent')};
  color: ${({theme, disabled, isActive}) => disabled ? (isActive ? theme.colors.white : theme.colors.gray[300] ) : (isActive ? theme.colors.white : theme.colors.violet[500])};
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
`;

export const TVerticalButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  font-size: 18px;

  button:not(:disabled) {

    :hover {
      border-color: ${({theme}) => theme.colors.product.primaryHover};
      color: ${({theme}) => theme.colors.product.primaryHover};
    }
  }
`;

export const THorzontalButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 743px;
  font-size: 18px;

  button{
    width: 47%;
  }

  button:not(:disabled) {
    border-color: ${({theme}) => theme.colors.product.primaryActive};
    color: ${({theme}) => theme.colors.product.primaryActive};

    :hover {
      border-color: ${({theme}) => theme.colors.product.primaryHover};
      color: ${({theme}) => theme.colors.product.primaryHover};
    }
  }
`;

export const TTooltipContainer = styled.div`
  padding:0;
  margin-top: -10px;
  margin-bottom: -15px;
`;

export const TRadioInputsWr = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  label {
    padding: 0;
    width: 400px;
    border: none;

    :first-child {
      margin-top: 24px;
    }
  }

  textarea {
    width: 400px;
    margin: 12px 0 0;
  }
`;

export const TCancelReasonBlock = styled.div`
  /* width: 100%; */
  height: max-content;
  border: 2px solid ${({theme}) => theme.colors.product.primaryActive};
  border-radius: 10px;
  padding: 18px 21px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.black[200]};
  cursor: default;

  i {
    color: ${({theme}) => theme.colors.product.primaryActive};
    font-style: normal;
  }
`;

export const TSectionTitle = styled.span<{ width?: string, color?: string }>`
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme, color }) => color ?? theme.colors.black[100]};
  width:${({ width }) => width ?? '100%'};
`;

export const TSSectionTitle = styled.span<{ width?: string, color?: string }>`
  font-style: normal;
  font-size: 16px;
  line-height: 34px;
  color: ${({ theme, color }) => color ?? theme.colors.black[100]};
  width:${({ width }) => width ?? '100%'};
`;

export const TBioDateConfirm = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({theme}) => theme.colors.gray[500]};
`;

export const TLocalizationWR = styled.div<{ error?: boolean }>`
  display: block;
  position: relative;
  width: 100%;
  div[class$="control"] {
    border: 1px solid ${({theme,error}) => error ? theme.colors.alert.warning : theme.colors.gray[500]};
    border-radius: 5px;
  }
`