import styled from "styled-components";
import { Colors } from "../../styles/themeNew";

type Props = {
  selected?: boolean;

  title?: string | null;
  text: string;
  tooltip?: boolean;

  required?: boolean;

  onSelect: () => void;

  disabled?: boolean;
};

export const Radio = (props: Props) => {
  return (
    <StyledContainer onClick={
      props.disabled
          ? undefined
          : props.onSelect
    }>
      {Boolean(props.title) && (
        <StyledTitleContainer>
          {props.title}
          {props.required && (
            <StyledAsteriskContainer>*</StyledAsteriskContainer>
          )}
        </StyledTitleContainer>
      )}

      <StyledMainContainer disabled={props.disabled}>
        <StyledCircleContainer
          selected={props.selected || false}
          disabled={props.disabled}
        >
          {Boolean(props.selected) &&
          <StyledInnerCircleContainer
              disabled={props.disabled}
          />
          }
        </StyledCircleContainer>

        <StyledTextContainer selected={props.selected || false}>
          {props.text}
        </StyledTextContainer>

        {props.tooltip && (
          <StyledIconContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <circle cx="10" cy="10" r="9.5" stroke="#3E6AFF" />
              <line
                x1="10.0859"
                y1="8.83203"
                x2="10.0859"
                y2="14.4987"
                stroke="#3E6AFF"
                strokeLinecap="round"
              />
              <circle cx="9.9974" cy="5.83333" r="0.833333" fill="#3E6AFF" />
            </svg>
          </StyledIconContainer>
        )}
      </StyledMainContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  cursor: pointer;
`;

const getBorderColor = (selected: boolean, disabled: boolean) => {
  if (selected && disabled) {
    return Colors.MONOCHROM[100];
  }
  if (selected) {
    return Colors.BRAND[500];
  }

  return Colors.MONOCHROM[400];
};

const getCircleColor = (disabled?: boolean) => {
  if (disabled) {
    return Colors.MONOCHROM[400];
  }

  return Colors.BRAND[500];
};

const getBackgroundColor = (disabled?: boolean) => {
  if (disabled) {
    return Colors.MONOCHROM[100];
  }

  return "transparent";
};


const StyledCircleContainer = styled.div<{
  selected: boolean;
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;

  background: ${(props) => getBackgroundColor(props.disabled)};

  border: 1px solid ${(props) => getBorderColor(props.selected, Boolean(props.disabled))};
`;

const StyledInnerCircleContainer = styled.div<{ disabled?: boolean }>`
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  background: ${(props) => getCircleColor(props.disabled)};
`;

const getTextColor = (selected: boolean) => {
  if (selected) {
    return Colors.MONOCHROM[950];
  }

  return Colors.MONOCHROM[400];
};

const StyledTextContainer = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 12px);
  color: ${(props) => getTextColor(props.selected)};
`;

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${Colors.MONOCHROM[950]};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;

  gap: 4px;
`;

const StyledMainContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 18px 16px;

  gap: 14px;

  border-radius: 6px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  background: ${(props) => getBackgroundColor(props.disabled)};`;

const StyledAsteriskContainer = styled.div`
  color: ${Colors.RED[500]};
  font-size: 12px;
  font-weight: 400;
`;
