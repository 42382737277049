import { format } from 'date-fns';
import { Speed, Referral } from '../../store/referral/model';
import { Organization } from '../../store/organizations/model';
import { REFERRAL_TYPE } from '../../constants/referral';
import defaultTheme from "../../styles/theme";

import {CustomTooltip, TIndicator, TPriorityField, TStatusTitle, TStatusWr, TTooltip} from './styled';
import getTooltipMessage from "./getTooltipMessage";

type columnsType = {
  organizations: Organization[];
}

export const SPEED = {
  Common: 'Планово',
  Urgent: 'Срочно',
};

export const STATUS:{[index: string]: string;} = {
  bio_pending: 'Прием биоматериала',
  morphology_pending: 'Морфологическая характеристика',
  markers_validation_pending: 'Валидация маркeров',
  analysis_pending: 'Реализация исследований',
  conclusion_pending: 'Формирование заключения',
  referral_pending: 'Направление закрыто',
  referral_canceled: 'Направление отменено',
  referral_completed: 'Направление закрыто',
  
};

export const columns = ({ organizations }: columnsType) => ([
  // {
  //   header: ({ cb, checkedStatus }) => (
  //     <Checkbox
  //       indeterminate={checkedStatus === 1}
  //       checked={checkedStatus === 2}
  //       onChange={() => cb(checkedStatus === 1 ? false : checkedStatus !== 2)}
  //     />
  //   ),
  //   renderer: (item, { checked, cb }) => (
  //     <Checkbox checked={checked} onChange={() => cb({ id: item.id, checked: !checked })}/>
  //   ),
  //   columnWidth: '3%',
  //   id: 1,
  // },
  {
    header: 'Номер',
    renderer: (item: Referral) => item.referralId,
    columnWidth: '10%',
    id: 2,
  },
  {
    header: 'Пациент',
    renderer: (item: Referral) => (
      <>
        <TTooltip id={`patient-table-tooltip-${item.referralId}`} place='top'>
          <p style={{ fontWeight: 600, marginBottom: '4px', color: defaultTheme.colors.gray[500] }}>{`${item.patient.lastName} ${item.patient.firstName} ${item.patient.middleName ?? ''}`}</p>
          <p style={{ fontWeight: 500, color: defaultTheme.colors.gray[500] }}>{`${format(new Date(item.patient.dateOfBirth), 'dd.MM.yyyy')}`}</p>
        </TTooltip>
        <div style={{ cursor: 'pointer' }} data-for={`patient-table-tooltip-${item.referralId}`} data-tip=''>
          {`${item.patient.lastName} ${item.patient.firstName[0]}.${item.patient.middleName ? ` ${item.patient.middleName?.[0]}.` : ''}`}
        </div>
      </>
    ),
    columnWidth: '13%',
    id: 5,
  },
  {
    header: 'Создано',
    renderer: (item: Referral) => item?.dateOfAcquisition ? format(new Date(item.dateOfAcquisition), 'dd.MM.yyyy') : item.dateCreated ? format(new Date(item.dateCreated), 'dd.MM.yyyy') : '',
    columnWidth: '7%',
    id: 3,
  },
  {
    header: 'Учреждение',
    renderer: (item: Referral,index:number) => {
      let customOrgName = item.doctorReferral.userProfile.organizationCustom
      let mainOrgName = organizations.find(({ id }) => id === item.doctorReferral.userProfile.organization)?.nameLocalRus ?? ''
      return (<>
      <CustomTooltip id={`organization-table-tooltip-${index}-${item.doctorReferral.userProfile.organization}${customOrgName}`} place='top'>
          {customOrgName}
      </CustomTooltip>
      <div data-for={!!customOrgName ? `organization-table-tooltip-${index}-${item.doctorReferral.userProfile.organization}${customOrgName}` : null} data-tip=''>
        {mainOrgName}
        </div>
      </>)},
    columnWidth: '11%',
    id: 4,
  },
  {
    header: 'Лаборатория',
    // @ts-ignore
    renderer: (item: Referral) => 'Лаборатория 1',
    // renderer: (item: Referral) => item.laboratoryName,
    columnWidth: '14%',
    id: 9,
  },
  {
    header: 'Тип',
    // @ts-ignore
    renderer: (item: Referral) => REFERRAL_TYPE[item.type],
    columnWidth: '14%',
    id: 6,
  },
  {
    header: 'Статус',
    renderer: (item: Referral) => {
      let allExaminationDone = item?.examinationReferral?.status === 'Complete' && item?.status === 'analysis_pending'
      const tooltipMessage = getTooltipMessage(item.status, item.statusCancellation, allExaminationDone);
      let status:string = 'bio_pending';
      if(allExaminationDone) status = 'conclusion_pending'
      else status = item.status.toString();
      
      return (
      <>
        <CustomTooltip id={`status-table-tooltip-${item.referralId}`} place='top'>
          {tooltipMessage}
        </CustomTooltip>
        <TStatusWr data-for={`status-table-tooltip-${item.referralId}`} data-tip=''>
          {item?.statusCancellation ? <TIndicator/> : <TIndicator status={status}/>}
          <TStatusTitle>{item.statusCancellation ? STATUS['referral_canceled'] : STATUS[status]}</TStatusTitle>
        </TStatusWr>
      </>
      )
    },
    columnWidth: '19%',
    id: 7,
  },
  // {
  //   header: 'Приоритет',
  //   renderer: (item: Referral) =>
  //     <TPriorityField isCommon={item.speedType === Speed.Common}>
  //       <span>{SPEED[item.speedType]}</span>
  //       </TPriorityField>,
  //   columnWidth: '7%',
  //   id: 8,
  // },
]);
