import {Examination} from "../../../../store/referral/model";
import {TIndicator, TStatusTitle, TStatusWr} from "../../../ReferralPage/styled";

export const statusLocale: { [index: string]: any; } = {
  'New':          {rus:'Новое',     code:'New'},
  'Complete':     {rus:'Завершено', code:'COMPLETE'},
  'Cancelled':    {rus:'Отменено',  code:'CANCELLED'},
  'Cancel':       {rus:'Отменено',  code:'CANCELLED'},
  'In Progress':  {rus:'В процессе',code:'IN_PROGRESS'},
}

const methods: { [index: number]: string; } = {
  1: 'ИГХ',
  2: 'FISH',
  3: 'ПЦР',
  4: 'Секвениров-е по Сэнгеру',
  5: 'Фрагментный анализ',
  6: '???'
}


export const examinationTableColumns = () => (

  [{
    header: 'Номер',
    renderer: (item: Examination) => <>{`100000/2024МИ-${item.id}`}</>,
    columnWidth: '25%',
    id: 1,
  },
    {
      header: 'Маркер',
      renderer: (item: Examination) => {
        let resultString;
        if (item?.marker.toString().includes('/')) {
          resultString = item?.marker.toString().split('/')
          return `${resultString[0]}/ ${resultString[1]}`
        }

        return item?.marker ? item?.marker : '-'},
      columnWidth: '30%',
      id: 2,
    },
    // {
    //   header: 'Биоматериал',
    //   renderer: (item: any) => {
    //     if(!item?.bio?.length) return '--';
    //     let priority = item?.bio[0]?.material?.priority || item?.bio[1]?.material?.priority;
    //     if(priority && item?.bio?.length === 1) return `Блок К${priority}`;
    //     if(priority && item?.bio?.length > 1)   return `Блок К${priority} + кровь`;
    //     if(!priority && item?.bio?.length) return 'Кровь'
    //     return '--';
    //   },
    //   columnWidth: '25%',
    //   id: 3,
    // },
    {
      header: 'Метод',
      renderer: (item: Examination) => item.method ? methods[+item.method] : '-',
      columnWidth: '30%',
      id: 4,
    },
    {
      header: 'Статус',
      columnWidth: '20%',
      id: 5,
      renderer: (item: Examination) => (
        // @ts-ignore
        <>
          <TStatusWr>
            <TIndicator margin={8} status={statusLocale[item.status].code}/>
            <TStatusTitle isSmallStatus>{statusLocale[item.status].rus ?? '--'}</TStatusTitle>
          </TStatusWr>
        </>
      ),
    }
  ]);
