import { FC } from 'react';
import {TDiv, TInfoTabsWrapper, TSpan, TSubTitle, Divider} from './styled';
import PatientData from '../../../containers/MolecularProfilingDetailsPage/PatientData';
import ReferralData from './ReferallData';
import ExaminationDataTable from "../../../containers/MolecularProfilingDetailsPage/ExaminationData";


interface IInfoTabs {
  title?: string;
  borderRadiusTopRight?: number;
  borderRadiusBottom?:number;
  patient?:any;
  referral?:any[];
  country?: string;
  newAmbulatoryCart?: any[];
  subTitle?: string;
  examination?: any;
  hasPermit?: boolean;
}

const InfoTabs:FC<IInfoTabs> = ({title, borderRadiusBottom, borderRadiusTopRight, patient, referral, country, newAmbulatoryCart, subTitle, examination,hasPermit}:IInfoTabs) => {
  return (
    <TInfoTabsWrapper title = {title} >
      <TSpan>{title}<Divider />{ subTitle && <TSubTitle>{subTitle}</TSubTitle>}</TSpan>
      <TDiv borderRadiusTopRight={borderRadiusTopRight} borderRadiusBottom={borderRadiusBottom}>
        {patient && <PatientData data={patient} country={country} patientAmbulatoryCard={newAmbulatoryCart}/>}
        {referral && <ReferralData patientReferrals={referral} hasPermit={hasPermit}/>}
        {examination && <ExaminationDataTable examination={examination} hasPermit={hasPermit}/>}
      </TDiv>
    </TInfoTabsWrapper>
  )
}

export default InfoTabs;
