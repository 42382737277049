import styled from 'styled-components';

export const TPatientData = styled.div`
  min-width: 492px;
  padding: 16px 0 10px 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const TBlock = styled.div`
  width: 50%;
  min-height: 50px;
  z-index: 1;
`

export const TLabel = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #96999C;
  user-select: none;
  z-index: 1;
`

export const TData = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1A1A22;
  padding-top: 4px;
  z-index: 2;
`