import { Patient } from '../patient/model';
import { Marker } from '../markers/model';
import { Nullable } from '../../types';
import { Commentary } from '../commentary/model'

export enum Payment {
  FREE = 'Free',
  PAID = 'Paid',
}

export enum Speed {
  Common = 'Common',
  Urgent = 'Urgent'
}

export enum Status {
  BIO_PENDING = 'bio_pending',
  MORPHOLOGY_PENDING = 'morphology_pending',
  MARKERS_VALIDATION_PENDING = 'markers_validation_pending',
  ANALYSIS_PENDING = 'analysis_pending',
  CONCLUSION_PENDING = 'conclusion_pending',
  REFERRAL_PENDING = 'referral_pending',
  REFERRAL_COMPLETED = 'referral_completed',
}

export interface Filter {
  currentPage?: number;
  nextPage?: Nullable<number>;
  searchQuery?: string;
  pageSize?: number;
  tab?: string;
  ulid?: string;
  dateCreatedGte?: Nullable<string>;
  dateCreatedLte?: Nullable<string>;
  status?: string;
  priority?: string;
  type?: string;
  organization?: string;
  cancelled?: Nullable<string>
  bio?: string;
  method?: string;
  executor?: string;
  marker?: string;
  [key: string]: string | number | null | undefined;
}

export interface ReferralsData {
  count: number;
  pages: number;
  types: string[];
  data: Referral[];
  currentPage: string;
}

export interface BluprintAdvisor {
  blueprint: number
  advisory: string
  fullName: string
  specialization: string
}

export interface ExaminationReferral {
  id: number;
  referral: number;
  examination_blueprints: ExaminationBlueprint[];
  coordinator: string;
  status: string;
  date_created: string | Date;
}

export interface ExaminationBlueprint {
  id: number
  ulid: string
  examination: number
  marker: string
  bio: IBio[]
  method?: number
  executor: string
  status: string
  cancellation_reason: string
  blueprint_advisory?: BluprintAdvisor[]
}

//BLOCKS and SLIDES  
export interface IBioMaterial {
  ulid: string
  patient: string
  dateOfAcquisition: string
  organization: number
  source: string
  type: string
  material: IBlockMaterial
}

export interface IBlockMaterial {
  organ?: string
  blockCypher?: BlockCypher[]
  slides?: Slide[]
  bloodId?: number
  organization?: string
}

export interface BlockCypher {
  organization: string
  startCode: string
  endCode: string
  amount: string
  year: number
  code: string
}

export interface Slide {
  ulid: string
  patient: string
  dateOfAcquisition: string
  source: string
  type: string
  material: ISlideMaterial
}

export interface ISlideMaterial {
  organ: string
  slideCypher: SlideCypher[]
}

export interface SlideCypher {
  organization: string
  startCode: string
  endCode: string
  amount: string
  year: number
  code: string
}


// TODO add models
export interface Referral {
  type: string;
  patient: Patient;
  paymentType: Payment;
  speedType: Speed;
  status: Status;
  statusCancellation: boolean;
  cancellationReason?:string;
  examinationReferral?:ExaminationReferral;
  cancelOption?: boolean;
  diagnosis: any;
  doctorReferral: any;
  bioMaterial: IBioMaterial;
  markers: Marker[];
  commentary: Commentary[];
  referralId?: string;
  dateCreated?: string | Date;
  dateOfAcquisition?: string | Date;
  laboratory_name?: string;
  ulid?: string;
}

interface IExamination {
  dateCreated: string | Date;
  referral: Referral;
}

interface IMethod {
  description: string;
  id: number;
  name: string;
  nameRus: string;
  ulid: string;
}

interface IBloodMaterial {
  bloodId: number;
  organization: string;
}


export interface IBio {
  ulid: string;
  type: string;
  patient: string;
  organization: string;
  source: string;
  dateOfAcquisition: string | Date;
  priority: number;
  startCode: number;
  endCode: number;
  year: number;
  material?:IBloodMaterial;
}

export interface Examination {
  dateCreated: string | Date;
  bio: IBio[] | null;
  examination: IExamination;
  executor: string;
  id: number | string;
  method: IMethod | null;
  marker: number;
  status: string;
  ulid: string;
  cancellationReason?: string;
}
