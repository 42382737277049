import { FC } from "react";
import { ISubBlock } from "../../../store/molecularProfiling/model";
import { CustomInput, customStylesOptions, TMenuButton, TTableRowWR, TTooltip } from "./styled";
import tableMenuIconActive from '../../../icons/table-menu-icon-active.svg';
import { Control, Controller, UseFormRegister, useWatch } from "react-hook-form";
// import { validations } from "../../../utils/validations";
import Select from "react-select";
import defaultTheme from "../../../styles/theme";

type IComponentProps = {
    index: number;
    subBlock: ISubBlock;
    isDisabled: boolean;
    control: Control<FormValues>;
    register: UseFormRegister<FormValues>;
    [index: string]: any;
}

type FormValues = {
    description: string;
    implementer: string;
    tableFields: ISubBlock[];
};

type IOptions = {
    value: string | number;
    label: string;
    disabled: boolean;
}

const optionsQuality = [
    { value: 'good', label: 'удовлетворительно' },
    { value: 'bad', label: 'неудовлетворительно' },
];

const defaultOptionsPriority:IOptions[] = [
    { value: 0, label: '--',disabled: true },
    { value: 1, label: '1', disabled: false},
    { value: 2, label: '2', disabled: false},
    { value: 3, label: '3', disabled: false},
];

const MorphTableRow: FC<IComponentProps> = ({ index, subBlock, isDisabled, control,errors }) => {
    const output = useWatch({
        name: "tableFields",
        control
    });

    return <tr>
        <td>
            <TTableRowWR>
                {subBlock.cypher}
                <TTooltip place='top' id={subBlock.cypher} />
                <TMenuButton
                    src={tableMenuIconActive}
                    data-for={subBlock.cypher}
                    data-tip={`Относится к оригинальному блоку ${subBlock?.originalBlockCode ?? ''}`}
                />
            </TTableRowWR>
        </td>
        <td
            className={!!errors?.tableFields?.[index]?.cancerousCellPercentage ? 'error' : ''}>
            <Controller
                control={control}
                name={`tableFields.${index}.cancerousCellPercentage` as const}
                key={`cancerousCellPercentage_${index}_${subBlock?.cancerousCellPercentage}`}
                rules={{ required: true, pattern: /^[0-9]*?[0-9]*$/,validate: {
                        positive: (v:any) => v !== null && parseInt(v) >= 0,
                        lessThanHundred: (v:any) => v !== null && parseInt(v) <= 100,
                    } }}
                render={({
                             field: { onChange, value, ref },
                             fieldState: { invalid, error } }) => {
                    return (
                        <CustomInput
                            type={'number'}
                            onChange={({target}) => {
                                if(target?.value !== '' && Number.isInteger(+target?.value)){
                                    return onChange(+(parseInt(target?.value)))
                                };
                                onChange(null);
                            }}
                            min={0}
                            max={100}
                            step={1}
                            // defaultValue={subBlock?.cancerousCellPercentage ?? ''}
                            placeholder='--'
                            disabled={isDisabled}
                            // pattern="/^[0-9]*?[0-9]*$/"
                        />
                    )}}
            />
        </td>
        <td
            className={!!errors?.tableFields?.[index]?.cancerousCellPercentage ? 'error' : ''}>
            <Controller
                control={control}
                name={`tableFields.${index}.cancerousCellPercentage` as const}
                key={`cancerousCellPercentage_${index}_${subBlock?.cancerousCellPercentage}`}
                rules={{ required: true, pattern: /^[0-9]*?[0-9]*$/,validate: {
                        positive: (v:any) => v !== null && parseInt(v) >= 0,
                        lessThanHundred: (v:any) => v !== null && parseInt(v) <= 100,
                    } }}
                render={({
                             field: { onChange, value, ref },
                             fieldState: { invalid, error } }) => {
                    return (
                        <CustomInput
                            type={'number'}
                            onChange={({target}) => {
                                if(target?.value !== '' && Number.isInteger(+target?.value)){
                                    return onChange(+(parseInt(target?.value)))
                                };
                                onChange(null);
                            }}
                            min={0}
                            max={100}
                            step={1}
                            defaultValue={subBlock?.cancerousCellPercentage ?? ''}
                            placeholder='--'
                            disabled={isDisabled}
                            pattern="/^[0-9]*?[0-9]*$/"
                        />
                    )}}
            />
        </td>
        <td className={!!errors?.tableFields?.[index]?.priority ? 'error' : ''}>
            <Controller
                key={output[index].priority}
                control={control}
                name={`tableFields.${index}.priority` as const}
                rules={{ required: !['none', 'bad'].includes(output[index].quality) }}
                render={({
                             field: { onChange, value, ref },
                             fieldState: { invalid } }) => {
                    let isCellPercentage = output?.[index]?.cancerousCellPercentage ?? null;
                    let lockedOptions = output.map((value: ISubBlock) => value.priority);
                    return (
                        <Select
                            onChange={(e) => onChange(e?.value)}
                            selected={value}
                            // defaultValue={defaultOptionsPriority[output[index].priority]}
                            options={defaultOptionsPriority.filter((option: IOptions) => option.value && !lockedOptions.includes(+option.value))}
                            classNamePrefix='selectQuality'
                            placeholder={'--'}
                            styles={customStylesOptions(defaultTheme)}
                            isOptionDisabled={(option: any) => option.disabled}
                            inputRef={ref}
                            error={invalid || !!errors?.tableFields?.[index]?.priority}
                            isDisabled={
                                ['none', 'bad'].includes(output[index].quality) ||
                                isCellPercentage === null || !(isCellPercentage >= 0) ||
                                isDisabled
                            }
                        />
                    )}}
            />
        </td>
        <td className={!!errors?.tableFields?.[index]?.priority ? 'error' : ''}>
            <Controller
                key={output[index].priority}
                control={control}
                name={`tableFields.${index}.priority` as const}
                rules={{ required: !['none', 'bad'].includes(output[index].quality) }}
                render={({
                             field: { onChange, value, ref },
                             fieldState: { invalid } }) => {
                    let isCellPercentage = output?.[index]?.cancerousCellPercentage ?? null;
                    let lockedOptions = output.map((value: ISubBlock) => value.priority);
                    return (
                        <Select
                            onChange={(e) => onChange(e?.value)}
                            selected={value}
                            // defaultValue={defaultOptionsPriority[output[index].priority]}
                            options={defaultOptionsPriority.filter((option: IOptions) => option.value && !lockedOptions.includes(+option.value))}
                            classNamePrefix='selectQuality'
                            placeholder={'--'}
                            styles={customStylesOptions(defaultTheme)}
                            isOptionDisabled={(option: any) => option.disabled}
                            inputRef={ref}
                            error={invalid || !!errors?.tableFields?.[index]?.priority}
                            isDisabled={
                                ['none', 'bad'].includes(output[index].quality) ||
                                isCellPercentage === null || !(isCellPercentage >= 0) ||
                                isDisabled
                            }
                        />
                    )}}
            />
        </td>
        <td className={!!errors?.tableFields?.[index]?.priority ? 'error' : ''}>
            <Controller
                key={output[index].priority}
                control={control}
                name={`tableFields.${index}.priority` as const}
                rules={{ required: !['none', 'bad'].includes(output[index].quality) }}
                render={({
                             field: { onChange, value, ref },
                             fieldState: { invalid } }) => {
                    let isCellPercentage = output?.[index]?.cancerousCellPercentage ?? null;
                    let lockedOptions = output.map((value: ISubBlock) => value.priority);
                    return (
                        <Select
                            onChange={(e) => onChange(e?.value)}
                            selected={value}
                            defaultValue={defaultOptionsPriority[output[index].priority]}
                            options={defaultOptionsPriority.filter((option: IOptions) => option.value && !lockedOptions.includes(+option.value))}
                            classNamePrefix='selectQuality'
                            placeholder={'--'}
                            styles={customStylesOptions(defaultTheme)}
                            isOptionDisabled={(option: any) => option.disabled}
                            inputRef={ref}
                            error={invalid || !!errors?.tableFields?.[index]?.priority}
                            isDisabled={
                                ['none', 'bad'].includes(output[index].quality) ||
                                isCellPercentage === null || !(isCellPercentage >= 0) ||
                                isDisabled
                            }
                        />
                    )}}
            />
        </td>
    </tr>
}

export default MorphTableRow;
