import styled from 'styled-components';

export const TDetailsFormWr = styled.div`
  padding-left: 12px;
`;

export const TDetailsItemWr = styled.div`
  position: relative;
  
  details[open]  {
    margin-bottom: 24px;
    .arrow{
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
  }
`;

export const TDetails = styled.details`
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: 10px;
  margin-bottom: 4px;
  summary { 
    cursor: pointer;
  }
  & > summary {
    margin-bottom: 4px;
  }
  & :last-child {
    margin-bottom: 0;
  }
`;

export const TDetailsContent = styled.div`
  position: relative;
  background-color:${({ theme }) => theme.colors.white};
  height: max-content;
  border-radius: 10px;
  padding: 30px 21px;
  margin-bottom: 6px;
`;

export const TSummary = styled.summary`
  display:flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  color:${({ theme }) => theme.colors.black[200]};
  background-color:${({ theme }) => theme.colors.white};
  list-style: none;
  height: 60px;
  border-radius: 10px;
  span {
    padding-top: 6px;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const TSummaryContent = styled.span<{isSelected:boolean}>`
  box-sizing: border-box;
  color:${({ theme }) => theme.colors.black[200]};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding-left: ${({ isSelected }) => isSelected ? '0px' : '40px'};
  
`;

export const TSummaryIcon = styled.img`
  display: inline-block;
  width: 30px;
  height: 30px;
  padding-right: 40px;
  &.arrow {
    padding:0;
    width: 12px;
    height: 12px;
    margin-left: 11px;
  }
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })<{disabled?: boolean}>`
  transform: scale(1.6);
  -ms-transform: scale(1.6); /* IE */
  -moz-transform: scale(1.6); /* FF */
  -webkit-transform: scale(1.6); /* Safari and Chrome */
  -o-transform: scale(1.6); /* Opera */
  transform: scale(1.6);
  padding: 10px;
  margin:0 24px ;
  accent-color: #7A78E9;
  cursor:${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`;

export const TCheckbox = styled(Checkbox)`

`;

export const TSelectAll = styled.label`
  display:block;
  width: max-content;
  margin-bottom:20px;
  color:${({ theme }) => theme.colors.gray[200]};
  cursor:pointer;
`