import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getPermissions, getProfile, getTokens } from "../../../store/auth/selectors";
import { getHighPriorityMarkers, getLowPriorityMarkers } from "../../../store/markers/selectors";
import { fetchMarkers } from "../../../store/markers/thunkActions";
import MarkerBlock from "../../CreateReferralPage/steps/MarkersSelection/MarkerBlock";

import {
  MessageWrapper,
  TBtnWr,
  TButton,
  TDateOfCompletion,
  TDoctorChanges,
  TEditButton,
  TFinalMarkersList,
  TFormWrapper,
  THeader,
  TIcon,
  TMarkerChanges,
  TMarkersTitle,
  TMessage,
  TRowWr,
  TVisibilityToggle,
  TWrapper
} from "./styled";
import arrowIcon from '../../../icons/arrow-select.svg';
import {ReactComponent as WarmIcon}  from '../../../icons/Activeinfo.svg'
import InfoModal from "../../../components/shared/InfoModal";
import Button, { SIZE, VARIANT } from "../../../components/shared/Button";
import { resetMarkers } from "../../../store/markers";
import { format } from "date-fns";
import { getFromSessionStorage, removeItemSessionStorage, setToSessionStorage } from "../../../utils/sessionStorageHelpers";
import { useLocation } from "react-router";
import { createNextStep, patchMorphMarkersDataConfirm, postExaminationCreate, postUpdateReferralMarkerList } from "../../../store/molecularProfiling/thunkActions";
import { FLOW_STEPS_STATUSES } from "../../../fixtures/StepsMolecularProfilingPage";
import { getMolProMarkerUpdatedList } from "../../../store/molecularProfiling/selectors";
import { resetReferralMarkerList } from "../../../store/molecularProfiling";
import { getServiceUsers } from "../../../store/serviceUsers/selectors";
import { fetchServiceUsers } from "../../../store/serviceUsers/thunkActions";
import { IBiologicalMaterial, IStepReferral } from "../../../store/molecularProfiling/model";
import { resetResearchSearchData } from "../../../store/research";
import { fetchResearch } from "../../../store/research/thunkActions";

interface IComponentProps {
  data?:any;
  [index: string]: any;
}

interface IMorfologActions {
  markerId : number[];
  markerName: string;
  personName: string;
  personAction: string;
  dateOfChange: string;
}



const MarkersValidation: FC<IComponentProps> = (data) => {
  const dispatch  = useDispatch();
  const tokens    = useSelector(getTokens);
  const path      = useLocation();

  const highPriorityMarkers = useSelector(getHighPriorityMarkers);
  const lowPriorityMarkers  = useSelector(getLowPriorityMarkers);
  const updatedMarkersListFromServer = useSelector(getMolProMarkerUpdatedList);

  console.log("highPriorityMarkers --- :", highPriorityMarkers); 
  
  const [isEditPageActive, setEditPageActive] = useState(false);  //trigger edit button
  const [allDataSaved, setAllDataSaved]       = useState(false);  //all changes has been saved
  const [isChangesSaved, setChangesSaved]     = useState(false);  //changes has been made to the table
  const [disableValidate, setDisableValidate] = useState(false);   //nothing checked
  const [visible, setVisible]                 = useState(false);  //is visible lowPriorityMarkers
  
  //const [checkedIds, setCheckedIds]           = useState<number[][]>([]);   //checked numbers id
  const [checkedMarkers, setCheckedMarkers]   = useState<number[]>([]);
  const [validateMarkersActiv, setValidateMarkersActiv] = useState(false);
  const [isBloodChecked, setBloodChecked] = useState<boolean>(false); //checked blood on referral create 
  
  const [checkedByMorfolog,   setCheckedByMorfolog]     = useState<IMorfologActions[]>([]);
  const [uncheckedByMorfolog, setUncheckedByMorfolog]   = useState<IMorfologActions[]>([]);
  const [referralMarkersId, setReferralMarkersId]       = useState<number[]>([]);
  const [referralMarkersNames, setReferralMarkersNames] = useState<{[index: string]: any;}>({});
  const [morphologName, setMorphologName] = useState('');
  
  const referralULD = useMemo(()=> ((path.pathname).split('/')).slice(-2)[0] ,[path]);
  
  const currentDate = useMemo(()=> format(new Date(), 'dd.MM.yyyy'),[]);
  
  const confirmedMarkers = useMemo(()=> FLOW_STEPS_STATUSES[data.status] >= 4,[data?.status]);
  
  //Ger user profile
  const profile = useSelector(getProfile);
  const permissions = useSelector(getPermissions);
  const doctorsList = useSelector(getServiceUsers);

  const isGeneticistCoordinator = useMemo(() => !!permissions?.geneticistCoordinator || !!permissions?.isStaff,[permissions]);

  useEffect(()=>{
    if(!profile || !data?.status) return;
    
    if(!confirmedMarkers){
      let {firstName = '', lastName = '',middleName = ''} = profile;
      setMorphologName(`${lastName} ${firstName} ${middleName}`.trim());
    }
    if(confirmedMarkers && data?.examinationReferral && doctorsList.length){
      
      let coordinatorId = data?.examinationReferral?.coordinator;
      let morphologName = doctorsList.find((item)=> item.ulid === coordinatorId)?.userProfile;

      if(!morphologName ) return;

      let {firstName = '', lastName = '',middleName = ''} = morphologName;
      
      setMorphologName(`${lastName} ${firstName} ${middleName}`.trim());
    }

  },[profile,doctorsList?.length,confirmedMarkers,data,doctorsList,setMorphologName]);

  //delete old data and load markers by referral localization
  const resetAndReloadMarkers = useCallback(()=>{
    if(tokens?.access && data?.diagnosis?.localization){
      dispatch(resetMarkers());
      dispatch(fetchMarkers(tokens?.access, data?.diagnosis?.localization));
    }
  },[dispatch,tokens,data]);

  //set values to avoid multiple rendering
  const setSavedData = useCallback((data:any)=>{
    setCheckedMarkers(data.checkedMarkers);
    setCheckedByMorfolog(data.checkedByMorfolog);
    setUncheckedByMorfolog(data.uncheckedByMorfolog);
    setAllDataSaved(true);
    setChangesSaved(true);
    setDisableValidate(!data?.checkedMarkers?.length);

  },[setCheckedMarkers,setAllDataSaved,setChangesSaved,
    setCheckedByMorfolog,setUncheckedByMorfolog,setDisableValidate]);

  //get saved data
  const loadChanges = useCallback(async() => {
    let savedData:any;
    //load saved data
    let savedRawData = getFromSessionStorage('CHANGES_NOT_SAVED_MarkersData')?.[0] ?? false;
    //find saved changes by ulid
    if(savedRawData){
      let parsedRawData = JSON.parse(savedRawData);
      savedData = parsedRawData.find((savedItem:any) => savedItem.ulid === referralULD);
    }
    if(!!savedData && savedData !== -1) setSavedData(savedData);
  },[referralULD,setSavedData]);

  //load doctors list if this stage is complite
  useLayoutEffect(()=>{
    if(!data?.status) return;
    let isBloodOnReferralCreate = !!(data?.biologicalMaterial ?? []).filter((bio:IBiologicalMaterial) => bio.type === 'Blood')?.length
    setBloodChecked(isBloodOnReferralCreate);

    if(FLOW_STEPS_STATUSES[data.status] >= 4 && tokens?.access){
      dispatch(fetchServiceUsers(tokens?.access,{organizationId:0}))
    }
    if(FLOW_STEPS_STATUSES[data.status] === 3 && referralMarkersId) loadChanges()
  },[data,dispatch,tokens,loadChanges, referralMarkersId,setBloodChecked])

  //get markers by loalization at once
  useLayoutEffect(()=>{
    resetAndReloadMarkers();
  },[data?.diagnosis?.localization,resetAndReloadMarkers]);


  //set referral markers and markers names
  useEffect(()=>{
    if(!data?.status) return;

    if(!data?.referralMarkers?.length) return;
    
    const mrkersId:number[]  = [];
    const markersNames:{[index: string]: any;} = {};
    const markerStatuseActions:{[index: string]: any;} = {
      'Убран координатором':'удалил',
      'Утвержден координатором': 'утвердил',
      'Выбран генетиком координатором': 'добавил'
    };

    console.log('highPriorityMarkers', highPriorityMarkers)
    console.log('lowPriorityMarkers', lowPriorityMarkers)
    console.log('checkedMarkers', checkedMarkers)
    console.log('confirmedMarkers', confirmedMarkers)
    console.log('referralMarkers', data.referralMarkers)
    

    //maps for marker history
    const removedMarkerByCoordinator:{ [index: string]: any;}  = {};
    const approvedMarkerByCoordinator:{[index: string]: any;}  = {};
    const selectedMarkerByCoordinator:{[index: string]: any;}  = {};

    console.log('data.referralMarkers', data.referralMarkers)
    
    for (let refMarker of data.referralMarkers){

      let id = refMarker.markerPriority.id;
      let marker = refMarker.markerPriority.marker.name;
      let markerHistoryObject = {
        markerId:   id,
        markerName: marker,
        personAction: markerStatuseActions[refMarker.status],
        personName: morphologName || 'Координатор',
        dateOfChange: format(new Date(refMarker.dateUpdated), 'dd.MM.yyyy')
      };

      if(confirmedMarkers && refMarker.status === 'Убран координатором'){
        removedMarkerByCoordinator[marker] = markerHistoryObject;
        continue;
      }

      if(confirmedMarkers && refMarker.status === 'Утвержден координатором'){
        approvedMarkerByCoordinator[marker] = markerHistoryObject;
      }

      if(confirmedMarkers && refMarker.status === 'Выбран генетиком координатором'){
        selectedMarkerByCoordinator[marker] = markerHistoryObject;
      }

      mrkersId.push(id);
      if(!markersNames[marker]) markersNames[marker] = [id];
      if(markersNames[marker]){
        let ids = markersNames[marker];
        // @ts-ignore
        markersNames[marker] = [...new Set([...ids,id])];
      }
    };


    let allReferralMarkersId = mrkersId.flat();

    //check markers referral by default
    setReferralMarkersId(allReferralMarkersId);
    setCheckedMarkers(allReferralMarkersId);
    setReferralMarkersNames(markersNames);

    //add history after complite marker validation stage
    if(confirmedMarkers){
      setUncheckedByMorfolog(Object.values(removedMarkerByCoordinator))
      setCheckedByMorfolog([
        ...Object.values(approvedMarkerByCoordinator),
        ...Object.values(selectedMarkerByCoordinator)
      ])
    };

  },[data,confirmedMarkers,morphologName]);

  //markers with low priority were selected
  const isLowPriorityMarkersCheckedByReferral = useMemo(()=>{
    if(!lowPriorityMarkers.length && !checkedMarkers.length ) return false;
    
    return lowPriorityMarkers.flat().some((item:any) => checkedMarkers.includes(item.id));
  },[checkedMarkers,lowPriorityMarkers]);

  useEffect(() => {
    if(!checkedMarkers.length) setDisableValidate(true);
    if((!isChangesSaved || !allDataSaved) && !!(checkedByMorfolog?.length || uncheckedByMorfolog?.length)){
      setDisableValidate(true);
    }
    if((!isChangesSaved || !allDataSaved) && !(checkedByMorfolog?.length || uncheckedByMorfolog?.length)){
      setDisableValidate(false);
    }
    if((isChangesSaved || allDataSaved) && !!(checkedByMorfolog?.length || uncheckedByMorfolog?.length) ){
      setDisableValidate(false);
    }

  },[checkedByMorfolog,uncheckedByMorfolog,allDataSaved,isChangesSaved,setDisableValidate,checkedMarkers.length]);

  //markers chosen by the morphologist
  const editedMarkers = useCallback((markerName, checkedStatus, blockIndex): void =>{

    const markerDataByBlockIndex = highPriorityMarkers.concat(lowPriorityMarkers)[blockIndex];

    let markerAction:{[index: string]: string;} = { CHECKED: 'добавил', UNCHECKED: 'удалил'};

    let markerIDByBlockIndex:{[index: string]: any;} = {};
    
    let markerIds:number[] = [];
    
    markerDataByBlockIndex.forEach((markerData:any)=>{
      let markerDataName = markerData.marker.name;
      if(markerName.includes(markerDataName)){
        let markerIDs = markerIDByBlockIndex[markerDataName]
        markerIds.push(markerData.id);
        if(!markerIDs) return markerIDByBlockIndex[markerDataName] = [markerData.id];
        return markerIDByBlockIndex[markerDataName] = [...markerIDs,markerData.id];
      }
    });

    let isReferralMarkerChecked  = markerName.some((name:string) => {
      if(!referralMarkersNames[name]) return false;
      return referralMarkersNames[name].every((id:number) => markerIDByBlockIndex[name].includes(id));

    });

    let morfologActionLog = markerName.map((name:string)=>{
      let idForMarker = markerIDByBlockIndex[name];
      return  {
        markerId:     idForMarker,
        markerName:   name,
        personName:   morphologName,
        personAction: markerAction[checkedStatus],
        dateOfChange: currentDate
      }
    })

    //set save data button active
    if(checkedStatus){
      setAllDataSaved(false);
      setChangesSaved(false);
    }

    if(checkedStatus === 'CHECKED'){
      if(isReferralMarkerChecked){
        let checkedRefMarkerLog:IMorfologActions[] = [];
        let uncheckedIds = uncheckedByMorfolog;
        for(let logItem of morfologActionLog){
          let ids = referralMarkersNames?.[logItem.markerName];
          if(!ids){
            checkedRefMarkerLog.push(logItem);
          }else{
           uncheckedIds = uncheckedIds.filter((uncheckItem:IMorfologActions)=> uncheckItem.markerId.toString() !== ids.toString() ?? '')
          }
        }

        setUncheckedByMorfolog(uncheckedIds);
        setCheckedByMorfolog([...checkedByMorfolog, ...checkedRefMarkerLog]);
        // @ts-ignore
        setCheckedMarkers([...new Set([...checkedMarkers,...markerIds])]);
        return;
      }

      setCheckedByMorfolog([...checkedByMorfolog, ...morfologActionLog]);
      // @ts-ignore
      setCheckedMarkers([...new Set([...checkedMarkers,...markerIds])]);
    };

    if(checkedStatus === 'UNCHECKED'){
      if(isReferralMarkerChecked){
        let checkedIds = checkedMarkers;
        let uncheckedRefMarkerLog:IMorfologActions[] = [];
        let checkedRefMarkerLog:IMorfologActions[] = checkedByMorfolog;

        for(let logItem of morfologActionLog){
          let ids = referralMarkersNames?.[logItem.markerName];
          if(!ids){
            checkedIds = checkedIds.filter((id:number)=> !logItem.markerId.includes(id));
            checkedRefMarkerLog = checkedRefMarkerLog.filter((value: IMorfologActions) => {
              return !markerName.includes(value.markerName)
            })
          }else{
            checkedIds = checkedIds.filter((id:number)=> !logItem.markerId.includes(id));
            uncheckedRefMarkerLog.push(logItem);
          }
        }

        // @ts-ignore
        setCheckedMarkers([...new Set([...checkedIds])]);
        // @ts-ignore
        setUncheckedByMorfolog([...new Set([...uncheckedByMorfolog, ...uncheckedRefMarkerLog])]);
        // @ts-ignore
        setCheckedByMorfolog([...new Set([...checkedRefMarkerLog])]);
        return;
      }

      let removedMarkers = checkedByMorfolog.filter((removedMarker:IMorfologActions) => {
        let removedArr = markerIDByBlockIndex?.[removedMarker.markerName]?.toString() ?? '';
        return removedMarker.markerId.toString() !== removedArr;
      })

      setCheckedMarkers((prevState)=> prevState.filter((markerId:number)=> !markerIds.includes(markerId)));
      setCheckedByMorfolog(removedMarkers);
    };

  },[morphologName,       setChangesSaved,
    highPriorityMarkers,  lowPriorityMarkers,
    checkedByMorfolog,    setCheckedByMorfolog,
    uncheckedByMorfolog,  setUncheckedByMorfolog,
    checkedMarkers,       setCheckedMarkers,
    referralMarkersNames, currentDate]);

  //is it possible to edit this tab
  const isEditDisabled = useMemo(() => {
    if(!data) return true;
    let isRefferalCanceled  = data?.statusCancellation;
    let isMorphologyPending = FLOW_STEPS_STATUSES[data.status] !== 3;
    
    return isRefferalCanceled || isMorphologyPending;
  },[data]);


  //save changes to session storage
  const handleSaveChanges = useCallback(() => {
    let dataForSave;

    //load saved data
    let savedRawData = getFromSessionStorage('CHANGES_NOT_SAVED_MarkersData')?.[0] ?? false;

    //if nothing saved - save state
    if(!savedRawData) dataForSave = [{checkedMarkers,checkedByMorfolog,uncheckedByMorfolog,ulid:referralULD}];

    if(savedRawData.length){
      //find saved changes by ulid
      let parsedRawData = JSON.parse(savedRawData)
      let savedDataIndex = parsedRawData.findIndex((savedItem:any) => savedItem.ulid === referralULD);

      //replace data if it already exists for that referral
      if(savedDataIndex !== -1){
        parsedRawData[savedDataIndex] = {checkedMarkers,checkedByMorfolog,uncheckedByMorfolog,ulid:referralULD};
        dataForSave = parsedRawData
      }

      //merge data if some data already exists
      if(savedDataIndex === -1){
        dataForSave = parsedRawData.concat({checkedMarkers,checkedByMorfolog,uncheckedByMorfolog,ulid:referralULD});
      }
    };

    setToSessionStorage(['CHANGES_NOT_SAVED_MarkersData',JSON.stringify(dataForSave)]);
    setTimeout(async () => await loadChanges(),500);

    setChangesSaved(true);
    setAllDataSaved(true);
    setEditPageActive(false);
  },[setChangesSaved,setAllDataSaved,
    checkedMarkers,checkedByMorfolog,
    uncheckedByMorfolog,referralULD,loadChanges]);


  //delete saved item from session storage
  const deleteSavedItem = useCallback(async () => {
    let savedRawData = await getFromSessionStorage('CHANGES_NOT_SAVED_MarkersData')?.[0];

    //close table editing if nothing has been saved at all
    if(!savedRawData) return setEditPageActive(false);

    //find saved changes by ulid
    let parsedRawData = JSON.parse(savedRawData)
    let savedDataIndex = parsedRawData.findIndex((savedItem:any) => savedItem.ulid === referralULD);

    //remove the current element from the setToSessionStorage
    if(savedDataIndex > -1 && parsedRawData.length > 1){
      parsedRawData.splice(savedDataIndex,1)
      await setToSessionStorage(['CHANGES_NOT_SAVED_MarkersData',JSON.stringify(parsedRawData)]);
    }

    //delete everything saved if it's the only element
    if(savedDataIndex > -1 && parsedRawData.length === 1){
      await removeItemSessionStorage('CHANGES_NOT_SAVED_MarkersData');
    }
  },[referralULD]);


  //handle edit tab button
  const handleEditPage = useCallback(async () => {
    if(isEditPageActive){
      //delete saved changes
      await deleteSavedItem();

      //clear state
      // setCheckedIds([]);
      setCheckedMarkers(referralMarkersId);
      setCheckedByMorfolog([]);
      setUncheckedByMorfolog([]);

      setAllDataSaved(false);
      setEditPageActive(false);
      setVisible(false);

      return resetAndReloadMarkers();
    }

    setEditPageActive(true);

  },[setCheckedMarkers,
    setCheckedByMorfolog, setUncheckedByMorfolog,
    referralMarkersId,    isEditPageActive,
    setEditPageActive,    setAllDataSaved,
    resetAndReloadMarkers,deleteSavedItem,
    setVisible]);


  const handleValidateMarkers = useCallback(async ()=>{
    setEditPageActive(false);
    //update referral marker lisd data
    const referral = +data.id;
  
    function createUpdateMarkerObj(markerArr:number[], status:string){
      return markerArr.map((markerPriority:number) => ({referral,markerPriority,status}));
    }

    let checkedByCoordinator:number[]   = checkedByMorfolog
      .flatMap((markerAction:IMorfologActions) => markerAction?.markerId);

    let uncheckedByCoordinator:number[] = uncheckedByMorfolog
      .flatMap((markerAction:IMorfologActions) => markerAction?.markerId);

    let confirmedByCoordinator:number[] = checkedMarkers
      .filter((markerId:number) => !checkedByCoordinator.includes(markerId));

    let dataForMarkerUpdate = [ 
      ...createUpdateMarkerObj(uncheckedByCoordinator,'Убран координатором'),
      ...createUpdateMarkerObj(confirmedByCoordinator,'Утвержден координатором'),
      ...createUpdateMarkerObj(checkedByCoordinator,  'Выбран генетиком координатором')
    ];


    if(tokens?.access){
      try {
        await dispatch(postUpdateReferralMarkerList(tokens.access,dataForMarkerUpdate));
        await dispatch(createNextStep(tokens.access, {referral:data.id,status:'markers_validation_done'}));
        await deleteSavedItem();
      } catch (error) {
        console.error(error);
      }

    }

  },[data,dispatch,tokens,
    setEditPageActive,checkedMarkers,
    deleteSavedItem,checkedByMorfolog,
    uncheckedByMorfolog]);

  const handleValidateMarkersConfirm = useCallback(()=>{
    if(!validateMarkersActiv) return setValidateMarkersActiv(true);
    setEditPageActive(false);
    setAllDataSaved(false);
    setChangesSaved(false);
    setValidateMarkersActiv(false);
    
  },[validateMarkersActiv,setEditPageActive,setAllDataSaved,setChangesSaved,setValidateMarkersActiv])

  function markersChangeHistory(data:IMorfologActions[]){
    if(!data) return null;
    return data.map((markerData:IMorfologActions) => (
      <li key={(markerData.markerId).toString()}>
        <b>{markerData.dateOfChange}</b>
        {`Пользователь ${markerData.personName} ${markerData.personAction} ${markerData.markerName} маркeр`}
      </li>));
  }

  const finalCheckMarkersList = useCallback(() => {
    const checkedMarkersCollection = [];
    const markerNamesMap:{[index: number]: string;} = {};
    const allMarkerData = highPriorityMarkers.concat(lowPriorityMarkers).flat();
    //collect data - {id:name}
    for (let markerObj of allMarkerData){
      markerNamesMap[markerObj.id] = markerObj.marker.name;
    }

    for (let markerId of checkedMarkers){
      checkedMarkersCollection.push(markerNamesMap[markerId]);
    }

    // @ts-ignore    
    return [...new Set(checkedMarkersCollection)];
  },[checkedMarkers,highPriorityMarkers,lowPriorityMarkers]);

  //Load updated markers
  useEffect(() => {
    if(updatedMarkersListFromServer?.length){
      //examination data
      const updatedMarkers  = updatedMarkersListFromServer
        .filter((marker:any)=> marker.status !== 'Убран координатором');

      const bluprintData = finalCheckMarkersList().map((marker:string) => ({ marker,bio:[],method:null}))

      const examinationData = {
        referral:data.id,
        status:'In Progress',
        examinationBlueprints:bluprintData,
        conclusion:''
      }

      if(tokens?.access && updatedMarkers.length){
        try {
          //send data to get template
          dispatch(postExaminationCreate(tokens.access,examinationData));
          //shange status
          dispatch(patchMorphMarkersDataConfirm(tokens.access, data?.ulid));
          //clear markers list
          dispatch(resetReferralMarkerList());
          //clear and update table
          if(tokens?.access) dispatch(fetchResearch(tokens?.access,{page: 1,pageSize: 10,tab: 'all'}))
          else dispatch(resetResearchSearchData());
        } catch (error) {
          console.error(error);
        }
      }
      handleValidateMarkersConfirm();
      handleEditPage();
      setEditPageActive(false);

    } 
  }, [data?.id,data?.ulid,
      tokens?.access, dispatch,
      handleValidateMarkersConfirm,handleEditPage,
      updatedMarkersListFromServer,setEditPageActive,
      finalCheckMarkersList
  ]);

  const doctorName = useMemo(()=>{
    if(FLOW_STEPS_STATUSES[data.status] === 3){
      const { firstName = '', lastName = '', middleName = '', title = '' } = profile ?? {};
      return <div className='implementer'><b>{`${lastName} ${firstName} ${middleName}`.trim()}</b>, {title}</div>;
    }

    return <></>;
  },[profile,data]);

  const [dateOfCompletion, doctorWhoCompleted] = useMemo(() => {

    if(!data?.stepReferral || !data?.stepReferral?.length) return ["",""];
    let bioComliteObj:IStepReferral = data.stepReferral.find((step:IStepReferral) => step.status === 'markers_validation_done');
    if(!bioComliteObj) return ["",""];

    let {dateSaved, userUlid} = bioComliteObj;

    //Completion date
    let dateCompletion = format(new Date(dateSaved), 'dd.MM.yyyy - HH:mm:ss');

    //Completion doctor
    let doctorWhoCompleted = <></>;
    let doctorName = doctorsList.find((item)=> item.ulid === userUlid)?.userProfile;

    if(doctorName ){
      let {firstName = '', lastName = '',middleName = '', title = ''} = doctorName;
      doctorWhoCompleted = <div className='implementer'><b>{`${lastName} ${firstName} ${middleName}`.trim()}</b>, {title}</div>
    };

    return [dateCompletion,doctorWhoCompleted];
  },[data,doctorsList]);

  return (
    <TWrapper>
        {!isEditDisabled && isGeneticistCoordinator && <MessageWrapper>
          <WarmIcon/>
          <TMessage>Приоритет направления можно изменить во вкладке "Данные направляющей стороны"</TMessage>
        </MessageWrapper>
        }
      {/* <TRowWr direction="space-between">
        <TDateOfCompletion>{!!dateOfCompletion && `Дата завершения: ${dateOfCompletion}` }</TDateOfCompletion>
        {data?.ableToUdateREFERRAL?.updateMarkerValidation && !dateOfCompletion ? (
          <TEditButton 
            disabled={isEditDisabled}
            onClick={handleEditPage}>
              {!isEditPageActive ? 'Редактировать' : 'Отменить'}
          </TEditButton>
        ) : <div></div>}
      </TRowWr> */}
      <TFormWrapper column={true}>
        {/* {!!lowPriorityMarkers.length && <TMarkersTitle calorBalack={true}>Рекомендуемые маркеры</TMarkersTitle> } */}
        <THeader>
          <p>Маркеры</p>
          <p>Лекарственные средства</p>
          {/* <p>{highPriorityMarkers}</p> */}
        </THeader>
        {
          [...highPriorityMarkers, ...lowPriorityMarkers]
            .map((group: any[]) => 
              group.filter((marker: any) => checkedMarkers.includes(marker.id))
            )
            .filter((filteredGroup: any[]) => filteredGroup.length > 0) // Filter out empty groups
            .map((filteredGroup: any[], index) => (
              <MarkerBlock 
                key={index}
                items={filteredGroup}
                blockIndex={index}
                isDisabled={!isEditPageActive}
                checkedByDefault={checkedMarkers}
                editedMarkers={editedMarkers}
                isBloodChecked={isBloodChecked}
              />
          ))
        }
        
        {/* {!!lowPriorityMarkers.length && (<>

          {/* <TVisibilityToggle 
            visible={visible || isLowPriorityMarkersCheckedByReferral}
            onClick={() => setVisible(!visible)}>
            Другие маркеры <TIcon size={12} src={arrowIcon}/>
          </TVisibilityToggle> */}

          {/* {(visible || isLowPriorityMarkersCheckedByReferral) && (
            lowPriorityMarkers.map((group: any[], index) => (
              <MarkerBlock 
                key={index}
                items={group} 
                blockIndex={index + highPriorityMarkers.length}
                isDisabled={!isEditPageActive} 
                // setCheckedMarkersIds={setCheckedMarkersIds}
                checkedByDefault={checkedMarkers}
                editedMarkers={editedMarkers}
                isBloodChecked={isBloodChecked}
              />
            ))
          )}
        </>)} */}

         {/* <TMarkerChanges>
          <TMarkersTitle>Изменения</TMarkersTitle>
          <TDoctorChanges>
            {!checkedMarkers.length && <li>Хотя бы один маркер должен быть отмечен для исследования</li>}
            {!uncheckedByMorfolog.length && !checkedByMorfolog.length && <li>Изменений нет</li>}
            {!!uncheckedByMorfolog.length && markersChangeHistory(uncheckedByMorfolog)}
            {!!checkedByMorfolog.length && markersChangeHistory(checkedByMorfolog)}
          </TDoctorChanges>
        </TMarkerChanges> */}

        {/* {!!doctorWhoCompleted ? doctorWhoCompleted : ''} */}
      </TFormWrapper>
      
      <InfoModal
        hasCloseButton
        showModal={validateMarkersActiv}
        onModalToggle={handleValidateMarkersConfirm}
        width={568}
        isWarning={true}
        title={'Пожалуйста, подтвердите список валидируемых маркеров:'}
        buttons={
        <>
          <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={handleValidateMarkersConfirm}>Вернуться</Button>
          <Button  size={SIZE.SMALL} onClick={handleValidateMarkers}>Подтвердить</Button>
        </>
      }>
        <TFinalMarkersList>
          {validateMarkersActiv && (finalCheckMarkersList()).map((markerName:string)=>{
            return <li key={markerName}>{markerName}</li>
          })}
        </TFinalMarkersList>
        
        <span>Изменить список после валидации будет невозможно</span>
      </InfoModal>
      {data?.ableToUdateREFERRAL?.updateMarkerValidation && !dateOfCompletion && (
        <TBtnWr>
          <TButton
            disabled={!isEditPageActive || isChangesSaved || allDataSaved || !checkedMarkers.length || !(checkedByMorfolog?.length || uncheckedByMorfolog?.length)}
            onClick={handleSaveChanges}
          >Сохранить изменения</TButton>
          <TButton 
            onClick={handleValidateMarkersConfirm} 
            disabled={disableValidate || isEditDisabled || (isEditPageActive && !allDataSaved)}
          >Валидировать данные</TButton>
        </TBtnWr>
      )}
    </TWrapper>
  )
}

export default MarkersValidation;