export const FLOW_STEPS_DATA = [
  'Приeм биоматериала',
  'Морфологическая характеристика',
  // 'Валидация маркеров',
  'Реализация исследований',
  'Формирование заключения',
  'Направление закрыто',
];

interface IFLOW_STEPS_STATUSES {
  bio_pending:number;
  morphology_pending:number;
  geneticist_review_pending:number;
  analysis_pending:number;
  analysis_absent:number;
  analysis_in_progress:number;
  conclusion_pending:number;
  referral_completed:number;
  referral_canceled:number;
  [key: string]: number;
}

export const FLOW_STEPS_STATUSES:IFLOW_STEPS_STATUSES = {
  bio_pending:1,
  morphology_pending:2,
  markers_validation_pending:3,
  geneticist_review_pending:3,
  analysis_pending:4,
  analysis_absent:4,
  analysis_in_progress:4,
  conclusion_pending:5,
  referral_completed:7,
  referral_canceled:6,
}



export const FLOW_STEPS_EXAMINATION = [
  'Попытка №1',
  'Попытка №2',
  'Попытка №3',
  'Исследование завершено',
];
