import React from 'react';

export function MeatballsMenuDefault(): JSX.Element {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_167_9543)">
                <circle cx="14" cy="14" r="14" fill="#EDEEF1"/>
                <circle cx="7" cy="14" r="2" transform="rotate(-90 7 14)" fill="#8D94A3"/>
                <circle cx="14" cy="14" r="2" transform="rotate(-90 14 14)" fill="#8D94A3"/>
                <circle cx="21" cy="14" r="2" transform="rotate(-90 21 14)" fill="#8D94A3"/>
            </g>
            <defs>
                <clipPath id="clip0_167_9543">
                    <rect width="28" height="28" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
}


