import { TBlock, TData, TLabel, TPatientData } from './styled';

interface IData {
  data?:any;
  country?: string;
  patientAmbulatoryCard?: any[]
}

const formatDate = (dateString: string) => {
  if (!dateString) return '--';

  const date = new Date(dateString);
  // Create a formatter object for the desired date format
  const formatter = new Intl.DateTimeFormat('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });
  return formatter.format(date);
};



const PatientData = ({data, country, patientAmbulatoryCard}:IData) => {
  return (
    <TPatientData>
      <TBlock>
        <TLabel>Фамилия</TLabel>
        <TData>{data?.lastName ?? '--'}</TData>
      </TBlock>
      <TBlock>
        <TLabel>Адрес регистрации</TLabel>
        <TData>{data?.address ?? 'г. Москва, ул. Некрасова, 3 кв.175'}</TData>
      </TBlock>
      <TBlock>
        <TLabel>Имя</TLabel>
        <TData>{data?.firstName ?? '--'}</TData>
      </TBlock>
      <TBlock>
        <TLabel>Личный номер / паспорт / ID  </TLabel>
        <TData>{data.idPassport}</TData>
      </TBlock>
      <TBlock>
        <TLabel>Отчество</TLabel>
        <TData>{!!data?.middleName ? data?.middleName : '--'}</TData>
      </TBlock>
      <TBlock>
        <TLabel>Номер СНИЛС</TLabel>
        <TData>{data?.snils ?? '083 367 324 77'}</TData>
      </TBlock>
      <TBlock>
        <TLabel>Гражданство</TLabel>
        <TData>{country ?? '--'}</TData>
      </TBlock>
      <TBlock>
        <TLabel>Номер полиса ОМС</TLabel>
        <TData>{data?.oms ?? '2345 4567 7689 8765'}</TData>
      </TBlock>
      <TBlock>
        <TLabel>Дата рождения</TLabel>
        <TData>{data?.dateOfBirth ? formatDate(data.dateOfBirth) : '--'}</TData>
      </TBlock>

      <TBlock>
        {patientAmbulatoryCard?.map((item) => {
          const ambulatoryCard = item.customOrganization ? item.customOrganization : item.organizationId?.nameLocalRus;

         return <div key={item.number + ambulatoryCard}><TLabel>Амбулаторная карта</TLabel> <TData>{item.number}, {ambulatoryCard}</TData></div>
        })}
      </TBlock>
      <TBlock>
        <TLabel>Пол</TLabel>
        <TData>{data.gender === 'Male' ? 'Мужчина' : 'Женщина'}</TData>
      </TBlock>
    </TPatientData>
  );
};

export default PatientData;
