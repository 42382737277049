import { FC, RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { fetchMolecularProfiling, fetchMolProPatientReferrals, patchMolecularProfilingCancellation, patchMolecularProfilingCancellationRequest, patchReferralComplite, postMolecularProfilingCancellationRequest } from '../../store/molecularProfiling/thunkActions';
import {
  getComplitedReferralNumber,
  getIsReferralReadyToComplite,
  getMolecularProfiling,
  getMolecularProfilingLoading,
  getMolProPatientReferralsData,
} from '../../store/molecularProfiling/selectors';
import { FLOW_STEPS_DATA, FLOW_STEPS_STATUSES } from '../../fixtures/StepsMolecularProfilingPage';
import { resetMolecularProfiling, resetSavedMolProCharacteristic } from '../../store/molecularProfiling';
import Button, { SIZE, VARIANT } from '../../components/shared/Button';
import InfoModal from '../../components/shared/InfoModal';
import FlowStep from '../../components/shared/FlowStep';
import { useDispatch, useSelector } from 'react-redux';
import Details from '../../components/shared/Details';
import { checkCancelREFERRAL, checkPermitUdateREFERRAL, checkPermitViewExperement, checkPermitViewREFERRAL, getTokens } from '../../store/auth/selectors';
import InfoTabs from '../../components/shared/Tabs';
import { ROUTES } from '../../constants/routes';
import { useParams, useNavigate } from 'react-router-dom';

import defaultStatusIcon from '../../icons/cross-in-circle-default-filled.svg';
import { ReactComponent as WarnIcon } from '../../icons/warn-red-circle.svg';
import pendingStatusIcon from '../../icons/attention.svg';
import doneStatusIcon from '../../icons/done.svg';
import { ReactComponent as SuccessModalIcon } from '../../icons/success-modal.svg';

import {
  TMolecularProfilingDetailsButtonBlock,
  TMolecularProfilingDetailsWrapper,
  TMolecularProfilingBlock,
  TPatientReferralNumber,
  TPatientDataBlock,
  TCheckBoxListWr,
  TMenuIconWr,
  TPageTitle,
  // TMenuIcon,
  TBackLink,
  TButton,
  TModalInfoText,
  TBage,
  TCustomInput,
  TCancelReasonBlock,
  TCancelRequestBlock,
  TButtonWrapper,
  TMenuPrintIcon,
  TPrintMenu,
  TPrintMenuItem,
} from './styled';
import { getCountries } from '../../store/countries/selectors';
import { fetchCountries } from '../../store/countries/thunkActions';
import { getOrganizations } from '../../store/organizations/selectors';
import { fetchOrganizations } from '../../store/organizations/thunkActions';
import { prepareAmbulatoryCardPatientHelper } from './prepareAmbulatoryCardPatientHelper';
import Loader from '../../components/shared/Loader';
import NoDataToShow from "./NoDataToShow";
import ClinicalData from './ClinicalData';
import ReferralSideData from './ReferralSideData';
import BioMaterialData from './BioMaterialData';
import MorphologicalCharacteristic from './MorphologicalCharacteristic';
import MarkersValidation from './MarkersValidation';
import AnalysisExecution from './AnalysisExecution';
import CreateExperiment from './Analysis';
import { getMarkerTypes } from '../../store/markers/selectors';
import { fetchMethodTypeList } from '../../store/markers/thunkActions';
import Сonclusion from './Сonclusion/Сonclusion';
import { resetAnalysisByBluprint } from '../../store/analysis/thunkActions';
import { Examination, IMolProRequest, IStepReferral } from '../../store/molecularProfiling/model';
import { getServiceUsers } from '../../store/serviceUsers/selectors';
import { format } from 'date-fns';
import { resetConclusionData } from '../../store/molProConclusion';
import ReferralToPrint from '../../components/documents/ReferralToPrint';
import { useReactToPrint } from 'react-to-print';
import { fetchSavedConclusionModules } from '../../store/molProConclusion/thunkActions';
import { getSavedConclusionData } from '../../store/molProConclusion/selectors';
import ConclusionToPrint from '../../components/documents/ConclusionToPrint';
import ResultFormDoc from './ResultForm';



interface IMolProMap {
  [index: string]: any;
}

interface IAbleToUdate {
  updateClinicalData: boolean;
  updateBiologicalMaterial: boolean;
  updateMorphology: boolean;
  updateMarkerValidation: boolean;
  updateExamination: boolean;
  updateCoclusion: boolean;
}

interface IDetailsData {
  id: number;
  title: string;
  content: object;
  status: string;
  ableToUdatePermit: IAbleToUdate;
  component?: FC;
}

const AbleToUdateDefault = {
  updateClinicalData: false,
  updateBiologicalMaterial: false,
  updateMorphology: false,
  updateMarkerValidation: false,
  updateExamination: false,
  updateCoclusion: false,
}

const detailDataComponents = [
  ReferralSideData,
  ClinicalData,
  BioMaterialData,
  MorphologicalCharacteristic,
  MarkersValidation,
  AnalysisExecution,
  Сonclusion
]

const detailsData: IDetailsData[] = [
  { id: 1, title: 'Данные направляющей стороны', content: {}, status: "", component: NoDataToShow, ableToUdatePermit: AbleToUdateDefault },
  { id: 2, title: 'Клинические данные', content: {}, status: "", component: NoDataToShow, ableToUdatePermit: AbleToUdateDefault },
  { id: 3, title: 'Биоматериал', content: {}, status: "", component: NoDataToShow, ableToUdatePermit: AbleToUdateDefault },
  { id: 4, title: 'Морфологическая характеристика', content: {}, status: "", component: NoDataToShow, ableToUdatePermit: AbleToUdateDefault },
  { id: 5, title: 'Маркеры', content: {}, status: "", component: NoDataToShow, ableToUdatePermit: AbleToUdateDefault },
  { id: 6, title: 'Исследования', content: {}, status: "", component: NoDataToShow, ableToUdatePermit: AbleToUdateDefault },
  { id: 7, title: 'Электронное заключение', content: {}, status: "", component: NoDataToShow, ableToUdatePermit: AbleToUdateDefault },
  // {id:8,title:'Комментарии',                    content:{}, status: "", component: NoDataToShow, ableToUdatePermit: AbleToUdateDefault},
]

const iconAndStatus = {
  progress: pendingStatusIcon,
  pending: pendingStatusIcon,
  default: defaultStatusIcon,
  done: doneStatusIcon,
  finish: doneStatusIcon,
}



const MolecularProfilingDetailsPage: FC = () => {
  const dispatch = useDispatch();
  const { id: refferalULID, method: methodUrlParam } = useParams();
  const tokens = useSelector(getTokens);
  const navigate = useNavigate();

  //Permissions
  const ableToViewREFERRAL = useSelector(checkPermitViewREFERRAL);
  const ableToUdateREFERRAL = useSelector(checkPermitUdateREFERRAL);
  const ableToCancelREFERRAL = useSelector(checkCancelREFERRAL);
  const ableToViewExperement = useSelector(checkPermitViewExperement);
  const usersList = useSelector(getServiceUsers);

  //Conclusion Data
  const savedConclusionData = useSelector(getSavedConclusionData);

  //Data for user card
  const molecularProfilingData = useSelector(getMolecularProfiling);
  const countries = useSelector(getCountries);
  const organizations = useSelector(getOrganizations);
  const patientReferrals = useSelector(getMolProPatientReferralsData)
  const loading = useSelector(getMolecularProfilingLoading)
  const countryName = countries.find((country) => country.id === molecularProfilingData?.patient?.country);
  const markerTypeList = useSelector(getMarkerTypes);
  const complitedReferralNumber = useSelector(getComplitedReferralNumber);
  const referralReadyToComplite = useSelector(getIsReferralReadyToComplite);
  const localCountryName = countryName?.name;
  const ambulatoryCardPatient = molecularProfilingData?.ambulatoryCard;
  const patientUlid = molecularProfilingData?.patient?.ulid;

  const [isLoadRequest, setLoadRequest] = useState(false);
  //TabData
  const [detailsTabsData, setDetailsTabsData] = useState<IDetailsData[]>([]);

  //Cancel Refferal State
  const [isCancelRefferalState, setCancelRefferalState] = useState(false);

  //Show Experiment page
  const [showExperimentPage, setShowExperimentPage] = useState(false);

  //Print
  const printReferralDataRef = useRef(null) as RefObject<HTMLInputElement>;
  const handlePrintReferral = useReactToPrint({
    documentTitle: `${molecularProfilingData?.referralId} Направление на молекулярное профилирование`,
    content: () => printReferralDataRef.current,
  }) ?? (() => null);

  const printConclusionDataRef = useRef(null) as RefObject<HTMLInputElement>;
  const handlePrintConclusion = useReactToPrint({
    documentTitle: `${savedConclusionData?.moduleDefault?.restoreDefaultData?.formTitle ?? 'Заключение'}, для направления ${molecularProfilingData?.referralId}`,
    content: () => printConclusionDataRef.current,
  }) ?? (() => null);

  //Modals
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showReferralCompletedModal, setShowReferralCompletedModal] = useState(false);
  const [showReferralIsComplitModal, setShowReferralIsComplitModal] = useState(false);
  const [showDeclineCancelModal, setDeclineCancelModal] = useState(false);
  const [showCancelReasonModal, setShowCancelReasonModal] = useState(false);

  //Cancel referral reason
  const [cancelReferralReason, setCancelReferralReason] = useState('');
  const [cancelRequestReason, setCancelRequestReason] = useState('');

  const onOpenSaveChenges = useCallback(() => setShowSaveChangesModal(true), []);
  const onCloseSaveChenges = useCallback(() => setShowSaveChangesModal(false), []);
  const onCloseCancelReasonModal = useCallback(() => {
    setShowCancelReasonModal(false);
    setCancelReferralReason('');
  }, [setCancelReferralReason]);

  const onCloseDeclineCancelModal = useCallback(() => {
    setDeclineCancelModal(false);
    setCancelReferralReason('');
  }, [setDeclineCancelModal]);

  const isCancellationReferralButtonDisable =  useMemo(() => molecularProfilingData?.status === 'referral_completed',[molecularProfilingData]);

  const onOpenCancelModal = useCallback(() => {
    if (!isCancellationReferralButtonDisable) {
      setShowCancelModal(true);
    }
  }, [isCancellationReferralButtonDisable, setShowCancelModal]);

  const onOpenReferralCompletedModal = useCallback(() => {
    if (!isCancellationReferralButtonDisable) {
      setShowReferralCompletedModal(!showReferralCompletedModal);
    }
  }, [isCancellationReferralButtonDisable, setShowReferralCompletedModal,showReferralCompletedModal]);

  const onOpenDeclineCancelModal = useCallback(() => {
    if (!isCancellationReferralButtonDisable) {
      setDeclineCancelModal(true);
    }
  }, [isCancellationReferralButtonDisable, setDeclineCancelModal]);

  const onOpenCancelReasonModal = useCallback(() => {
    setCancelReferralReason(cancelRequestReason)
    setShowCancelReasonModal(true);
    setShowCancelModal(false);
  }, [setShowCancelModal, setShowCancelReasonModal, setCancelReferralReason, cancelRequestReason]);
  const onCloseCancelModal = useCallback(() => setShowCancelModal(false), []);

  const onConfirmPageLeave = useCallback(async () => {
    onCloseSaveChenges();
    return navigate(ROUTES.REFERRALS);
  }, [onCloseSaveChenges, navigate]);


  const checkSavedData = useCallback(async () => {
    let notSavedItems = sessionStorage.length - 1;
    if (!notSavedItems) {
      onConfirmPageLeave();
    }
    onOpenSaveChenges()
  }, [onConfirmPageLeave, onOpenSaveChenges])

  const [isMedicalReportReady, isAnalysisDone] = useMemo(() => {
    const examinationBlueprints = molecularProfilingData?.examinationReferral?.examinationBlueprints;
    let cancellation = molecularProfilingData?.statusCancellation;
    let isAnalysisDone = !!molecularProfilingData?.stepReferral?.find((step: IStepReferral) => step.status === 'analysis_done');
    let reportReady = false;
    if (!examinationBlueprints?.length || cancellation) return [reportReady, isAnalysisDone];

    reportReady = examinationBlueprints.every((blueprint: Examination) => !!blueprint?.status && ['Cancel', 'Cancelled', 'Complete'].includes(blueprint.status));
    return [reportReady, isAnalysisDone]
  }, [molecularProfilingData]);

  //DropDownList and Data
  const flowStepIndex = useMemo(() => {
    let actualStatus = molecularProfilingData?.status;
    if (!actualStatus) return 0;
    return FLOW_STEPS_STATUSES[actualStatus];

  }, [molecularProfilingData?.status]);


  useLayoutEffect(() => {
    if (!flowStepIndex || !molecularProfilingData?.ulid || !organizations) return;
    if (parseInt(methodUrlParam ?? '') ?? false) setShowExperimentPage(true)

    const molProData: IMolProMap = { ...molecularProfilingData, organizations, ableToUdateREFERRAL };
    const doneStatus = flowStepIndex + 1;
    const currentStaus = molProData.status ?? '';

    const tabsData = detailsData.map((item: IDetailsData, index: number) => {
      //add content
      item.content = molProData;
      item.component = detailDataComponents[index];
      //add status
      if (index <= doneStatus) item.status = 'done';
      if (index === doneStatus) {
        item.status = currentStaus
        // if (isMedicalReportReady && !isAnalysisDone && molecularProfilingData?.status === 'conclusion_pending') {
        //   item.content = {};
        //   item.component = NoDataToShow;
        // }
      };

      if (index > doneStatus) {
        item.status = '';
        if (item.id !== 5) {
          item.status = '';
          item.content = {};
          item.component = NoDataToShow;
        }
      }

      return item;
    });

    if(currentStaus === 'referral_completed'){
      tabsData.push({ id: 8, title: 'Бланк-результат', content: {}, status: "finish", component: ResultFormDoc, ableToUdatePermit: AbleToUdateDefault })
    }

    setDetailsTabsData(tabsData);

    return () => setDetailsTabsData([]);

  }, [flowStepIndex, molecularProfilingData, organizations, setDetailsTabsData, methodUrlParam, setShowExperimentPage, ableToUdateREFERRAL, isMedicalReportReady, isAnalysisDone]);


  //replace title if referral canceled
  const flowStepsTitle = useMemo(() => {
    const FLOW_STEPS = [...FLOW_STEPS_DATA];
    if (molecularProfilingData?.statusCancellation) {
      let index = FLOW_STEPS.findIndex((item: string) => item === ('Направление закрыто'));
      if (~index) FLOW_STEPS[index] = 'Направление отменено';
      return FLOW_STEPS;
    }
    return FLOW_STEPS;
  }, [molecularProfilingData?.statusCancellation]);


  const handleGetMolecularProfilingData = useCallback(async (ulid?: string) => {
    if (!ulid) return;
    tokens?.access && await dispatch(fetchMolecularProfiling(tokens.access, ulid));

  }, [tokens?.access, dispatch]);

  const handleGetPatientReferralsData = useCallback(async (pat_ulid?: string) => {

    if (!pat_ulid) return;
    tokens?.access && await dispatch(fetchMolProPatientReferrals(tokens.access, pat_ulid));
    setLoadRequest(true);
  }, [dispatch, tokens?.access])

  const newAmbulatoryCart = useMemo(() => {
    if (ambulatoryCardPatient && organizations) {
      return prepareAmbulatoryCardPatientHelper(ambulatoryCardPatient, organizations)
    }
  }, [ambulatoryCardPatient, organizations])

  //Load page data
  useLayoutEffect(() => {
    setLoadRequest(false);
    if (!molecularProfilingData?.ulid) {
      handleGetMolecularProfilingData(refferalULID);
    }
    return () => setLoadRequest(true);
  }, [refferalULID, handleGetMolecularProfilingData, molecularProfilingData?.ulid]);

  //Load Data for patient refferals, examination and card
  useEffect(() => {
    !countries.length && tokens?.access && dispatch(fetchCountries(tokens.access));
  }, [tokens?.access, dispatch, countries]);

  // organizations data
  useEffect(() => {
    !organizations.length && tokens?.access && dispatch(fetchOrganizations(tokens.access));
  }, [tokens?.access, dispatch, organizations]);

  // examination data
  useEffect(() => {
    !markerTypeList.length && tokens?.access && dispatch(fetchMethodTypeList(tokens.access));
  }, [tokens?.access, dispatch, markerTypeList]);

  useEffect(() => {
    setLoadRequest(false);
    if (!patientReferrals.length && tokens?.access) {
      handleGetPatientReferralsData(patientUlid);
    }
    return () => setLoadRequest(true);
  }, [tokens?.access, patientUlid, handleGetPatientReferralsData, patientReferrals]);

  //Referral payment type
  const [referralTypePaid, referralTypeSpeed, referralTypeCreate] = useMemo(() => {
    let payment, speed, create;
    let doctorProfileID = molecularProfilingData?.doctorReferral?.ulid ?? '--';
    let referralCreatorID = molecularProfilingData?.referralCreator;

    if (molecularProfilingData?.paymentType === 'Paid') {
      payment = <TBage color='#9578E9'>Платно</TBage>;
    }

    if (molecularProfilingData?.speedType === 'Urgent') {
      speed = <TBage color='#F36ABC'>Срочно</TBage>;
    }

    if (!!doctorProfileID && !!referralCreatorID && doctorProfileID !== referralCreatorID) {
      create = <TBage color='#3E74FF'>Создано регистратором</TBage>;
    }

    return [payment, speed, create]
  }, [molecularProfilingData]);


  useLayoutEffect(() => {
    dispatch(resetSavedMolProCharacteristic());
    return () => {
      dispatch(resetAnalysisByBluprint());
      dispatch(resetMolecularProfiling());
      dispatch(resetConclusionData());
      dispatch(resetSavedMolProCharacteristic());
    }
  }, [dispatch]);

  const cancelReasonInputHandler = useCallback(({ value }) => {
    setCancelReferralReason(value);
  }, [setCancelReferralReason]);

  const cancelRequestMessage = useMemo(() => {
    let data = molecularProfilingData?.requestReferral?.filter((data: IMolProRequest) => data?.status === 'Cancellation' && data?.pending && !data?.confirmation);
    
    const getNameAndTitle = (userUlid: string) => {
      let nameAndTitle = '';
      let doctorName = usersList?.find((item) => item.ulid === userUlid)?.userProfile;
      if (doctorName) {
        let { firstName = '', lastName = '', middleName = '', title = '' } = doctorName;
        nameAndTitle = `${lastName} ${firstName} ${middleName},`.trim() + '\n' + title;
      };
      return nameAndTitle;
    }

    if (data && data?.length) {
      let { pending, comment, confirmation, dateConfirmation, dateCreated, status, userUlid, id } = data[0];
      let requesterName = getNameAndTitle(userUlid);
      let dateConfirm = dateConfirmation ? format(new Date(dateConfirmation), 'dd.MM.yyyy - HH:mm:ss') : '';
      let dateCreate = dateCreated ? format(new Date(dateCreated), 'dd.MM.yyyy - HH:mm:ss') : '';
      setCancelRequestReason(comment);
      return { id, pending, comment, confirmation, dateConfirmation: dateConfirm, dateCreated: dateCreate, status, user: requesterName }
    }
    return {};
  }, [molecularProfilingData?.requestReferral,usersList, setCancelRequestReason]);

  const resetMolProData = useCallback(async() => {
    await dispatch(resetAnalysisByBluprint());
    await dispatch(resetMolecularProfiling());
    return setTimeout(() => {
      onCloseDeclineCancelModal();
      return onCloseCancelReasonModal();
    }, 1000);
  },[dispatch,onCloseDeclineCancelModal,onCloseCancelReasonModal])

  const onConfirm = useCallback(async () => {
    if (!refferalULID || !molecularProfilingData?.id || !tokens?.access) return;



    if (!ableToCancelREFERRAL && !molecularProfilingData?.cancelOption && molecularProfilingData?.cancelRequestOption) {
      await dispatch(postMolecularProfilingCancellationRequest(tokens?.access, molecularProfilingData.id, cancelReferralReason));
      await resetMolProData();
    }

    if (ableToCancelREFERRAL || molecularProfilingData?.cancelOption) {
      if (!!Object.keys(cancelRequestMessage)?.length) {
        let requestID = cancelRequestMessage?.id ?? 0
        let isConfirmCancel = true;
        dispatch(patchMolecularProfilingCancellationRequest(tokens?.access, requestID, isConfirmCancel, cancelReferralReason));
      }
      await dispatch(patchMolecularProfilingCancellation(tokens?.access, refferalULID, cancelReferralReason));
      setCancelRefferalState(true);
      await resetMolProData();
    }

  }, [dispatch, refferalULID, tokens, setCancelRefferalState, molecularProfilingData,
    ableToCancelREFERRAL, cancelRequestMessage, cancelReferralReason, resetMolProData]);

  const onConfirmDeclineCancel = useCallback(async () => {
    if (tokens?.access && (ableToCancelREFERRAL || molecularProfilingData?.cancelOption)) {
      if (!!Object.keys(cancelRequestMessage)?.length) {
        let requestID = cancelRequestMessage?.id ?? 0;
        let isConfirmCancel = false;
        dispatch(patchMolecularProfilingCancellationRequest(tokens?.access, requestID, isConfirmCancel));
      }
      await resetMolProData();
    }
    

  }, [tokens,dispatch,cancelRequestMessage,ableToCancelREFERRAL,molecularProfilingData?.cancelOption, resetMolProData]);

  const onConfirmReferralComplite = useCallback(() => {
    if(!!molecularProfilingData && tokens?.access && (ableToCancelREFERRAL || !molecularProfilingData?.cancelRequestOption)){
      let {id, ulid, referralId } = molecularProfilingData;
      dispatch(patchReferralComplite(tokens?.access,ulid,id,referralId));
      setShowReferralCompletedModal(false);
    }
  }, [tokens,dispatch,ableToCancelREFERRAL,molecularProfilingData,setShowReferralCompletedModal]);

  const handleReferralIsComplitModal = useCallback(async () => {
    if(showReferralIsComplitModal){
      setShowReferralIsComplitModal(false);
      await resetMolProData();
    } else setShowReferralIsComplitModal(true);
  },[setShowReferralIsComplitModal,showReferralIsComplitModal,resetMolProData]);

  useEffect(() => {
    if(!!complitedReferralNumber) setShowReferralIsComplitModal(true);
  },[complitedReferralNumber,setShowReferralIsComplitModal]);

  useLayoutEffect(() => {
    let referralID = molecularProfilingData?.ulid
    if (tokens?.access && referralID && referralReadyToComplite) {
      dispatch(fetchSavedConclusionModules(tokens?.access, referralID));
    }
  }, [tokens?.access, dispatch, molecularProfilingData, referralReadyToComplite]);

  const referralPrintData = useMemo(() => {
    if(!molecularProfilingData?.id) return;
    return molecularProfilingData;
  },[molecularProfilingData]);

  const conclusionPrintData = useMemo(() => {
    if(!referralReadyToComplite || !savedConclusionData || molecularProfilingData?.status === 'conclusion_pending') return;
    return savedConclusionData;
  },[savedConclusionData, referralReadyToComplite, molecularProfilingData?.status]);

  


  return (
    <Loader enabled={loading}>
      <TMolecularProfilingDetailsWrapper>
        <TPatientDataBlock>
          <InfoTabs title='Данные пациента' borderRadiusTopRight={10} patient={molecularProfilingData?.patient} country={localCountryName} newAmbulatoryCart={newAmbulatoryCart} />
          <InfoTabs title='Направления' referral={patientReferrals} hasPermit={ableToViewREFERRAL} />
          <InfoTabs title='Исследования ' hasPermit={ableToViewExperement} borderRadiusBottom={10} examination={molecularProfilingData?.examinationReferral?.examinationBlueprints} />
        </TPatientDataBlock>

        {!showExperimentPage &&
          <TMolecularProfilingBlock>
            <TPatientReferralNumber><span>Направление {`${molecularProfilingData?.referralId ?? ''}`}</span>
              <TMenuIconWr>
                <TMenuPrintIcon />
                <TPrintMenu>
                  <TPrintMenuItem 
                    isAvailable={true} 
                    onClick={() => handlePrintReferral()}
                  >Печать направления</TPrintMenuItem>
                  <TPrintMenuItem 
                    isAvailable={(referralReadyToComplite && !!conclusionPrintData)} 
                    onClick={() => conclusionPrintData ? handlePrintConclusion() : null}
                  >Печать заключения</TPrintMenuItem>
                </TPrintMenu>
              </TMenuIconWr>
            </TPatientReferralNumber>
            <TBackLink onClick={checkSavedData}><span>&lsaquo;</span> К списку направлений</TBackLink>
            <TPageTitle>Молекулярное профилирование {!!referralTypeSpeed && referralTypeSpeed} {!!referralTypePaid && referralTypePaid} {!!referralTypeCreate && referralTypeCreate}</TPageTitle>
            <FlowStep
              activeIndex={flowStepIndex}
              flowSteps={flowStepsTitle}
              canceled={isCancelRefferalState || molecularProfilingData?.statusCancellation} />
            <TCheckBoxListWr >
              {molecularProfilingData?.statusCancellation && <TCancelReasonBlock>Направление отменено: <i>{molecularProfilingData?.cancellationReason}</i></TCancelReasonBlock>}

              {!!Object.keys(cancelRequestMessage)?.length && (
                <TCancelRequestBlock>
                  <b>Запрос на отмену направления.</b>
                  <span><i>Дата формирования запроса: </i> {cancelRequestMessage?.dateCreated}</span>
                  <span><i>Инициатор: </i> {cancelRequestMessage?.user}</span>
                  <span><i>Причина: </i> {cancelRequestMessage?.comment}</span>
                </TCancelRequestBlock>)}

              {!!detailsTabsData.length ?
                <Details isSelected={false} detailsData={detailsTabsData} iconAndStatus={iconAndStatus} closeAllTabs={!!molecularProfilingData?.statusCancellation || isCancellationReferralButtonDisable} /> :
                <NoDataToShow title='Загружаем данные' description='перезагрузите страницу, если видите это сообщение' />
              }
            </TCheckBoxListWr>
            <TMolecularProfilingDetailsButtonBlock>
              {!!referralReadyToComplite && ableToCancelREFERRAL && molecularProfilingData?.cancelOption && !isCancellationReferralButtonDisable && !cancelRequestMessage?.pending && (
                <TButton
                  isActive={true}
                  onClick={() => onOpenReferralCompletedModal()}
                  disabled={isLoadRequest || isCancelRefferalState || molecularProfilingData?.statusCancellation || isCancellationReferralButtonDisable}>
                  Закрыть направление
                </TButton>
              )}
              {ableToCancelREFERRAL && molecularProfilingData?.cancelOption && !!Object.keys(cancelRequestMessage)?.length && (
                <TButton
                  onClick={onOpenDeclineCancelModal}
                  disabled={isLoadRequest || isCancelRefferalState || molecularProfilingData?.statusCancellation || isCancellationReferralButtonDisable}>
                  Отклонить запрос
                </TButton>
              )}
              {(!!Object.keys(cancelRequestMessage)?.length || !referralReadyToComplite ) && ableToCancelREFERRAL && molecularProfilingData?.cancelOption && (
                <TButton
                  isActive={!!Object.keys(cancelRequestMessage)?.length}
                  onClick={!!molecularProfilingData?.examinationReferral?.examinationBlueprints?.length ? onOpenCancelModal : onOpenCancelReasonModal}
                  disabled={isLoadRequest || isCancelRefferalState || molecularProfilingData?.statusCancellation || isCancellationReferralButtonDisable}>
                  Отменить направление
                </TButton>
              )}
              {!ableToCancelREFERRAL && molecularProfilingData?.cancelRequestOption && (
                <TButton
                  width={410}
                  onClick={!!molecularProfilingData?.examinationReferral?.examinationBlueprints?.length ? onOpenCancelModal : onOpenCancelReasonModal}
                  disabled={isLoadRequest || isCancelRefferalState || molecularProfilingData?.statusCancellation || isCancellationReferralButtonDisable || !!Object.keys(cancelRequestMessage)?.length}>
                  Запросить отмену направления
                </TButton>
              )}
            </TMolecularProfilingDetailsButtonBlock>
          </TMolecularProfilingBlock>
        }
        {showExperimentPage && <CreateExperiment data={molecularProfilingData} />}
        <InfoModal
          showModal={showCancelModal}
          onModalToggle={onCloseCancelModal}
          isWarning={true}
          icon={<WarnIcon />}
          title={'Все исследования в рамках данного направления будут отменены'}
          hasCloseButton
          buttons={
            <>
              <Button disabled={isCancelRefferalState} size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onCloseCancelModal}>Вернуться</Button>
              <Button disabled={isCancelRefferalState} size={SIZE.SMALL} onClick={onOpenCancelReasonModal}>Да</Button>
            </>
          } />
        <InfoModal
          showModal={showDeclineCancelModal}
          onModalToggle={onCloseDeclineCancelModal}
          isWarning={true}
          icon={<WarnIcon />}
          title={'Запрос на отмену будет отклонен для продолжения работы по направлению.'}
          hasCloseButton
          buttons={
            <>
              <Button disabled={isCancelRefferalState} size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onCloseDeclineCancelModal}>Вернуться</Button>
              <Button disabled={isCancelRefferalState} size={SIZE.SMALL} onClick={onConfirmDeclineCancel}>Ок</Button>
            </>
          } />
        <InfoModal
          showModal={showCancelReasonModal}
          onModalToggle={onCloseCancelReasonModal}
          isWarning={true}
          title={!molecularProfilingData?.cancelOption ?
            'Для отмены направления укажите причину и отправьте запрос Координатору лаборатории.' : 'Пожалуйста, укажите причину отмены:'}
          hasCloseButton
          buttons={
            <Button disabled={cancelReferralReason?.length < 3 || cancelReferralReason?.length > 50} size={SIZE.LARGE} variant={VARIANT.DEFAULT} onClick={onConfirm}>Подтвердить</Button>
          }>
          {!molecularProfilingData?.cancelOption && <TModalInfoText>Координатор примет решение о приостановке или продолжении работы над данным направлением.</TModalInfoText>}
          <TCustomInput
            bordered
            placeholder={molecularProfilingData?.cancelRequestOption && !molecularProfilingData?.cancelOption ? 'Укажите причину' : 'Введите текст'}
            onChange={async ({ target }) => cancelReasonInputHandler(target)}
            value={cancelReferralReason}
            type="text"
            error={cancelReferralReason?.length > 50}
            maxLength={51}
          />
        </InfoModal>

        <InfoModal
          hasCloseButton
          showModal={showSaveChangesModal}
          onModalToggle={onCloseSaveChenges}
          isWarning={true}
          title={'Хотите сохранить изменения, прежде чем перейти к списку направлений?'}
          buttons={
            <>
              <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onConfirmPageLeave}>Перейти</Button>
              <Button size={SIZE.SMALL} onClick={onCloseSaveChenges}>Да, сохранить</Button>
            </>
          }>
          <TModalInfoText>Если не сохранить, изменения будут потеряны.</TModalInfoText>
        </InfoModal>

        <InfoModal
          hasCloseButton
          showModal={showReferralCompletedModal}
          onModalToggle={onOpenReferralCompletedModal}
          isWarning={true}
          title={'Хотите закрыть направление?'}
          buttons={
            <>
              <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onOpenReferralCompletedModal}>Нет</Button>
              <Button size={SIZE.SMALL} onClick={onConfirmReferralComplite}>Да</Button>
            </>
          }>
          <TModalInfoText>Подтвердив действие, вы завершите работу над данным направлением. Внесение каких-либо изменений станет невозможным.</TModalInfoText>
        </InfoModal>

        <InfoModal
          hasCloseButton
          showModal={showReferralIsComplitModal}
          onModalToggle={handleReferralIsComplitModal}
          isWarning={true}
          icon={<SuccessModalIcon />}
          title={!(complitedReferralNumber ?? (molecularProfilingData?.referralId || '')) ? 'Обновление данных ...' : `Направление № ${complitedReferralNumber ?? (molecularProfilingData?.referralId || '')} закрыто`}
          buttons={
            molecularProfilingData?.referralId && (
                <TButtonWrapper>
                  <Button size={SIZE.LARGE} onClick={() => conclusionPrintData ? handlePrintConclusion() : null}>Распечатать</Button>
                  <Button size={SIZE.LARGE} onClick={handleReferralIsComplitModal} variant={VARIANT.TRANSPARENT} > К текущему направлению</Button>
                  {/* <Button size={SIZE.LARGE} onClick={onConfirmPageLeave} variant={VARIANT.TRANSPARENT} > К списку всех направлений</Button> */}
                </TButtonWrapper>
              )
          }>
        </InfoModal>
        {!!referralPrintData && <div style={{ display: "none" }} >
        <ReferralToPrint 
          ref={printReferralDataRef} 
          referral={{ ...referralPrintData, createdReferralId:referralPrintData?.referralId }} 
        />
        </div>}
        {!!conclusionPrintData && <div style={{ display: "none" }}>
          <ConclusionToPrint 
            ref={printConclusionDataRef} 
            modules={conclusionPrintData}
          />
        </div>}
      </TMolecularProfilingDetailsWrapper>
    </Loader>

  )
}

export default MolecularProfilingDetailsPage;