export const REFERRAL_TYPE = {
  'molecularProfiling': 'Молекулярное профилирование',
  'IHC': 'Иммуногистохимическое исследование',
};

export const REFERRAL_STATUS:{[index: string]: string;} = {
  'bio_pending': 'Прием биоматериала',
  'morphology_pending': 'Морфологическая характеристика',
  'markers_validation_pending': 'Валидация маркeров',
  'analysis_pending': 'Реализация исследований',
  'conclusion_pending': 'Формирование заключения',
  'referral_pending': 'Направление закрыто',
  'referral_canceled': 'Направление отменено',
  'referral_completed': 'Направление закрыто',
};