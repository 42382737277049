import styled from "styled-components";
import { Title } from "../../../../../../componentsNew/Title";
import { Input } from "../../../../../../componentsNew/Input";
import { Datepicker } from "../../../../../../componentsNew/Datepicker";
import { Radio } from "../../../../../../componentsNew/Radio";
import { Select } from "../../../../../../componentsNew/Select";
import { useDispatch, useSelector } from "react-redux";
import {
    editedPatientSelector,
    newRfProfileSelector,
    setEditedPatient,
    setNewRfProfileAddress,
    setNewRfProfileBirthdate,
    setNewRfProfileCardHospital,
    setNewRfProfileCardNumber,
    setNewRfProfileCitizenship,
    setNewRfProfileFirstName,
    setNewRfProfileLastName,
    setNewRfProfileOms,
    setNewRfProfilePassport,
    setNewRfProfilePatronymic,
    setNewRfProfileSexCode,
    setNewRfProfileSnils,
} from "../../../../redux/patient";
import { Description } from "../../../../../../componentsNew/Description";
import { useHttpClient } from "../../../../../../hooks/useHttpClient";
import React, { useEffect, useState } from "react";
import { ClinicsService } from "../../../../../../services/Clinics";
import { CountriesService } from "../../../../../../services/Countries";
import { Button } from "../../../../../../componentsNew/Button";

import { RF_CITIZEN_CODE } from "../../../../../../constants/codes";

const sexes = [
  {
    code: "M",
    name: "Мужской",
  },
  {
    code: "F",
    name: "Женский",
  },
];

export const NewRfProfile = () => {
  const dispatch = useDispatch();

  const editedPatient = useSelector(editedPatientSelector);
  const newRfProfile = useSelector(newRfProfileSelector);

  const httpClient = useHttpClient();

  const [clinics, setClinics] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);

  const isPatientFormFilled = Boolean(
      newRfProfile?.address &&
      newRfProfile?.birthdate &&
      newRfProfile?.card?.number &&
      newRfProfile?.card?.number &&
      newRfProfile?.card?.hospital?.value &&
      newRfProfile?.citizenship &&
      newRfProfile?.firstName &&
      newRfProfile?.lastName &&
      newRfProfile?.oms &&
      newRfProfile?.passport &&
      newRfProfile?.sexCode &&
      newRfProfile?.snils
  );



  const effect = async () => {
    const clinicsService = new ClinicsService(httpClient);
    const _clinics = await clinicsService.searchClinics();

    const countriesService = new CountriesService(httpClient);
    const _countries = await countriesService.getCountries();

    if (_clinics?.length) {
        //в стейт редакса сохранять?
        setClinics(_clinics);
    }

    if (_countries?.length) {
      setCountries(_countries);
      console.log('countries', countries);
    }


  };

  useEffect(() => {
      dispatch(setNewRfProfileCitizenship(RF_CITIZEN_CODE));
      effect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("_______ newRfProfile", newRfProfile);

  const onPatientConfirm = () => {
      dispatch(
          setEditedPatient({
              ulid: '',
              idPassport: newRfProfile?.passport,
              address: newRfProfile?.address,
              countryId: '',
              countryName: newRfProfile?.citizenship,
              countryAlphaCode: '',
              gender: newRfProfile?.sexCode,
              lastName: newRfProfile?.lastName,
              firstName: newRfProfile?.firstName,
              patronymic: newRfProfile?.patronymic,
              oms: newRfProfile?.oms,
              snils: newRfProfile?.snils,
              birthdate: newRfProfile?.birthdate,
              fullName: `${newRfProfile?.firstName} ${newRfProfile?.lastName} ${newRfProfile?.patronymic}`,
              ambulatoryCard: {
                  number: newRfProfile?.card?.number,
                  organizationId: newRfProfile?.card?.hospital?.value,
                  organizationName: newRfProfile?.card?.hospital?.label,
              },

              confirmed: true,
          })
      );
  };

    const onPatientNeedEdit = () => {
        dispatch(
            setEditedPatient({
                ulid: '',
                idPassport: newRfProfile?.passport,
                address: newRfProfile?.address,
                countryId: '',
                countryName: newRfProfile?.citizenship,
                countryAlphaCode: '',
                gender: newRfProfile?.sexCode,
                lastName: newRfProfile?.lastName,
                firstName: newRfProfile?.firstName,
                patronymic: newRfProfile?.patronymic,
                oms: newRfProfile?.oms,
                snils: newRfProfile?.snils,
                birthdate: newRfProfile?.birthdate,
                fullName: `${newRfProfile?.firstName} ${newRfProfile?.lastName} ${newRfProfile?.patronymic}`,
                ambulatoryCard: {
                    number: newRfProfile?.card?.number,
                    organizationId: newRfProfile?.card?.hospital?.value,
                    organizationName: newRfProfile?.card?.hospital?.label,
                },

                confirmed: false,
            })
        );
    };

  const onClinicSelect = (option: { label: string; value: string }) => {
    dispatch(setNewRfProfileCardHospital(option));
  };

  // const onCountrySelect =(option: { label: string; value: string }) => {
  //   dispatch(setNewRfProfileCitizenship(option.value));
  // };

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Совпадений не найдено. Создайте профиль</Title>
      </StyledTitleContainer>

      {/*<StyledRowContainer>*/}
      {/*  <StyledInputContainer>*/}
      {/*    <Select*/}
      {/*      title="Гражданство"*/}
      {/*      required={true}*/}
      {/*      placeholder="--"*/}
      {/*      options={countries.map((country) => ({*/}
      {/*        label: country.name,*/}
      {/*        value: String(country.id),*/}
      {/*        // selected: `${country.id}` === `${newRfProfile?.card?.hospital?.value}`,*/}
      {/*        selected: `${country.id}` === `${newRfProfile?.citizenship}`,*/}
      {/*      }))}*/}
      {/*      onSelect={onCountrySelect}*/}
      {/*    />*/}
      {/*  </StyledInputContainer>*/}
      {/*</StyledRowContainer>*/}

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            mask = "9999 999999"
            title="Серия и номер паспорта"
            required={true}
            placeholder="xxxx xxxxxx"
            value={newRfProfile?.passport || ""}
            errorMessage="Пример: 1234 567890"
            onChange={(event) => {
              dispatch(setNewRfProfilePassport(event.target.value));
            }}
            disabled={editedPatient?.confirmed}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Адрес регистрации"
            required={true}
            placeholder="--"
            value={newRfProfile?.address || ""}
            onChange={(event) => {
              dispatch(setNewRfProfileAddress(event.target.value));
            }}
            disabled={editedPatient?.confirmed}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            mask = "9999999999999999"
            title="Номер полиса ОМС"
            required={true}
            placeholder="xxxxxxxxxxxxxxxx"
            value={newRfProfile?.oms || ""}
            errorMessage="Пример: 1234567891234567"
            onChange={(event) => {
              dispatch(setNewRfProfileOms(event.target.value));
            }}
            disabled={editedPatient?.confirmed}
          />
        </StyledInputContainer2>

        <StyledInputContainer2>
          <Input
            mask="999-999-999 99"
            title="Номер СНИЛС"
            required={true}
            placeholder="xxx-xxx-xxx xx"
            value={newRfProfile?.snils || ""}
            errorMessage="Пример: 123-456-789 10"
            onChange={(event) => {
              dispatch(setNewRfProfileSnils(event.target.value));
            }}
            disabled={editedPatient?.confirmed}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            title="Имя"
            required={true}
            placeholder="--"
            value={newRfProfile?.firstName || ""}
            onChange={(event) => {
              dispatch(setNewRfProfileFirstName(event.target.value));
            }}
            disabled={editedPatient?.confirmed}
          />
        </StyledInputContainer2>

        <StyledInputContainer2>
          <Input
            title="Фамилия"
            required={true}
            placeholder="--"
            value={newRfProfile?.lastName || ""}
            onChange={(event) => {
              dispatch(setNewRfProfileLastName(event.target.value));
            }}
            disabled={editedPatient?.confirmed}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            title="Отчество"
            placeholder="--"
            value={newRfProfile?.patronymic || ""}
            onChange={(event) => {
              dispatch(setNewRfProfilePatronymic(event.target.value));
            }}
            disabled={editedPatient?.confirmed}
          />
        </StyledInputContainer2>

        <StyledInputContainer2>
          <Datepicker
            required={true}
            title="Дата рождения"
            placeholder="--"
            value={newRfProfile?.birthdate || ""}
            onChange={(event) => {
              dispatch(setNewRfProfileBirthdate(event.target.value));
            }}
            disabled={editedPatient?.confirmed}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledRowContainer2>
        {sexes.map((sex) => (
          <StyledInputContainer2 key={sex.code}>
            <Radio
              title={sex.code === "M" ? "Пол" : undefined}
              selected={sex.code === newRfProfile?.sexCode}
              text={sex.name}
              //   onSelect={() => onCitizenshipSelect(citizenship.code)}
              onSelect={() => {
                dispatch(setNewRfProfileSexCode(sex.code as "M" | "F"));
              }}
              tooltip={false}
              required={
                // citizenship.code === Constants.CITIZENSHIP_RUSSIAN_FEDERATION
                //   ? true
                //   : false
                sex.code === "M" ? true : false
              }
              disabled={editedPatient?.confirmed}
            />
          </StyledInputContainer2>
        ))}
      </StyledRowContainer2>

      <StyledAmbulatoryCardContainer>
        <StyledRowContainer style={{ marginBottom: "16px" }}>
          <Title level="3">Амбулаторная карта</Title>
        </StyledRowContainer>

        <StyledRowContainer style={{ marginBottom: "8px" }}>
          <Description>
            Убедитесь, что выбранная амбулаторная карта зарегистрирована в
            учреждении направителя
          </Description>
        </StyledRowContainer>

        <StyledRowContainer2>
          <StyledInputContainer2>
            <Input
              title="Номер амбулаторной карты"
              required={true}
              placeholder="--"
              value={newRfProfile?.card?.number || ""}
              onChange={(event) => {
                dispatch(setNewRfProfileCardNumber(event.target.value));
              }}
              disabled={editedPatient?.confirmed}
            />
          </StyledInputContainer2>

          <StyledInputContainer2>
            <Select
              title="УЗ"
              placeholder="--"
              required={true}
              options={clinics.map((clinic) => ({
                label: clinic.name_local_rus,
                value: String(clinic.id),
                selected: `${clinic.id}` === `${newRfProfile?.card?.hospital?.value}`,
              }))}
              disabled={editedPatient?.confirmed}
              onSelect={onClinicSelect}
            />
          </StyledInputContainer2>
        </StyledRowContainer2>
      </StyledAmbulatoryCardContainer>
        {Boolean(!editedPatient?.confirmed) && (
            <StyledRowContainer>
                <Button
                    type="PRIMARY"
                    text="Подтвердить"
                    onClick={() => {
                        onPatientConfirm();
                    }}
                    disabled={!isPatientFormFilled}
                />
            </StyledRowContainer>
        )}
        {Boolean(editedPatient?.confirmed) && (
            <StyledRowContainer>
                <Button
                    type="SECONDARY"
                    text="Редактировать"
                    onClick={() => {
                        onPatientNeedEdit();
                    }}
                />
            </StyledRowContainer>
        )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  /* margin-bottom: 32px; */
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledInputContainer = styled.div`
  width: 100%;
`;

const StyledInputContainer2 = styled.div`
  width: 50%;
`;

const StyledRowContainer2 = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-end;

  gap: 16px;
`;

const StyledAmbulatoryCardContainer = styled.div``;
