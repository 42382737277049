import { FC, useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns';
import Select from "react-select";
import { Table, TBody } from "../../../../../components/shared/BiomaterialTable";
import { TDetailsContent } from "../../../../../components/shared/Details/styled";
import Textarea from "../../../../../components/shared/Textarea";
import { ExperimentSteps, IAnalisysByMethod, MarkerConclusion, MarkerIhcExperiment, MarkerReference, SaveExperimentUpdate } from "../../../../../store/analysis/model";
import { fetchAnalysisByBluprint, patchBioExperementUpdate, patchExperimentUpdate, resetAnalysisByBluprint } from "../../../../../store/analysis/thunkActions";
import { getProfile, getTokens } from "../../../../../store/auth/selectors";
import { TEditButton, TRowWr } from "../../../MarkersValidation/styled";
import { CustomButton, TBioAcquired, TButtonWrapper, TDateOfCompletion, TExecutor, TOtherExecutors, TSectionTitle, TSSectionTitle, TWrapper, customStylesOptions, TFullWidthInput } from "../../styled";
import { getAndOverWriteEperement } from "../../../../../store/molProConclusion/thunkActions";
import InfoModal from "../../../../../components/shared/InfoModal";
import Button, { SIZE, VARIANT } from "../../../../../components/shared/Button";
import { ReactComponent as WarnIcon } from '../../../../../icons/warn-red-circle.svg';
import { IBlueprintAdvisors, IExamExecutors } from "../../CreateExperiment";
import defaultTheme from "../../../../../styles/theme";

interface IComponentProps {
  flowStepsActiveIndex: any;
  analisysByMethod: IAnalisysByMethod | { [index: string]: any };
  isActive: boolean;
  hasPermit: boolean;
  defaultValues?: any;
  ableToUpdate?: boolean;
  isReferralComplite: boolean;
  referralULID: string;
  defaultExecutorOptions?:any;
  examExecutors: IExamExecutors;
  [index: string]: any;
}

interface ISelectOptions {
  value: string | number;
  label: string;
  id?: number;
}

const stainIntensityOption: ISelectOptions[] = [
  { value: '0', label: '0' },
  { value: '1+', label: '1+' },
  { value: '2+', label: '2+' },
  { value: '3+', label: '3+' },
  { value: 'Невозможно оценить', label: 'Невозможно оценить' },
]

const notInformativeSampleValues = ['Неинформативно','Не выполнялось'];
const stepDate = (date: Date) => format(new Date(date), 'dd.MM.yyyy - HH:mm:ss');


const TableIHC: FC<IComponentProps> = ({ referralULID,defaultExecutorOptions,isReferralComplite, flowStepsActiveIndex, analisysByMethod, isActive, hasPermit, defaultValues, ableToUpdate, examExecutors }) => {
  const { register, control, handleSubmit, reset, getValues,formState: { errors },clearErrors } = useForm();

  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const profile = useSelector(getProfile);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [resertFormKey, setResetFormKey] = useState<number>(Date.now());
  const [notInformativeSample, setNotInformativeSample] = useState(false);

  const [ihsDataAttempt, setIhsDataAttempt] = useState<MarkerIhcExperiment>();
  const [ihsResultDataOptions, setIhsResultDataOptions] = useState<ISelectOptions[]>([]);
  const [ihsResultMap,setIhsResultMap] = useState<{[index: number]: ISelectOptions;}>({});

  const [cellStainPercentOptions, setCellStainPercentOptions] = useState<ISelectOptions[]>([]);

  const setDefaultValues = useCallback(() => {
    if (!!defaultValues && Object.keys(defaultValues)?.length) {
      let { cellStainPercentage: STPecentage, stainIntensity: STIntence, conclusion, result } = defaultValues ?? {};


      const defaults: { [index: string]: any; } = {
        stainIntensity: STIntence ? { value: STIntence, label: STIntence } : undefined,
        cellStainPercentage: STPecentage ? { value: STPecentage, label: STPecentage.toString() } : undefined,
        examResult: ihsResultMap?.[result],
        conclusion: conclusion ?? '',
      }

      reset({ ...defaults });
      setResetFormKey(Date.now())
      return true;
    }
    return false;
  }, [defaultValues, setResetFormKey, reset, ihsResultMap]);

  useLayoutEffect(() => {
    if (!analisysByMethod?.markerIhc?.markerIhcReferences?.length) return;

    if (analisysByMethod?.analysisIhcExperiments && flowStepsActiveIndex) {
      let dataAtempt: MarkerIhcExperiment[] = analisysByMethod?.analysisIhcExperiments?.filter((ihsData: MarkerIhcExperiment) => {
        return +ihsData.number === +flowStepsActiveIndex
      });
      if (dataAtempt.length) setIhsDataAttempt(dataAtempt[0]);
    }

    if(!cellStainPercentOptions?.length){
      //Stain Percent
      let cellStainPercentList: ISelectOptions[] = [];
      for (let percent = 0; percent <= 100; ++percent) {
        if (percent <= 10) cellStainPercentList.push({ value: percent, label: percent.toString() });
        if (percent > 10 && percent % 5 === 0) cellStainPercentList.push({ value: percent, label: percent.toString() });
      }
      setCellStainPercentOptions(cellStainPercentList);
    }


    //Conclusions
    if(!Object.keys(ihsResultDataOptions)?.length){

   
    const ConclusionList: ISelectOptions[] = [];
    const markerConclusions: MarkerConclusion[] = analisysByMethod?.markerIhc?.markerIhcConclusions?.map((data: MarkerConclusion) => data) ?? [];
    const uniqOptionNames = new Set<string>();
    const ResultsMap:{[index: number]: ISelectOptions;} = {}
    const markerIhcResults = analisysByMethod?.markerIhc?.markerIhcResults?.map((data: MarkerConclusion) => {
      const option:ISelectOptions = {value:data?.id ?? 0, label: data?.content}
      if(data?.id) ResultsMap[+data.id] = option;
      return option;
    });
    setIhsResultDataOptions(markerIhcResults);
    setIhsResultMap(ResultsMap);

    for (let conclusion of markerConclusions) {
      let content = `${conclusion.content.replaceAll(' ', "\u2004")}\u2004 `;
      if (uniqOptionNames.has(content)) continue;
      else uniqOptionNames.add(content);

      let conclusionOption = { value: conclusion?.id ?? 0, label: content };
      ConclusionList.push(conclusionOption);
    }
  }
    setDefaultValues()
  }, [analisysByMethod, flowStepsActiveIndex, setDefaultValues,cellStainPercentOptions,ihsResultDataOptions]);

  const handleEditTable = useCallback(async () => {
    // if(!isActive) return;
    if (!hasPermit) return;
    clearErrors()
    if (isEdit) {
      if(!setDefaultValues()){
        reset({}, { keepValues: false });
        setResetFormKey(Date.now());
      }
      return setEdit(false);
    }
    setEdit(true)
  }, [isEdit, setEdit, reset, setResetFormKey, hasPermit,setDefaultValues,clearErrors]);


  const [executorfullName, executorTitle] = useMemo(()=> {
    if(examExecutors?.blueprintExecutors?.length){
      let data = examExecutors?.blueprintExecutors?.[0]
      let {firstName = '', lastName = '',middleName = '',specialization = ''} = data;
      return [`${lastName} ${firstName} ${middleName}`.trim(), specialization];
    }
    if(!profile) return ['','']
    let {firstName = '', lastName = '',middleName = '',title = ''} = profile;
    const executorfullName = `${lastName} ${firstName} ${middleName}`.trim();
    return [executorfullName,title];
  },[profile,examExecutors]);

  const onSubmitTable = useCallback(async (data: any) => {
    let { conclusion, stainIntensity, cellStainPercentage, examResult } = data;
    const experementID = ihsDataAttempt?.id;

    const dataForSend = {
      stain_intensity: stainIntensity.value,
      cell_stain_percentage: cellStainPercentage.value,
      conclusion,
      completed: true,
      result: examResult?.value,
      date_completed: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
    }

    if (!defaultValues && tokens?.access && experementID) {
      await handleEditTable()
      await dispatch(patchBioExperementUpdate(tokens?.access, experementID, dataForSend, 'ihc'));
    }
    
    if(!!defaultValues && tokens?.access && ableToUpdate) {
      let data:{acceptance:boolean; [index:string]: any} = {...dataForSend,acceptance: true, number:defaultValues?.number};
      delete data['date_completed'];
      delete data['completed'];
      let dataToUpdate:SaveExperimentUpdate = data;
      await dispatch(patchExperimentUpdate(tokens?.access, 'ihc', defaultValues.id, dataToUpdate));
      await dispatch(getAndOverWriteEperement(tokens?.access, referralULID));
    }

    dispatch(resetAnalysisByBluprint());
    tokens?.access && setTimeout(() => dispatch(fetchAnalysisByBluprint(tokens?.access, 'ihc', analisysByMethod?.blueprint)), 100);
    setEdit(false);

  }, [tokens?.access, dispatch, handleEditTable, analisysByMethod, ihsDataAttempt, ableToUpdate, defaultValues,referralULID]);

  const experementBioSteps = useMemo(() => {
    const BioSteps: ExperimentSteps[] = defaultValues?.experimentIhcSteps || defaultValues?.experimentIhcAlkSteps || defaultValues?.experimentFishSteps;
    if (!BioSteps) return <></>;
    return BioSteps
    ?.filter(((value: ExperimentSteps) => value.step === 'bio_acquired'))
    ?.map((value: ExperimentSteps) => {
      return (
        <div key={value.id}>
          <span>Материал получен: <b> {stepDate(value.dateCreated)}</b></span>
        </div>
      )
    });
  }, [defaultValues]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const onModalToggle = useCallback(() => setShowConfirmModal(!showConfirmModal), [setShowConfirmModal,showConfirmModal]);

  const dateOfCompletion = useMemo(() => {
    if (!defaultValues?.dateCompleted) return '';

    return format(new Date(defaultValues?.dateCompleted), 'dd.MM.yyyy - HH:mm:ss');
  }, [defaultValues]);

  return (
    <TDetailsContent>
      {!isReferralComplite ? (
        <TRowWr direction={'space-between'}>
          {!dateOfCompletion ? 
            <TSectionTitle width="60%" color={ihsDataAttempt?.bioAcquired ? "#7A78E9" : "#777"}>Результаты исследования биоматериала методом ИГХ</TSectionTitle> : 
            <TDateOfCompletion>{!!dateOfCompletion && `Дата создания в системе: ${dateOfCompletion}`}</TDateOfCompletion>
          }

          {/* {!isReferralComplite && !!Object?.keys(defaultValues ?? {})?.length && !!ableToUpdate ? <TEditButton disabled={!ihsDataAttempt?.bioAcquired} onClick={handleEditTable}>
            {!isEdit ? 'Редактировать' : 'Отменить'}
          </TEditButton> : hasPermit && <TEditButton disabled={!ihsDataAttempt?.bioAcquired || !isActive} onClick={handleEditTable}>
            {!isEdit ? 'Редактировать' : 'Отменить'}
          </TEditButton>} */}
        </TRowWr>
      ) : (
        <TRowWr direction="flex-start">
          <TSectionTitle width="60%" color="#7A78E9">Результаты исследования биоматериала методом ИГХ</TSectionTitle>
        </TRowWr>
      )}
      <TFullWidthInput dataLabel={"Клон антител"} >{'CB11'}</TFullWidthInput>
      <TFullWidthInput dataLabel={"Протокол (система детекции)"} >{'Ventana Benchmark XT'}</TFullWidthInput>
      <TSSectionTitle width="100%" color="#000000">Результаты исследования биоматериала методом ИГХ</TSSectionTitle>
      <TWrapper onSubmit={handleSubmit(onSubmitTable)} key={resertFormKey}>
        <Table>
          {/* header */}
          <TBody>
            <tr className="borderTop violet">
              <td className="head leftTopRadius">Маркер</td>
              <td className="head">Шкала (score)</td>
              <td className="head">Порог позитивности, %</td>
              <td className="head">Процент опухолевых клеток, %</td>
              <td className="head rigthTopRadius">Результат исследования</td>
            </tr>
          </TBody>
          {/* genes */}
          {!!analisysByMethod?.markerIhc && (

            <TBody>
              <tr >
                <td >{analisysByMethod?.markerIhc?.name}</td>
                <td className={!!errors?.['stainIntensity'] ? 'error' : ''}>
                  <Controller
                    control={control}
                    name={'stainIntensity'}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        onChange={onChange}
                        selected={value}
                        options={stainIntensityOption}
                        placeholder={'55'}
                        isDisabled={!isEdit}
                        noOptionsMessage={() => "нет опций"}
                        isSearchable={false}
                        components={!isEdit ? ({ DropdownIndicator: () => null, IndicatorSeparator: () => null }) : undefined}
                        defaultValue={getValues('broke_stainIntensity')}
                        styles={customStylesOptions(defaultTheme)}
                      />
                    )}
                  />
                </td>
                {/* <td className="borderRight"> */}
                  {/* {analisysByMethod?.markerIhc?.markerIhcReferences?.map((reference: MarkerReference) => <div key={reference?.id}>{reference.content}</div>)} */}
                {/* </td> */}
                <td className={!!errors?.['stainIntensity'] ? 'error' : ''}>
                  <Controller
                    control={control}
                    name={'stainIntensity'}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        onChange={onChange}
                        selected={value}
                        options={stainIntensityOption}
                        placeholder={'99'}
                        isDisabled={!isEdit}
                        noOptionsMessage={() => "нет опций"}
                        isSearchable={false}
                        components={!isEdit ? ({ DropdownIndicator: () => null, IndicatorSeparator: () => null }) : undefined}
                        defaultValue={getValues('broke_stainIntensity')}
                        styles={customStylesOptions(defaultTheme)}
                      />
                    )}
                  />
                </td>
                <td className={!!errors?.['cellStainPercentage'] ? 'error' : ''}>
                  <Controller
                    control={control}
                    name={'cellStainPercentage'}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        onChange={onChange}
                        selected={value}
                        options={cellStainPercentOptions}
                        placeholder={'--'}
                        isDisabled={!isEdit}
                        noOptionsMessage={() => "нет опций"}
                        isSearchable={true}
                        components={!isEdit ? ({ DropdownIndicator: () => null, IndicatorSeparator: () => null }) : undefined}
                        defaultValue={getValues('cellStainPercentage')}
                        styles={customStylesOptions(defaultTheme)}
                      />
                    )}
                  />
                </td>
                <td className={!!errors?.['examResult'] ? 'error' : ''}>
                  <Controller
                    control={control}
                    name={'examResult'}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        onChange={(option) => {
                          clearErrors('conclusion');
                          setNotInformativeSample(notInformativeSampleValues.includes(option?.label));
                          return onChange(option);
                        }}
                        selected={value}
                        options={ihsResultDataOptions}
                        placeholder={'--'}
                        isDisabled={!isEdit}
                        noOptionsMessage={() => "нет опций"}
                        isSearchable={false}
                        components={!isEdit ? ({ DropdownIndicator: () => null, IndicatorSeparator: () => null }) : undefined}
                        defaultValue={getValues('examResult')}
                        styles={customStylesOptions(defaultTheme)}
                      />
                    )}
                  />
                </td>
              </tr>
            </TBody>
          )}

          {/* conclusion */}
          <TBody>
            <tr>
              <td colSpan={5} className="head violet">Клинико-лабораторное заключение {notInformativeSample ? '*' : ''}</td>
            </tr>
            <tr >
              {/* <td colSpan={5} className={!!errors?.['conclusion'] ? 'error' : ''}> */}
              <td colSpan={5} className={'conclusion'}>
                <Textarea
                  {...register('conclusion', { maxLength: 300, required: notInformativeSample })}
                  placeholder={'Введите дополнительные сведения при необходимости...'}
                  className='conclusionDescription'
                  defaultValue=''
                  height={100}
                  readOnly={!isEdit}
                  disabled={!isEdit}
                  maxLength={300}
                />
              </td>
            </tr>

          </TBody>
        </Table>

        {(!isActive || !!defaultValues) && !!executorfullName && <TExecutor>
          <b>{executorfullName ?? 'Исполнитель не определен'}</b>
          {executorTitle ? `, ${executorTitle}` : ''}
        </TExecutor>}
        {/* {!!defaultValues?.labAssistant && (<TBioAcquired height={'84px'}>
          {experementBioSteps}
          <span>Материал подготовил:  
            <b> {defaultExecutorOptions?.[defaultValues.labAssistant]?.fullName ?? 'Исполнитель не определен'}</b>
            {`, ${defaultExecutorOptions?.[defaultValues.labAssistant]?.title}`}
          </span>
        </TBioAcquired>)}
        {(!isActive || !!defaultValues) && !!examExecutors?.blueprintAdvisory?.length && <TOtherExecutors height='fit-content'>
          {examExecutors?.blueprintAdvisory?.map((advisor: IBlueprintAdvisors) => (
            <div key={`advisorIHS${advisor?.fullName}`}><b>{advisor?.fullName ?? 'Исполнитель не определен'}</b>
            {advisor?.specialization ? `, ${advisor?.specialization}` : ''}</div>
          ))}        
        </TOtherExecutors>} */}

        {!isReferralComplite && <TButtonWrapper>
          {hasPermit && isActive && <Button size={SIZE.SMALL} variant={!isEdit ? VARIANT.DEFAULT : VARIANT.BLUE} type="submit" disabled={!isEdit}>
            Завершить попытку
          </Button>}
          {!!Object?.keys(defaultValues ?? {})?.length && !!ableToUpdate && <CustomButton type="button" onClick={onModalToggle} disabled={!isEdit}>
            Подтвердить изменения
          </CustomButton>}
        </TButtonWrapper>}
        <InfoModal
            title='Внесенные изменения будут отображены в заключении. '
            showModal={showConfirmModal}
            isWarning={true}
            icon={<WarnIcon />}
            onModalToggle={onModalToggle}
            buttons={<>
              <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onModalToggle}>Вернуться</Button>
              <Button  size={SIZE.SMALL} type="submit" onClick={handleSubmit(data => onSubmitTable(data))}>Продолжить</Button>
            </>}
          />
      </TWrapper>

    </TDetailsContent>
  )
}

export default TableIHC;